import React, { useState, useEffect, useRef } from "react"
import "./style.scss"
import { getDate, formattedDate } from "shared"
import { useToday } from "shared"
import api from "api"
import { useSelector, useDispatch } from "react-redux"
import {
  PROFILE_OVERVIEW_LIST,
  PROFILE_OVERVIEW_STATUS,
  SORT_OVERVIEW_PROFILE_LIST,
  GET_PRINT_STATUS,
  CURRENT_PROFILE,
  RECERTIFY_PROFILE,
  SET_PROGRESS_STEP_ONLY,
  GENERATOR_DETAILS,
  SET_PROFILE_NAME,
  MODULE_OVERVIEW_LIST,
  PROFILE_CRETAE_FROM_GENE,
  GET_PROFILE_FOR_PRINT_LABEL,
  SET_SHOW_DELETE,
  //GET_DEFAULT_GENE,
  ALL_GENERATORS,
  RECERTIFY_VALIDATE,
} from "store/reducer/actionType"
import { toast } from "react-toastify"
import Image from "shared/Image"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const profileOverviewStatus = useSelector(
      (state) => state.generatorDetails.profileOverviewStatus
    )
    const profileList = useSelector(
      (state) => state.profileDetails.profilesOverviewList
    )
    const moduleOverviewList = useSelector(
      (state) => state.generatorDetails.moduleOverList
    )
    const dispatch = useDispatch()
    // let [pageNo, gotoPage] = useState(1)
    // let [sortColumn, setSortColumn] = useState("")
    // let [sortOrder, setSortOrder] = useState("")
    // let [totalRecords, setTotalRecords] = useState(0)
    // const [sortName, setSortName] = useState("")
    const [profileCounts, setprofileCounts] = useState([])
    // const [firstTimeFetch, setFirstTimeFetch] = useState(true)
    const firstTimeFetch = useRef(true)
    const [profileChange, setProfileChange] = useState(false)
    const [moduleChnage, setModuleChange] = useState(false)
    const [loading, setLoading] = useState(false)
    const [moduleLoading, setModuleLoading] = useState(false)
    const [gridLoader, setGridLoader] = useState(false)
    // eslint-disable-next-line
    const [tileLoader, setTileLoader] = useState(false)
    const [printLoader, setPrintLoader] = useState(false)
    const [downloadLoader, setDownloadLoader] = useState(false)
    const { changeCustomer } = useSelector((state) => state)

    if (profileOverviewStatus != undefined) {
      setprofileCounts(profileOverviewStatus)
    }

    const [profileParams, setProfileParams] = useState({
      overView: "Last 90 Days",
      status: "Approved",
    })

    const [moduleParams, setModuleParams] = useState({
      overView: "Last 90 Days",
      status: "Approved",
    })

    const profileListAPI = async (statusInfo) => {
      setGridLoader(true)
      try {
        let req = {
          profilePeriod: profileParams.overView,
          profileStatus: profileParams.status,
          // index: pageNo,
          // size: 10,
        }
        dispatch({ type: PROFILE_OVERVIEW_LIST, payload: [] })
        let profileOverviewList = await api.p_getProfileOverviewList(req)
        if (!profileOverviewList) {
          dispatch({ type: PROFILE_OVERVIEW_LIST, payload: [] })
        } else {
          dispatch({
            type: PROFILE_OVERVIEW_LIST,
            payload: profileOverviewList,
          })
          // setProfileListValue(profileOverviewList.data)
        }
      } catch (error) {
      } finally {
        setGridLoader(false)
        setLoading(false)
      }
    }

    const profileListStatusCount = async () => {
      try {
        setTileLoader(true)
        let req = {
          profilePeriod: profileParams.overView,
        }
        const profileOverviewStatus = await api.p_getProfileOverviewStatusCount(
          req
        )
        if (!profileOverviewStatus) return
        dispatch({ type: PROFILE_OVERVIEW_STATUS, profileOverviewStatus })
        setprofileCounts(profileOverviewStatus)
      } catch (error) {
      } finally {
        setTileLoader(false)
      }
    }

    const moduleDataAPI = async () => {
      try {
        let req = {
          profilePeriod: moduleParams.overView,
          CustomerId: changeCustomer?.customer?.v,
        }
        let moduleOverviewList = await api.p_getModuleOverviewList(req)
        const { data } = moduleOverviewList
        if (!moduleOverviewList) return
        dispatch({ type: MODULE_OVERVIEW_LIST, payload: data })
      } catch (error) {
      } finally {
        setModuleLoading(false)
      }
    }

    const getGeneratorDetails = async (id) => {
      try {
        let res = await api.g_getGeneratorDetails(id)
        if (!res) return
        dispatch({
          type: GENERATOR_DETAILS,
          payload: res,
        })
      } catch (error) {}
    }

    const getAllGenerators = async () => {
      try {
        // let res = await api.g_getAllGenerators(customer_name)
        let res = await api.g_getAllGenerators()
        if (!res) return
        dispatch({ type: ALL_GENERATORS, payload: res })
      } catch (error) {}
    }

    useEffect(() => {
      // for stopping multiple api updated this logic
      if (firstTimeFetch.current) {
        setLoading(true)
        profileListStatusCount()
        profileListAPI()
        moduleDataAPI()
        getAllGenerators()
        firstTimeFetch.current = false
      }
      setProfileNameAction("")
    }, [])

    useEffect(() => {
      if (!firstTimeFetch.current && profileChange) {
        setGridLoader(true)
        if (fetchTileLoad) {
          profileListStatusCount()
        }
        profileListAPI()
        setProfileChange(false)
      }
      if (!firstTimeFetch.current && moduleChnage) {
        moduleDataAPI()
        setModuleChange(false)
        setModuleLoading(true)
      }
    }, [profileChange, moduleChnage])

    const sortInfo = useSelector((state) => state.profileDetails.sort)
    const [showInfo, setShowInfo] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [currentData, setCurrentData] = useState(null)
    const editProfileUser = (x) => {
      const profileIdParam = x.profile?.startsWith("P")
        ? x.profile.slice(1)
        : x.profile
      editProfile(profileIdParam)
    }
    const sortArrows = (field) => {
      return (
        <>
          {((sortInfo?.sortField === field && sortInfo.dir === "asc") ||
            sortInfo?.sortField != field) && <Image src="asc_icon_table.svg" />}
          {sortInfo?.sortField === field && sortInfo.dir === "desc" && (
            <Image src="desc_icon_table.svg" />
          )}
        </>
      )
    }

    let today = useToday()
    const statuses = [
      {
        key: "APPROVED",
        value: "Approved",
      },
      {
        key: "EXPIRED",
        value: "Expired",
      },
      {
        key: "PENDING_C",
        value: "Pending Customer Response",
      },
      {
        key: "PENDING_A",
        value: "Pending Clean Earth Approval",
      },
      {
        key: "EXPIRE_S",
        value: "Expiring Soon",
      },
    ]
    const [selectedGrid, setSelectedGrid] = useState({
      key: "APPROVED",
      value: "Approved",
    })

    const overviewTypesD = {
      last30Days: {
        active: false,
        fromDate: getDate(today, -59),
        toDate: getDate(today),
        currentFromDate: getDate(today, -29),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -59),
        previousToDate: getDate(today, -30),
        label: "last30Days",
        legends: [
          { key: "cur", label: "Last 30 days" },
          { key: "prev", label: "Previous Last 30 days" },
        ],
        chartLabel: "Last 30 Days",
        xKey: "date",
        dashboardHeader: "profile overview",
      },
      last60Days: {
        active: false,
        fromDate: getDate(today, -119),
        toDate: getDate(today),
        currentFromDate: getDate(today, -59),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -119),
        previousToDate: getDate(today, -60),
        label: "last60Days",
        legends: [
          { key: "cur", label: "Last 30 days" },
          { key: "prev", label: "Previous Last 30 days" },
        ],
        chartLabel: "Last 60 Days",
        xKey: "day",
        dashboardHeader: "profile overview",
      },
      last90Days: {
        active: true,
        fromDate: getDate(today, -179),
        toDate: getDate(today),
        currentFromDate: getDate(today, -89),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -179),
        previousToDate: getDate(today, -90),
        label: "last90Days",
        legends: [
          { key: "cur", label: "Last 90 days" },
          { key: "prev", label: "Previous Last 90 days" },
        ],
        chartLabel: "Last 90 Days",
        xKey: "date",
        dashboardHeader: "profile overview",
      },
    }

    const overviewTypesM = {
      last30Days: {
        active: false,
        fromDate: getDate(today, -59),
        toDate: getDate(today),
        currentFromDate: getDate(today, -29),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -59),
        previousToDate: getDate(today, -30),
        label: "last30Days",
        legends: [
          { key: "cur", label: "Last 30 days" },
          { key: "prev", label: "Previous Last 30 days" },
        ],
        chartLabel: "Last 30 Days",
        mKey: "date",
        dashboardHeader: "profile overview",
      },
      last60Days: {
        active: false,
        fromDate: getDate(today, -119),
        toDate: getDate(today),
        currentFromDate: getDate(today, -59),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -119),
        previousToDate: getDate(today, -60),
        label: "last60Days",
        legends: [
          { key: "cur", label: "Last 30 days" },
          { key: "prev", label: "Previous Last 30 days" },
        ],
        chartLabel: "Last 60 Days",
        mKey: "day",
        dashboardHeader: "profile overview",
      },
      last90Days: {
        active: true,
        fromDate: getDate(today, -179),
        toDate: getDate(today),
        currentFromDate: getDate(today, -89),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -179),
        previousToDate: getDate(today, -90),
        label: "last90Days",
        legends: [
          { key: "cur", label: "Last 90 days" },
          { key: "prev", label: "Previous Last 90 days" },
        ],
        chartLabel: "Last 90 Days",
        mKey: "date",
        dashboardHeader: "profile overview",
      },
    }

    const overviewTypesW = {
      last30Days: {
        active: false,
        fromDate: getDate(today, -59),
        toDate: getDate(today),
        currentFromDate: getDate(today, -29),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -59),
        previousToDate: getDate(today, -30),
        label: "last30Days",
        legends: [
          { key: "cur", label: "Last 30 days" },
          { key: "prev", label: "Previous Last 30 days" },
        ],
        chartLabel: "Last 30 Days",
        dashboardHeader: "profile overview",
      },
      last60Days: {
        active: false,
        fromDate: getDate(today, -119),
        toDate: getDate(today),
        currentFromDate: getDate(today, -59),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -119),
        previousToDate: getDate(today, -60),
        label: "last60Days",
        legends: [
          { key: "cur", label: "Last 30 days" },
          { key: "prev", label: "Previous Last 30 days" },
        ],
        chartLabel: "Last 60 Days",
        dashboardHeader: "profile overview",
      },
      last90Days: {
        active: true,
        fromDate: getDate(today, -179),
        toDate: getDate(today),
        currentFromDate: getDate(today, -89),
        currentToDate: getDate(today),
        previousFromDate: getDate(today, -179),
        previousToDate: getDate(today, -90),
        label: "last90Days",
        legends: [
          { key: "cur", label: "Last 90 days" },
          { key: "prev", label: "Previous Last 90 days" },
        ],
        chartLabel: "Last 90 Days",
        dashboardHeader: "profile overview",
      },
    }

    const [overviewTypes, setOverviewDuration] = useState(overviewTypesD)
    const [activeDuration, setActiveDuration] = useState(
      overviewTypesD.last90Days
    )
    const [overviewModule, setOverviewModule] = useState(overviewTypesM)
    const [activeModule, setActiveModule] = useState(overviewTypesM.last90Days)

    const [overviewWaste, setOverviewWaste] = useState(overviewTypesW)
    const [activeWaste, setActiveWaste] = useState(overviewTypesW.last90Days)
    const [fetchTileLoad, setFetchTileLoad] = useState(false)

    let xKey = activeDuration?.xKey

    let mKey = activeModule?.mKey

    const sortData = (options, data) => {
      dispatch({
        type: SORT_OVERVIEW_PROFILE_LIST,
        payload: options,
        data: {
          type: data?.type,
          fieldName: data?.fieldName,
        },
      })
    }

    let weekDates = {
      chartLabel: activeDuration?.chartLabel,
      from: formattedDate(activeDuration?.currentFromDate, false),
      to: formattedDate(activeDuration?.currentToDate, false),
      chartLabelCurrent: activeDuration?.legends[0]?.label,
      chartLabelPrevious: activeDuration?.legends[1]?.label,
      headerLabel: activeDuration?.dashboardHeader,
    }

    let weekDates1 = {
      chartLabel: activeModule?.chartLabel,
      from: formattedDate(activeModule?.currentFromDate, false),
      to: formattedDate(activeModule?.currentToDate, false),
      chartLabelCurrent: activeModule?.legends[0]?.label,
      chartLabelPrevious: activeModule?.legends[1]?.label,
      headerLabel: activeModule?.dashboardHeader,
    }

    let changeActiveOverview = (overviewD) => {
      setFetchTileLoad(true)
      let overviewTypesN = { ...overviewTypes }
      if (overviewD !== "weekly")
        // eslint-disable-next-line
        for (let key in overviewTypesN) {
          overviewTypesN[key]["active"] = false
          if (
            overviewTypesN.hasOwnProperty(key) &&
            key.toLowerCase() === overviewD.toLowerCase()
          ) {
            overviewTypesN[key]["active"] = true
            setActiveDuration(overviewTypesN[key])
            setProfileParams({
              overView: overviewTypesN[key].chartLabel,
              status: selectedGrid?.value || "Approved",
            })
            setProfileChange(true)
          }
        }
      setOverviewDuration(overviewTypesN)
    }

    let changeActiveModule = (overviewM) => {
      let overviewTypesM = { ...overviewModule }
      if (overviewM !== "weekly")
        // eslint-disable-next-line
        for (let key in overviewTypesM) {
          overviewTypesM[key]["active"] = false
          if (
            overviewTypesM.hasOwnProperty(key) &&
            key.toLowerCase() === overviewM.toLowerCase()
          ) {
            overviewTypesM[key]["active"] = true
            setActiveModule(overviewTypesM[key])
            setModuleParams({
              overView: overviewTypesM[key].chartLabel,
              status: selectedGrid?.value || "Approved",
            })
            setModuleChange(true)
          }
        }
      setOverviewModule(overviewTypesM)
    }

    let changeActiveWaste = (overviewW) => {
      let overviewTypesW = { ...overviewWaste }
      if (overviewW !== "weekly")
        // eslint-disable-next-line
        for (let key in overviewTypesW) {
          overviewTypesW[key]["active"] = false
          if (
            overviewTypesW.hasOwnProperty(key) &&
            key.toLowerCase() === overviewW.toLowerCase()
          ) {
            overviewTypesW[key]["active"] = true
            setActiveWaste(overviewTypesW[key])
          }
        }
      setOverviewWaste(overviewTypesW)
    }

    let changeStatus = (statusInfo) => {
      setProfileParams({ ...profileParams, status: statusInfo.value })
      setFetchTileLoad(false)
      setSelectedGrid(statusInfo)
      // gotoPage(1);
      // eslint-disable-next-line
      for (let key in overviewTypes) {
        if (overviewTypes[key]["active"] === true) {
          setProfileParams({
            overView: overviewTypes[key].chartLabel,
            status: statusInfo?.value || "Active",
          })
          setProfileChange(true)
        }
      }
    }

    const goToEditProfile = async (page, id, vers) => {
      await dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: 1 })
      setPrintLoader(false)
      // await dispatch({ type: GENERATOR_DETAILS, payload: {} })
      if (vers) {
        history.push(`/profiles/${page}/${id}/${vers}`)
      } else {
        history.push(`/profiles/${page}/${id}`)
      }
    }
    const editProfile = async (profileId) => {
      goToEditProfile("editprofile", profileId)
    }

    const goTorecertifyValidate = async (version, profileRequestId) => {
      try {
        let res = await api.p_recertifyValidate({
          ProfileRequestId: null,
          ProfileNumber: profileRequestId,
          RevisionNum: version,
        })
        if (!res) return

        dispatch({ type: RECERTIFY_VALIDATE, payload: res })
      } catch (error) {}
    }

    const goToRecertifyProfile = async (data) => {
      dispatch({ type: RECERTIFY_VALIDATE, payload: [] })
      dispatch({ type: RECERTIFY_PROFILE, payload: data })
      goTorecertifyValidate(Number(data?.version), data?.profile)
      history.push(`/profiles/recertify`)
    }

    // const goToRecertifyProfile = async (data) => {
    //   dispatch({ type: RECERTIFY_PROFILE, payload: data })
    //   history.push(`/profiles/recertify`)
    // }

    const setProfileNameAction = async (data) => {
      await dispatch({ type: SET_PROFILE_NAME, payload: data })
    }

    const getProfileData = async (reqId, template, isNonDraft) => {
      let req
      if (isNonDraft) {
        req = {
          profileRequestId: 0,
          webProfileId: "",
          coreProfileNumber: reqId?.profile,
          coreVersionNumber: Number(reqId?.version),
        }
      } else {
        req = {
          profileRequestId: reqId,
        }
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        if (template)
          dispatch({
            type: CURRENT_PROFILE,
            payload: { ...res, templateFlag: true, profileName: "" },
          })
        else dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const downloadDocument = async (id, status, revsNo) => {
      let payload
      if (
        status?.toLowerCase() == "draft" &&
        id?.charAt(0)?.toLowerCase() == "p"
      ) {
        payload = {
          profileId:
            id?.charAt(0)?.toLowerCase() == "p"
              ? Number(id.substring(1, id?.length))
              : Number(id),
          profileNumber: "",
          coreRevisionNumber: 0,
        }
      } else {
        payload = {
          profileId: 0,
          profileNumber:
            id?.charAt(0)?.toLowerCase() == "p"
              ? id.substring(1, id?.length)
              : id,
          coreRevisionNumber: revsNo,
        }
      }
      try {
        let res = await api.pb_downloadProfileDocuments(payload)
        if (res?.type === "application/pdf") {
          let url = window.URL.createObjectURL(res)
          let a = document.createElement("a")
          a.href = url
          a.download = `profile${id}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        } else {
          toast.error("Waste profile form not found.")
        }
      } catch (error) {
      } finally {
        setDownloadLoader(false)
      }
    }

    const printDocument = async (id, status, revsNo) => {
      let payload
      if (
        status?.toLowerCase() == "draft" &&
        id?.charAt(0)?.toLowerCase() == "p"
        //   &&
        // selectedGrid.key !== "PENDING_C"
        //  &&
        // id != 0
      ) {
        payload = {
          profileId:
            id?.charAt(0)?.toLowerCase() == "p"
              ? Number(id.substring(1, id?.length))
              : Number(id),
          profileNumber: "",
          coreRevisionNumber: 0,
        }
      } else {
        payload = {
          profileId: 0,
          profileNumber:
            id?.charAt(0)?.toLowerCase() == "p"
              ? id.substring(1, id?.length)
              : id,
          coreRevisionNumber: revsNo,
        }
      }
      try {
        let res = await api.pb_downloadProfileDocuments(payload)
        if (!res) return
        let url = window.URL.createObjectURL(res)
        const iframe = document.querySelector("#pdf-frame")
        iframe.src = ""
        iframe.src = url
        iframe.style.display = "none"
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
        setTimeout(function () {
          document.querySelector("#pdf-frame").contentWindow.print()
        }, 1000)
      } catch (error) {
      } finally {
        setPrintLoader(false)
      }
    }

    const goToCloneProfile = async (page, profileId) => {
      const getProfile = profileList.find(
        (item) => item.profileId == profileId || item.profile == profileId
      )

      let str = getProfile?.version
      let cleanedStr = str?.replace(/\D/g, "")
      let revisionNum = Number(cleanedStr)

      if (getProfile !== undefined) {
        try {
          let res = await api.p_getCloneProfile({
            profileId: getProfile?.profileId || 0,
            profileNumber: getProfile?.profile,
            revisionNum: revisionNum,
          })
          if (!res) return
          await getProfileData(res?.data?.profileRequestId, true)
          let pId = res?.data?.profileRequestId
          await goToEditProfile(page, pId)
        } catch (error) {
        } finally {
        }
      }
    }

    // const viewProfile = async (data) => {
    //   let str = data?.version
    //   let cleanedStr = str?.replace(/\D/g, "")
    //   let coreVersionNumber = Number(cleanedStr)
    //   data.version = coreVersionNumber

    //   await getProfileData(data, false, true)
    //   await goToEditProfile("viewprofile", data?.profile)
    // }

    const viewProfile = async (profileId, version, generatorId) => {
      getGeneratorDetails(generatorId)
      goToEditProfile("viewprofile", profileId, version)
    }

    const mapDefaultGenerator = async (val) => {
      if (val) {
        await dispatch({
          type: PROFILE_CRETAE_FROM_GENE,
          payload: {
            isProfileFromGenerator: true,
            selectedProfileGenerator: val,
          },
        })
      } else {
        await dispatch({
          type: PROFILE_CRETAE_FROM_GENE,
          payload: {
            isProfileFromGenerator: false,
            selectedProfileGenerator: null,
          },
        })
      }
    }

    const handlePrintState = (print, data) => {
      // let str = data?.version
      // let cleanedStr = str?.replace(/\D/g, "")
      // let coreVersionNumber = Number(cleanedStr)
      // data.version = coreVersionNumber

      const isNonDraft = data.status?.toLowerCase() != "draft"
      getProfileData(isNonDraft ? data : data?.profile, false, isNonDraft)
      dispatch({ type: GET_PROFILE_FOR_PRINT_LABEL, payload: data })
      dispatch({ type: GET_PRINT_STATUS, payload: print })
    }

    const showDelete = async (res = false) => {
      dispatch({ type: SET_SHOW_DELETE, payload: res })
    }

    const deleteProfile = async (profileId) => {
      let req = {
        profileRequestId:
          profileId?.charAt(0)?.toLowerCase() == "p"
            ? profileId.substring(1, profileId?.length)
            : profileId,
      }
      try {
        let res = await api.pu_deleteProfile(req)
        if (!res) return
        showDelete(true)
        profileListStatusCount()
        profileListAPI()
      } catch (error) {}
    }

    // useEffect(() => {
    //   setProfileNameAction("")
    // }, [])

    return (
      <Component
        {...{
          editProfile,
          viewProfile,
          weekDates,
          changeActiveOverview,
          overviewTypes,
          statuses,
          selectedGrid,
          changeStatus,
          profileList,
          sortArrows,
          setShowInfo,
          showInfo,
          editProfileUser,
          setCurrentData,
          setShowModal,
          profileCounts,
          currentData,
          showModal,
          profileParams,
          xKey,
          profileOverviewStatus,
          sortData,
          handlePrintState,
          goToCloneProfile,
          goToRecertifyProfile,
          downloadDocument,
          printDocument,
          setProfileNameAction,
          mKey,
          weekDates1,
          overviewModule,
          changeActiveModule,
          moduleOverviewList,
          changeActiveWaste,
          overviewWaste,
          activeWaste,
          mapDefaultGenerator,
          loading,
          gridLoader,
          deleteProfile,
          showDelete,
          tileLoader,
          moduleLoading,
          printLoader,
          setPrintLoader,
          downloadLoader,
          setDownloadLoader,
          // gotoPage,
          // pageNo,
          // totalRecords,
        }}
      />
    )
  }

export default enhancer
