import React, { useState, useEffect } from "react"
import api from "api"
import {
  ALL_LDR_CLASS,
  AVAILABLE_EPA_WASTE_CODES,
  AVAILABLE_STATE_CODE,
  AVAILABLE_EPA_UHCS,
  AVAILABLE_LDR_UHCS,
  AVAILABLE_LDR_RCRA_CODE,
  SELECTED_EPA_WASTE_CODES,
  SELECTED_STATE_CODE,
  SELECTED_EPA_UHCS,
  SELECTED_LDR_RCRA_CODE,
  SELECTED_LDR_UHCS,
  // ALL_TEXAS_SHIPPING_WASTE_CODES,
  SET_PROGRESS_STEP,
  GET_PROGRESS_STEP,
  CURRENT_PROFILE,
  SET_ACTIVE_STEP,
  SET_PROGRESS_STEP_ONLY,
} from "store/reducer/actionType"
import { getOptions } from "routes/Utilities/util"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import {
  getBooleanValues,
  getObjectValues,
  getValues,
} from "routes/Utilities/util"
import * as Yup from "yup"
import { useHistory } from "react-router-dom"
import { useHarsco } from "shared"

let statereginfoenhancer =
  (Component) =>
  ({ ...props }) => {
    const history = useHistory()
    const { profile } = useHarsco()
    const [showError, setShowError] = useState(false)
    const [hasLDRError, setHasLDRError] = useState(false)
    const [naicsCode, setNaicsCode] = useState({
      naics1: "",
      naics2: "",
      naics3: "",
      naics4: "",
    })
    const [txState, setTxState] = useState("")
    const generatorDetails = useSelector(
      (state) => state.generatorDetails.generatorDetails
    )
    const error_highlight = useSelector(
      (state) => state.profileDetails.errorHighlight
    )
    const isTxCode = generatorDetails?.shippingState === "Texas" ? true : false
    let wasteWaterOpts = {
      id: 1,
      options: [
        {
          l: "Non-Waste Water",
          v: 0,
        },
        {
          l: "Waste Water",
          v: 1,
        },
      ],
    }

    const dispatch = useDispatch()

    // const [noUhcFlag, setNoUhcFlag] = useState(false)

    const currentProfile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )
    const { id, type } = useParams()

    const ldrClassCodes = useSelector(
      (state) => state.profileDetails?.allLDRClass
    )

    let ldrClassOpts = getOptions(ldrClassCodes, "description", "value")
    ldrClassOpts =
      ldrClassOpts?.["0"]?.v === 0 ? ldrClassOpts?.splice(1) : ldrClassOpts

    const epaWasteCodes = useSelector(
      (state) => state.profileDetails?.availableEpaWasteCodes
    )

    let EpaWasteCodeOpts = getOptions(epaWasteCodes, "epaCodeDesc", "epaCodeId")

    const epaUhcCodes = useSelector(
      (state) => state.profileDetails?.availableEpaUhcs
    )

    let EpaUhcOpts = getOptions(epaUhcCodes, "ldrdesc", "ldrCodesId")

    const epaStateCodes = useSelector(
      (state) => state.profileDetails?.availableStateCode
    )

    let EpaStateCodeOpts = getOptions(
      epaStateCodes,
      "stateCodeDesc",
      "epaCodeId"
    )

    const ldrUhcCodes = useSelector(
      (state) => state.profileDetails?.availableLdrUhcs
    )

    let ldrUhcOpts = getOptions(ldrUhcCodes, "ldrdesc", "ldrCodesId")

    const ldrRcraCodes = useSelector(
      (state) => state.profileDetails?.availableLdrRcraCode
    )

    let ldrRcraOpts = getOptions(ldrRcraCodes, "ldrdesc", "ldrCodesId")

    let selectedEpaWasteCodes = useSelector(
      (state) => state?.profileDetails?.selectedEpaWasteCodes
    )

    let selectedEpaWasteOpts = getOptions(
      selectedEpaWasteCodes,
      "epaCodeDesc",
      "epaCodeId"
    )

    let selectedEpaUhcCodes = useSelector(
      (state) => state?.profileDetails?.selectedEpaUhcs
    )

    let selectedEpaUhcOpts = getOptions(
      selectedEpaUhcCodes,
      "ldrdesc",
      "ldrCodesId"
    )

    let selectedEpaStateCodes = useSelector(
      (state) => state?.profileDetails?.selectedStateCode
    )

    let selectedEpaStateCodeOpts = getOptions(
      selectedEpaStateCodes,
      "stateCodeDesc",
      "epaCodeId"
    )

    let selectedLdrRcraCodes = useSelector(
      (state) => state?.profileDetails?.selectedLdrRcraCode
    )

    let selectedLdrRcraOpts = getOptions(
      selectedLdrRcraCodes,
      "ldrdesc",
      "ldrCodesId"
    )

    let selectedLdrUhcCodes = useSelector(
      (state) => state?.profileDetails?.selectedLdrUhcs
    )

    let selectedLdrUhcOpts = getOptions(
      selectedLdrUhcCodes,
      "ldrdesc",
      "ldrCodesId"
    )

    let texasShippingWasteCodes = useSelector(
      (state) => state?.profileDetails?.allTexasShippingWasteCodes
    )

    let texasCodeOpts = getOptions(
      texasShippingWasteCodes,
      "epaCode",
      "epaCodeId"
    )

    // let wasteCodeRes =
    //   selectedEpaWasteOpts && selectedEpaWasteOpts?.length > 0
    //     ? selectedEpaWasteOpts?.map((item) => item.l).join("\n")
    //     : ""
    // let uhcCodeRes =
    //   selectedEpaUhcOpts && selectedEpaUhcOpts?.length > 0
    //     ? selectedEpaUhcOpts?.map((item) => item.l).join("\n")
    //     : ""
    // let stateCodeRes =
    //   selectedEpaStateCodeOpts && selectedEpaStateCodeOpts?.length > 0
    //     ? selectedEpaStateCodeOpts?.map((item) => item.l).join("\n")
    //     : ""
    // let ldrRcraRes =
    //   selectedLdrRcraOpts && selectedLdrRcraOpts?.length > 0
    //     ? selectedLdrRcraOpts?.map((item) => item.l).join("\n")
    //     : ""
    // let ldrUhcRes =
    //   selectedLdrUhcOpts && selectedLdrUhcOpts?.length > 0
    //     ? selectedLdrUhcOpts?.map((item) => item.l).join("\n")
    //     : ""
    const [epaWasteCode, setEPAWasteCode] = useState("")
    const [epaUhc, setEPAUHC] = useState("")
    const [epaStateCode, setEPAStateCode] = useState("")
    const [ldrRcraCode, setLdrRcraCode] = useState("")
    const [ldrUhcs, setLdrUhcs] = useState("")

    const initValues = {
      federalUniversalWaste: false,
      generatorStateUniversalWaste: false,
      rcraNonHazExempt: false,
      epaStateExemption: "",
      epaWasteCode: "",
      epaUhc: "",
      epaStateCode: "",
      epaStateCodeFlag: epaStateCode?.length > 0 ? true : false,
      txInStateCode: "",
      waDesignation: "",
      ldrFlag: false,
      ldrRcraCode: "",
      ldrUhcs: "",
      noUhcsFlag: false,
      wasteWaterFlag: null,
      ldrClassId: { l: "Select LDR Class", v: 0 },
      benzeneWasteFlag: false,
      hazardousWasteNeshapFlag: false,
      naicsCodes: "",
      naicsCodes1Flag: false,
      naicsCodes2Flag: false,
      annualBenzene: null,
      benzeneConcentration: "",
      yearTabCalculates: "",
      maxBenzene: "",
      benzeneWater: false,
      maxBenzeneFlag: false,
      benzeneSectionFlag: false,
    }
    // const initialValues = initValues
    const initStateRegErrors = {
      federalUniversalWaste: false,
      generatorStateUniversalWaste: false,
      rcraNonHazExempt: false,
      epaStateExemption: false,
      epaWasteCode: false,
      epaUhc: false,
      epaStateCode: false,
      epaStateCodeFlag: false,
      txInStateCode: false,
      waDesignation: false,
      ldrFlag: false,
      ldrRcraCode: false,
      ldrUhcs: false,
      noUhcsFlag: false,
      wasteWaterFlag: false,
      ldrClassId: false,
      benzeneWasteFlag: false,
      hazardousWasteNeshapFlag: false,
      naicsCodes: false,
      naicsCodes1Flag: false,
      naicsCodes2Flag: false,
      annualBenzene: false,
      benzeneConcentration: false,
      yearTabCalculates: false,
      maxBenzene: false,
      benzeneWater: false,
      maxBenzeneFlag: false,
      benzeneSectionFlag: false,
    }

    const [initialValues, setInitialValues] = useState(initValues)
    const [errorFields, setErrorFields] = useState(initStateRegErrors)

    let valSchema = {
      federalUniversalWaste: Yup.boolean()
        // .oneOf([true], 'Please select yes or no')
        .required("Please select yes or no"),
      generatorStateUniversalWaste: Yup.boolean()
        // .oneOf([true], 'Please select yes or no')
        .required("Please select yes or no"),
      rcraNonHazExempt: Yup.boolean(),
      // .oneOf([true], "This checkbox is mandatory")
      // .required("This checkbox is mandatory"),
      epaStateExemption: Yup.string().when("rcraNonHazExempt", (val) => {
        if (val === true)
          return Yup.string().required("Please enter Exemption code ")
      }),
      // epaWasteCode: Yup.string().required("Please select EPA Waste Code(s)"),
      // epaUhc: Yup.string().required("Please select EPA Uhc(s)"),
      epaStateCodeFlag: Yup.boolean(),
      txInStateCode: Yup.string().when([], {
        is: () => isTxCode,
        then: Yup.string()
          .matches(
            /^([A-Za-z0-9]{8})$/,
            "Must be an 8 character alphanumeric code"
          ) // 8 alphanumeric characters
          .required("Texas State Waste code is required"), // Make it required
        otherwise: Yup.string(), // Optional when state is false
      }),

      // txInStateCode: Yup.string().when("epaStateCodeFlag", {
      //   is: true,
      //   then: (schema) =>
      //     schema
      //       .matches(/^[a-zA-Z\s]*$/, "Enter valid state code")
      //       .required("Please enter state code"),
      //   otherwise: (schema) =>
      //     schema.test({
      //       test: (value) => !value || /^[a-zA-Z]+$/.test(value),
      //       message: "Enter valid state code",
      //       // Set to undefined if you don't want any error message when empty
      //       exclusive: false,
      //     }),
      // }),
      waDesignation: Yup.string(),
      ldrFlag: Yup.boolean(),
      // ldrRcraCode: Yup.string().required("Please select LDR RCRA Code(s)"),
      // ldrUhcs: Yup.string().required("Please select LDR Uhc(s)"),
      wasteWaterFlag: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.boolean(),
        })
        .nullable(),
      ldrClassId: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number(),
        })
        .nullable(),
      noUhcsFlag: Yup.boolean(),
      benzeneWasteFlag: Yup.boolean().required("Please select yes or no"),
      hazardousWasteNeshapFlag: Yup.boolean().required(
        "Please select yes or no"
      ),
      naicsCodes: Yup.string(),
      naicsCodes1Flag: Yup.boolean(),
      naicsCodes2Flag: Yup.boolean(),
      annualBenzene: Yup.string().nullable(),
      benzeneConcentration: Yup.string(),
      yearTabCalculates: Yup.string().nullable(),
      maxBenzene: Yup.number().when("maxBenzeneFlag", (val) => {
        if (val === true)
          return Yup.number().required("Please enter Max Benzene value ")
      }),
      benzeneWater: Yup.boolean(),
      maxBenzeneFlag: Yup.boolean(),
      benzeneSectionFlag: Yup.boolean(),
    }

    const getProfileData = async (reqId) => {
      const req = {
        profileRequestId: id || reqId,
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const onSubmit = async (formData) => {
      if (hasLDRError) {
        return
      }
      props.setShowAlert(false)
      let required_length = 4
      if (formData?.rcraNonHazExempt) required_length = required_length + 1
      if (isTxCode) required_length = required_length + 1
      if (formData?.maxBenzeneFlag) required_length = required_length + 1
      const payload = {
        ...formData,
        roleId: "GenericRole",
        userName: profile?.email,
        profileRequestId: currentProfile?.profileRequestId || id,
        federalUniversalWaste: formData?.federalUniversalWaste,
        generatorStateUniversalWaste: formData?.generatorStateUniversalWaste,
        rcraNonHazExempt: formData?.rcraNonHazExempt ? 1 : 0,
        epaStateExemption: formData?.epaStateExemption || "",
        // epaWasteCode: formData?.epaWasteCode || "",
        // epaUhc: formData?.epaUhc || "",
        // epaStateCode: formData?.epaStateCode || "",
        txInStateCode: formData?.txInStateCode || "",
        waDesignation: formData?.waDesignation || "",
        ldrFlag: formData?.ldrFlag,
        // ldrRcraCode: formData?.ldrRcraCode || "",
        // ldrUhcs: noUhcFlag ? "" : formData?.ldrUhcs,
        noUhcsFlag: formData?.noUhcsFlag,
        wasteWaterFlag: formData?.wasteWaterFlag?.v ? true : false,
        ldrClassId: formData?.ldrClassId?.v || null,
        benzeneWasteFlag: formData?.benzeneWasteFlag ? 1 : 0,
        hazardousWasteNeshapFlag: formData?.hazardousWasteNeshapFlag,
        naicsCodes: formData?.naicsCodes?.toString() || "",
        naicsCodes1Flag: formData?.naicsCodes1Flag,
        naicsCodes2Flag: formData?.naicsCodes2Flag,
        annualBenzene: formData.annualBenzene
          ? `${formData.annualBenzene}`
          : null,
        yearTabCalculates: `${formData?.yearTabCalculates}` || "",
        maxBenzene: `${formData?.maxBenzene}` || "",
        benzeneWater: formData?.benzeneWater ? 1 : 0,
        maxBenzeneFlag: formData?.maxBenzeneFlag ? 1 : 0,
        benzeneSectionFlag: formData?.benzeneSectionFlag ? 1 : 0,
      }
      try {
        let res = await api.p_saveStateRegulatoryInfo(payload)
        if (!res) return
        setProgress(formData, required_length)
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {
        // dispatch({
        //   type: "SET_CHANGE_CUSTOMER",
        //   status: false,
        //   customer_name: changeCustomer?.customer,
        //   source: false,
        // })
      }
    }

    const onSubmitProgress = async (formData, step) => {
      let required_length = 4
      if (formData?.rcraNonHazExempt) required_length = required_length + 1
      if (isTxCode) required_length = required_length + 1
      if (formData?.maxBenzeneFlag) required_length = required_length + 1
      const payload = {
        ...formData,
        roleId: "GenericRole",
        userName: profile?.email,
        profileRequestId: currentProfile?.profileRequestId || id,
        federalUniversalWaste: formData?.federalUniversalWaste,
        generatorStateUniversalWaste: formData?.generatorStateUniversalWaste,
        rcraNonHazExempt: formData?.rcraNonHazExempt ? 1 : 0,
        epaStateExemption: formData?.epaStateExemption || "",
        txInStateCode: formData?.txInStateCode || "",
        waDesignation: formData?.waDesignation || "",
        ldrFlag: formData?.ldrFlag,
        noUhcsFlag: formData?.noUhcsFlag,
        wasteWaterFlag: formData?.wasteWaterFlag?.v ? true : false,
        ldrClassId: formData?.ldrClassId?.v || null,
        benzeneWasteFlag: formData?.benzeneWasteFlag ? 1 : 0,
        hazardousWasteNeshapFlag: formData?.hazardousWasteNeshapFlag,
        naicsCodes: formData?.naicsCodes?.toString() || "",
        naicsCodes1Flag: formData?.naicsCodes1Flag,
        naicsCodes2Flag: formData?.naicsCodes2Flag,
        annualBenzene: formData?.annualBenzene || "",
        yearTabCalculates: `${formData?.yearTabCalculates}` || "",
        maxBenzene: `${formData?.maxBenzene}` || "",
        benzeneWater: formData?.benzeneWater ? 1 : 0,
        maxBenzeneFlag: formData?.maxBenzeneFlag ? 1 : 0,
        benzeneSectionFlag: formData?.benzeneSectionFlag ? 1 : 0,
      }
      try {
        let res = await api.p_saveStateRegulatoryInfo(payload)
        if (!res) return
        setProgress(formData, required_length)
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {}
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }

    const fetchAllLDRClass = async () => {
      try {
        let res = await api.g_getAllLDRClass()
        if (!res) return

        dispatch({ type: ALL_LDR_CLASS, payload: res })
      } catch (error) {}
    }

    const fetchAvailableEpaWasteCodes = async () => {
      try {
        let res = await api.g_getAvailableEpaWasteCodes(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return

        dispatch({ type: AVAILABLE_EPA_WASTE_CODES, payload: res })
      } catch (error) {}
    }

    const fetchAvailableEpaUhcs = async () => {
      try {
        let res = await api.g_getAvailableEpaUhcs(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return

        dispatch({ type: AVAILABLE_EPA_UHCS, payload: res })
      } catch (error) {}
    }

    const fetchAvailableStateCode = async () => {
      try {
        let res = await api.g_getAvailableStateCode(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return

        dispatch({ type: AVAILABLE_STATE_CODE, payload: res })
      } catch (error) {}
    }

    const fetchAvailableLdrUhcs = async () => {
      try {
        let res = await api.g_getAvailableLdrUhcs(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return

        dispatch({ type: AVAILABLE_LDR_UHCS, payload: res })
      } catch (error) {}
    }

    const fetchAvailableLdrRcraCode = async () => {
      try {
        let res = await api.g_getAvailableLdrRcraCode(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return
        dispatch({ type: AVAILABLE_LDR_RCRA_CODE, payload: res })
      } catch (error) {}
    }
    const fetchSelectedEpaWasteCodes = async () => {
      try {
        let res = await api.g_getSelectedEpaWasteCodes(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return
        dispatch({ type: SELECTED_EPA_WASTE_CODES, payload: res })
      } catch (error) {}
    }

    const fetchSelectedStateCodes = async () => {
      try {
        let res = await api.g_getSelectedStateCodes(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return
        dispatch({ type: SELECTED_STATE_CODE, payload: res })
      } catch (error) {}
    }
    const fetchSelectedEpaUhcs = async () => {
      try {
        let res = await api.g_getSelectedEpaUhcs(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return
        dispatch({ type: SELECTED_EPA_UHCS, payload: res })
      } catch (error) {}
    }
    const fetchSelectedLdrRcraCode = async () => {
      try {
        let res = await api.g_getSelectedLdrRcraCode(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return
        dispatch({ type: SELECTED_LDR_RCRA_CODE, payload: res })
      } catch (error) {}
    }
    const fetchSelectedLdrUhcs = async () => {
      try {
        let res = await api.g_getSelectedLdrUhcs(
          currentProfile?.profileRequestId || 1
        )
        if (!res) return
        dispatch({ type: SELECTED_LDR_UHCS, payload: res })
      } catch (error) {}
    }

    // const fetchTexasShippingWasteCodes = async () => {
    //   try {
    //     let res = await api.g_getAllTexasShippingWasteCodes()
    //     if (!res) return
    //     dispatch({ type: ALL_TEXAS_SHIPPING_WASTE_CODES, payload: res })
    //   } catch (error) {}
    // }

    const getCodeOptions = (keys) => {
      switch (keys) {
        case "wasteWaterFlag":
          return wasteWaterOpts?.options
        case "ldrClassId":
          return ldrClassOpts
        default:
          return []
      }
    }

    // const handlePopupCancel = async (type) => {
    //   switch (type) {
    //     case "EPAWaste":
    //       // let selectedEpaCodesReq = _.cloneDeep(selectedCodes)
    //       // selectedEpaCodesReq.map(async (item) => {
    //       //   item.EpaCodeId = item.epaCodeId
    //       //   item.EpaCode = item.epaCode
    //       //   item.Desc = item.desc
    //       //   item.EpaCodeDesc = item.epaCodeDesc
    //       //   item.SeqNum = item.origin
    //       //   delete item.l
    //       //   delete item.v
    //       //   delete item.key
    //       //   delete item.epaCodeId
    //       //   delete item.epaCode
    //       //   delete item.desc
    //       //   delete item.epaCodeDesc
    //       // })
    //       try {
    //         let epaWasteReq = {
    //           profileRequestId: currentProfile?.profileRequestId || 1,
    //           epaWasteCodes: selectedEpaWasteOpts,
    //           // epaWasteCodes: selectedEpaCodesReq,
    //         }
    //         let res = await api.p_saveEpaWasteCode(epaWasteReq)
    //         let payloadRes = await api.g_getSelectedEpaWasteCodes(
    //           res?.data?.profileRequestId
    //         )
    //         dispatch({ type: SELECTED_EPA_WASTE_CODES, payload: payloadRes })
    //       } catch (error) {}
    //       break
    //     // case "EPAUHC":
    //     //   let selectedCodesUhcReq = _.cloneDeep(selectedCodes)
    //     //   selectedCodesUhcReq.map(async (item) => {
    //     //     item.LdrCodesId = item.ldrCodesId
    //     //     delete item.l
    //     //     delete item.v
    //     //   })
    //     //   try {
    //     //     let epaUhcReq = {
    //     //       profileRequestId: profileId || 1,
    //     //       EpaUhcsRequest: selectedCodesUhcReq,
    //     //     }
    //     //     let res = await api.p_saveEpaUhc(epaUhcReq)
    //     //     let payloadRes = await api.g_getSelectedEpaUhcs(
    //     //       res?.data?.profileRequestId
    //     //     )
    //     //     dispatch({ type: SELECTED_EPA_UHCS, payload: payloadRes })
    //     //   } catch (error) {}
    //     //   break
    //     // case "stateCode":
    //     //   let selectedStateCodeReq = _.cloneDeep(selectedCodes)
    //     //   selectedStateCodeReq.map(async (item) => {
    //     //     item.SeqNum = item.origin
    //     //     delete item.l
    //     //     delete item.v
    //     //     delete item.key
    //     //   })
    //     //   try {
    //     //     let epaStateReq = {
    //     //       profileRequestId: profileId || 1,
    //     //       stateCodeRequest: selectedStateCodeReq,
    //     //     }
    //     //     let res = await api.p_saveStateCode(epaStateReq)
    //     //     // let selectedReq = {
    //     //     //   profileRequestId: res?.data?.profileRequestId,
    //     //     // }
    //     //     let payloadRes = await api.g_getSelectedStateCodes(
    //     //       res?.data?.profileRequestId || 1
    //     //     )
    //     //     dispatch({ type: SELECTED_STATE_CODE, payload: payloadRes })
    //     //   } catch (error) {}
    //     //   break
    //     // case "LdrRCRA":
    //     //   try {
    //     //     let ldrRcraReq = {
    //     //       profileRequestId: profileId || 1,
    //     //       ldrRcraCodeDetailRequest: selectedCodes,
    //     //     }
    //     //     let res = await api.p_saveLdrRcraCode(ldrRcraReq)
    //     //     let payloadRes = await api.g_getSelectedLdrRcraCode(
    //     //       res?.data?.profileRequestId
    //     //     )
    //     //     dispatch({ type: SELECTED_LDR_RCRA_CODE, payload: payloadRes })
    //     //   } catch (error) {}
    //     //   break
    //     // case "LdrUHC":
    //     //   try {
    //     //     let ldrUhcReq = {
    //     //       profileRequestId: profileId || 1,
    //     //       ldrUhcDetailRequest: selectedCodes,
    //     //     }
    //     //     let res = await api.p_saveLdrUhc(ldrUhcReq)
    //     //     let payloadRes = await api.g_getSelectedLdrUhcs(
    //     //       res?.data?.profileRequestId
    //     //     )
    //     //     dispatch({ type: SELECTED_LDR_UHCS, payload: payloadRes })
    //     //   } catch (error) {}
    //     //   break
    //     default:
    //       break
    //   }
    // }

    const setProgress = (data, length) => {
      let count = 0
      let non_requiredcount = 0
      let required_length = 4
      if (data["rcraNonHazExempt"]) required_length = required_length + 1
      if (isTxCode) required_length = required_length + 1
      if (data["maxBenzeneFlag"]) required_length = required_length + 1
      if (epaWasteCode.length > 0) required_length = required_length + 1
      if (epaUhc.length > 0) required_length = required_length + 1
      for (var prop in initValues) {
        if (prop == "wasteWaterFlag") {
          if (data[prop]?.v) {
            non_requiredcount = non_requiredcount + 1
          }
        } else if (prop == "ldrClassId") {
          if (data[prop]?.v) {
            non_requiredcount = non_requiredcount + 1
          }
        } else if (
          prop == "federalUniversalWaste" ||
          prop == "generatorStateUniversalWaste" ||
          prop == "benzeneWasteFlag" ||
          prop == "hazardousWasteNeshapFlag"
        ) {
          if (data[prop] == true || data[prop] == false) {
            count = count + 1
          }
        } else if (prop == "epaStateExemption") {
          if (data["rcraNonHazExempt"] == true && data[prop]) {
            count = count + 1
          }
        } else if (prop == "txInStateCode" && isTxCode) {
          if (data[prop]) {
            count = count + 1
          }
        } else if (prop == "maxBenzene" && data["maxBenzeneFlag"]) {
          if (data[prop]) {
            count = count + 1
          }
        } else {
          if (data[prop]) {
            non_requiredcount = non_requiredcount + 1
          }
        }
      }
      if (epaWasteCode.length > 0 && ldrRcraCode.length > 0) {
        count = count + 1
      }
      if (epaUhc.length > 0 && ldrUhcs.length > 0) {
        count = count + 1
      }
      dispatch({
        type: SET_PROGRESS_STEP,
        step: 5,
        progressStep: props.showAlert ? props.seekingStep + 1 : 6,
        percentage: (count / required_length) * 100,
        numerator: count,
        denominator: required_length,
      })
    }

    const calcProgress = async (count, leng) => {
      await dispatch({
        type: GET_PROGRESS_STEP,
        step: 5,
        progressStep: 5,
        percentage: (count / leng) * 100,
        numerator: count,
        denominator: leng,
      })
    }

    const codeResponseSetter = () => {
      for (var item in initValues) {
        if (item === "epaWasteCode") {
          let wasteCodes =
            currentProfile?.epaWasteCode &&
            JSON.parse(currentProfile?.epaWasteCode)
          let wasteResponse =
            wasteCodes?.length > 0
              ? wasteCodes?.map((item) => item.EpaCode).join("\n")
              : ""
          setEPAWasteCode(wasteResponse)
        } else if (item === "epaUhc") {
          let uhcCodes =
            currentProfile?.epaUhc && JSON.parse(currentProfile?.epaUhc)
          let epaUhcResponse =
            uhcCodes?.length > 0
              ? uhcCodes?.map((item) => item.Ldrdesc).join("\n")
              : ""
          setEPAUHC(epaUhcResponse)
        } else if (item === "epaStateCode") {
          let stateCodes =
            currentProfile?.epaStateCode &&
            JSON.parse(currentProfile?.epaStateCode)
          let epaStateResponse =
            stateCodes?.length > 0
              ? stateCodes?.map((item) => item.StateCodeDesc).join("\n")
              : ""
          setEPAStateCode(epaStateResponse)
        } else if (item === "ldrUhcs") {
          let ldrUhc =
            currentProfile?.ldrUhcs && JSON.parse(currentProfile?.ldrUhcs)
          let uhcResponse =
            ldrUhc?.length > 0
              ? ldrUhc?.map((item) => item.Ldrdesc).join("\n")
              : ""
          setLdrUhcs(uhcResponse)
        } else if (item === "ldrRcraCode") {
          let ldrRcra =
            currentProfile?.ldrRcraCode &&
            JSON.parse(currentProfile?.ldrRcraCode)
          let rcraResponse =
            ldrRcra?.length > 0
              ? ldrRcra?.map((item) => item.Ldrdesc).join("\n")
              : ""
          setLdrRcraCode(rcraResponse)
        }
      }
      let LdrRcraError = false
      let LdrUhcError = false
      if (
        epaWasteCode?.length > 0 &&
        (ldrRcraCode === null ||
          ldrRcraCode === undefined ||
          ldrRcraCode?.length === 0)
      )
        LdrRcraError = true
      if (
        epaUhc?.length > 0 &&
        (ldrUhcs === null || ldrUhcs === undefined || ldrUhcs?.length === 0)
      )
        LdrUhcError = true
      setHasLDRError(LdrRcraError || LdrUhcError)
    }

    const customeValidateStateReg = () => {
      let data = {}
      let errorsData = {}
      if (error_highlight?.["5"]?.errorFlag) {
        let rcraFlag = getBooleanValues(
          currentProfile,
          "rcraNonHazExempt",
          initValues?.["rcraNonHazExempt"]
        )
        let maxbenzflag = getBooleanValues(
          currentProfile,
          "maxBenzeneFlag",
          initValues?.["maxBenzeneFlag"]
        )
        for (var val in initValues) {
          if (
            val == "federalUniversalWaste" ||
            val == "generatorStateUniversalWaste" ||
            val == "benzeneWasteFlag" ||
            val == "hazardousWasteNeshapFlag"
          ) {
            data[val] = getBooleanValues(currentProfile, val, initValues?.[val])
            if (data[val] == true || data[val] == false) {
              errorsData[val] = false
            } else errorsData[val] = true
          } else if (
            val == "rcraNonHazExempt" ||
            val == "ldrFlag" ||
            val == "naicsCodes1Flag" ||
            val == "naicsCodes2Flag" ||
            val == "benzeneWater" ||
            val == "maxBenzeneFlag" ||
            val == "benzeneSectionFlag" ||
            val == "noUhcsFlag"
            // prop == "wasteWaterFlag" ||
            // prop == "ldrClassId"
          ) {
            data[val] = getBooleanValues(currentProfile, val, initValues?.[val])
            if (data[val]) errorsData[val] = false
          } else if (val == "epaStateExemption") {
            data[val] = getValues(currentProfile, val, initValues?.[val])
            if (rcraFlag == true && data[val]) {
              errorsData[val] = false
            } else errorsData[val] = true
          }
          // else if (prop == "txInStateCode" && isTxCode) {
          //   data[prop] = getValues(currentProfile, prop, initValues?.[prop])
          //   if(data[prop]){
          //     count = count + 1
          //   }
          // }
          else if (val == "maxBenzene") {
            data[val] = getValues(currentProfile, val, initValues?.[val])
            if (maxbenzflag === true && data[val]) {
              errorsData[val] = false
            } else errorsData[val] = true
          } else if (val === "txInStateCode") {
            if (isTxCode) {
              data[val] = getValues(currentProfile, val, initValues?.[val])
            } else data[val] = ""
            setTxState(data[val])
            if (data[val] && isTxCode) errorsData[val] = false
            else errorsData[val] = true
          } else if (val === "waDesignation") {
            let waDesignationVal = getValues(
              currentProfile,
              val,
              initValues?.[val]
            )
            data[val] = waDesignationVal
            if (
              waDesignationVal !== null &&
              waDesignationVal !== undefined &&
              waDesignationVal?.length > 0
            )
              data["ldrFlag"] = true
            if (data[val]) errorsData[val] = false
          } else if (val === "naicsCodes") {
            let codes = getValues(currentProfile, val, initValues?.[val])
            let codeRanges = codes.split(",")
            data[val] = codes
            setNaicsCode({
              ...naicsCode,
              naics1: codeRanges[0] || "",
              naics2: codeRanges[1] || "",
              naics3: codeRanges[2] || "",
              naics4: codeRanges[3] || "",
            })
            if (data[val]) errorsData[val] = false
          } else if (val == "wasteWaterFlag") {
            data[val] = currentProfile[val]
              ? { l: "Waste Water", v: 1 }
              : { l: "Non-Waste Water", v: 0 }
            if (data[val].v !== null) errorsData[val] = false
          } else if (val == "ldrClassId") {
            data[val] = getObjectValues(
              getCodeOptions(val),
              currentProfile,
              val,
              initValues?.[val]
            )
            if (data[val]?.v) errorsData[val] = false
          } else {
            data[val] = getValues(currentProfile, val, initValues?.[val])
            if (data[val]) errorsData[val] = false
          }
        }
        setErrorFields(errorsData)
      }
    }

    useEffect(() => {
      customeValidateStateReg()
      if (type !== "viewprofile") {
        getProfileData(currentProfile?.profileRequestId)
      }
      //getProfileData(currentProfile?.profileRequestId)
    }, [error_highlight])

    useEffect(() => {
      let data = {}
      if (currentProfile?.generatorId || type == "viewprofile") {
        // console.log("currentProfile: ", currentProfile)
        let count = 0
        let non_requiredcount = 0
        let required_length = 4
        let rcraFlag = getBooleanValues(
          currentProfile,
          "rcraNonHazExempt",
          initValues?.["rcraNonHazExempt"]
        )
        if (rcraFlag) required_length = required_length + 1
        if (isTxCode) required_length = required_length + 1
        let maxbenzflag = getBooleanValues(
          currentProfile,
          "maxBenzeneFlag",
          initValues?.["maxBenzeneFlag"]
        )
        if (maxbenzflag) required_length = required_length + 1
        let wasteCodes =
          currentProfile?.epaWasteCode &&
          JSON.parse(currentProfile?.epaWasteCode)
        let wasteResponse =
          wasteCodes?.length > 0
            ? wasteCodes?.map((item) => item.EpaCode).join("\n")
            : ""
        if (wasteResponse.length > 0) required_length = required_length + 1
        let uhcCodes =
          currentProfile?.epaUhc && JSON.parse(currentProfile?.epaUhc)
        let epaUhcResponse =
          uhcCodes?.length > 0
            ? uhcCodes?.map((item) => item.Ldrdesc).join("\n")
            : ""
        if (epaUhcResponse.length > 0) required_length = required_length + 1
        for (var prop in initValues) {
          if (
            prop == "federalUniversalWaste" ||
            prop == "generatorStateUniversalWaste" ||
            prop == "benzeneWasteFlag" ||
            prop == "hazardousWasteNeshapFlag"
          ) {
            data[prop] = getBooleanValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop] == true || data[prop] == false) {
              count = count + 1
            }
          } else if (
            prop == "rcraNonHazExempt" ||
            prop == "ldrFlag" ||
            prop == "naicsCodes1Flag" ||
            prop == "naicsCodes2Flag" ||
            prop == "benzeneWater" ||
            prop == "maxBenzeneFlag" ||
            prop == "benzeneSectionFlag" ||
            prop == "noUhcsFlag"
            // prop == "wasteWaterFlag" ||
            // prop == "ldrClassId"
          ) {
            data[prop] = getBooleanValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]) non_requiredcount = non_requiredcount + 1
          } else if (prop == "epaStateExemption") {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (rcraFlag == true && data[prop]) {
              count = count + 1
            }
          }
          // else if (prop == "txInStateCode" && isTxCode) {
          //   data[prop] = getValues(currentProfile, prop, initValues?.[prop])
          //   if(data[prop]){
          //     count = count + 1
          //   }
          // }
          else if (prop == "maxBenzene") {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (maxbenzflag && data[prop]) {
              count = count + 1
            }
          } else if (prop === "epaWasteCode") {
            setEPAWasteCode(wasteResponse)
          } else if (prop === "epaUhc") {
            setEPAUHC(epaUhcResponse)
          } else if (prop === "epaStateCode") {
            let stateCodes =
              currentProfile?.epaStateCode &&
              JSON.parse(currentProfile?.epaStateCode)
            let epaStateResponse =
              stateCodes?.length > 0
                ? stateCodes?.map((item) => item.Desc).join("\n")
                : ""
            setEPAStateCode(epaStateResponse)
          } else if (prop === "ldrUhcs") {
            let ldrUhc =
              currentProfile?.ldrUhcs && JSON.parse(currentProfile?.ldrUhcs)
            let uhcResponse =
              ldrUhc?.length > 0
                ? ldrUhc?.map((item) => item.Ldrdesc).join("\n")
                : ""
            setLdrUhcs(uhcResponse)
            if (uhcResponse.length > 0) {
              data["ldrFlag"] = true
            }
            if (epaUhcResponse.length > 0 && uhcResponse.length > 0) {
              count = count + 1
            }
          } else if (prop === "ldrRcraCode") {
            let ldrRcra =
              currentProfile?.ldrRcraCode &&
              JSON.parse(currentProfile?.ldrRcraCode)
            let rcraResponse =
              ldrRcra?.length > 0
                ? ldrRcra?.map((item) => item.Ldrdesc).join("\n")
                : ""
            setLdrRcraCode(rcraResponse)
            if (rcraResponse.length > 0) {
              data["ldrFlag"] = true
            }
            if (wasteResponse.length > 0 && rcraResponse.length > 0) {
              count = count + 1
            }
          } else if (prop === "txInStateCode") {
            if (isTxCode) {
              data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            } else data[prop] = ""
            setTxState(data[prop])
            if (data[prop] && isTxCode) count = count + 1
          } else if (prop === "yearTabCalculates") {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) non_requiredcount = non_requiredcount + 1
          } else if (prop === "waDesignation") {
            let waDesignationVal = getValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            data[prop] = waDesignationVal
            if (
              waDesignationVal !== null &&
              waDesignationVal !== undefined &&
              waDesignationVal?.length > 0
            )
              data["ldrFlag"] = true
            if (data[prop]) non_requiredcount = non_requiredcount + 1
          } else if (prop === "naicsCodes") {
            let codes = getValues(currentProfile, prop, initValues?.[prop])
            let codeRanges = codes.split(",")
            data[prop] = codes
            setNaicsCode({
              ...naicsCode,
              naics1: codeRanges[0] || "",
              naics2: codeRanges[1] || "",
              naics3: codeRanges[2] || "",
              naics4: codeRanges[3] || "",
            })
            if (data[prop]) non_requiredcount = non_requiredcount + 1
          } else if (prop == "wasteWaterFlag") {
            data[prop] = currentProfile[prop]
              ? { l: "Waste Water", v: 1 }
              : { l: "Non-Waste Water", v: 0 }
            if (data[prop].v !== null) non_requiredcount = non_requiredcount + 1
          } else if (prop == "ldrClassId") {
            data[prop] = getObjectValues(
              getCodeOptions(prop),
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]?.v) non_requiredcount = non_requiredcount + 1
          } else {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) non_requiredcount = non_requiredcount + 1
          }
        }
        calcProgress(count, required_length)
        setInitialValues(data)
      }
      if (type !== "viewprofile") {
        fetchSelectedEpaWasteCodes()
        fetchSelectedStateCodes()
        fetchSelectedEpaUhcs()
        fetchSelectedLdrUhcs()
        fetchSelectedLdrRcraCode()
      }
      codeResponseSetter()
      // fetchTexasShippingWasteCodes()
    }, [currentProfile?.generatorId])

    useEffect(() => {
      if (type !== "viewprofile") {
        fetchAvailableEpaWasteCodes()
        fetchAvailableEpaUhcs()
        fetchAvailableStateCode()
        fetchAvailableLdrUhcs()
        fetchAvailableLdrRcraCode()
      }
      fetchAllLDRClass()
      // fetchTexasShippingWasteCodes()
      codeResponseSetter()
    }, [])

    const goToProfilePages = (page) => {
      history.push(`/profiles/${page}`)
    }

    const backAction = () => {
      dispatch({ type: SET_ACTIVE_STEP, payload: 4 })
    }

    const cancelAction = () => {
      // handlePopupCancel("EPAWaste")
      history.push(`/profiles`)
    }

    const handleAlerts = (values, errors, isValid, step) => {
      if (Object.keys(errors)?.length > 0 && !isValid) {
        setShowError(true)
        props.setShowAlert(true)
      } else if (hasLDRError) {
        setShowError(true)
        props.setShowAlert(true)
      } else {
        setShowError(false)
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(values, step)
        props.setShowAlert(false)
      }
    }

    const handleGetProfileData = () => {
      getProfileData(currentProfile?.profileRequestId)
    }

    return (
      <Component
        {...props}
        {...{
          // docData,
          // updateDocData,
          goToProfilePages,
          wasteWaterOpts,
          // LDRclassOpts,
          hasLDRError,
          setHasLDRError,
          ldrClassOpts,
          epaWasteCode,
          setEPAWasteCode,
          epaStateCode,
          setEPAStateCode,
          epaUhc,
          setEPAUHC,
          ldrRcraCode,
          setLdrRcraCode,
          ldrUhcs,
          setLdrUhcs,
          EpaStateCodeOpts,
          EpaUhcOpts,
          EpaWasteCodeOpts,
          ldrUhcOpts,
          ldrRcraOpts,
          selectedEpaWasteOpts,
          selectedEpaStateCodeOpts,
          selectedEpaUhcOpts,
          selectedLdrRcraOpts,
          selectedLdrUhcOpts,
          handleGetProfileData,
          // noUhcFlag,
          // setNoUhcFlag,
          backAction,
          naicsCode,
          setNaicsCode,
          formik,
          cancelAction,
          showError,
          setShowError,
          handleAlerts,
          isTxCode,
          txState,
          setTxState,
          texasCodeOpts,
          error_highlight,
          errorFields,
        }}
      />
    )
  }

export default statereginfoenhancer
