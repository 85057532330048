import React, { useEffect, useState } from "react"
import api from "api"
// import {
//   useHarsco,
// } from "shared"
import {
  ALL_DOCUMENT_TYPES,
  CURRENT_PROFILE,
  SET_ACTIVE_STEP,
  RETRIEVE_DOCUMENTS,
  SET_PROGRESS_STEP,
  GET_PROGRESS_STEP,
  SET_PROGRESS_STEP_ONLY,
  // SET_PROGRESS_STEP,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { useHistory } from "react-router-dom"
import { useHarsco } from "shared"
import { toast } from "react-toastify"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    const history = useHistory()
    const [files, setFiles] = useState(null)
    const [selectedDocType, setSelectedDocType] = useState({
      l: "Select",
      v: 0,
    })
    const [showError, setShowError] = useState(false)
    // eslint-disable-next-line
    const [loader, setLoader] = useState(false)
    const { id } = useParams()
    const { profile } = useHarsco()
    const currentProfile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )

    const profileList = useSelector(
      (state) => state.profileDetails.profilesList
    )
    const availDocuments = useSelector(
      (state) => state.generatorDetails.availabledocDocuments
    )
    const getProfile = profileList.find(
      (item) => item.profileId == id || item.profile == id
    )

    const finalAvailDocs =
      getProfile?.status?.toLowerCase() !== "draft" && currentProfile?.documents
        ? JSON.parse(currentProfile?.documents)
        : availDocuments?.filter(
            (val) => val?.documentTypeId !== 9 && val?.documentTypeId !== 27
          )

    const dispatch = useDispatch()
    const initValues = {
      docs: [],
    }
    const initialValues = initValues
    // const [initialValues, setInitialValues] = useState(initValues);

    let valSchema = {
      docs: Yup.array(),
    }
    const getProfileData = async (reqId) => {
      const req = {
        profileRequestId: id || reqId,
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }
    const retrieveDocuments = async (reqId) => {
      const req = {
        profileRequestId: reqId,
        documentTypeEntity: "Profile",
        documentTypeDescription: "",
      }
      try {
        let res = await api.p_getDocuments(req)
        if (!res) return
        dispatch({ type: RETRIEVE_DOCUMENTS, payload: res })
      } catch (error) {}
    }
    const downloadDocument = async (data, profileId) => {
      const getProfile = profileList.find(
        (item) => item.profileId == profileId || item.profile == profileId
      )
      const payload = {
        documentName: getProfile
          ? getProfile?.status?.toLowerCase() == "draft"
            ? data?.documentName
            : data?.filename?.replaceAll("/", "\\")
          : data?.documentName
          ? data?.documentName
          : data?.filename?.replaceAll("/", "\\"),
        isdraft: getProfile
          ? getProfile?.status?.toLowerCase() == "draft"
            ? true
            : false
          : data?.documentName
          ? true
          : false,
        // profileRequestDocumentSeqid: data?.profileRequestDocumentSeqids,
        // profileRequestId: profileId,
        // documentTypeId: data?.documentTypeId,
      }
      try {
        let res = await api.pb_downloadDocuments(payload)
        if (!res) return
        let url = window.URL.createObjectURL(res)
        let a = document.createElement("a")
        a.href = url
        a.download = getProfile
          ? getProfile?.status?.toLowerCase() == "draft"
            ? data?.documentName
            : data?.display_name
          : data?.documentName
          ? data?.documentName
          : data?.display_name
        a.click()
        a.remove()
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
        // const url = window.URL.createObjectURL(new Blob([res]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute(
        //     "download",
        //     `${data?.documentName}`
        // );
        // document.body.appendChild(link);
        // link.click();
      } catch (error) {}
    }
    const deleteDocument = async (data, profileId) => {
      try {
        let res = await api.p_deleteDocument({
          profileRequestDocumentSeqid: data?.profileRequestDocumentSeqids,
          profileRequestId: profileId,
        })
        if (!res) return
        retrieveDocuments(currentProfile?.profileRequestId)
        toast.success("Document has been deleted successfully")
      } catch (error) {}
    }

    const onSubmit = async (docType) => {
      if (!files) {
        return
      }
      props.setShowAlert(false)
      setLoader(true)
      var formData = new FormData()
      formData.append("roleId", "GenericRole")
      formData.append("userName", profile?.email)
      formData.append("profileRequestId", currentProfile?.profileRequestId)
      formData.append("documentTypeId", docType.documentTypeId)
      formData.append("documentTypeEntity", "Profile")
      formData.append(
        "documentTypeDescription",
        docType.documentTypeDescription
      )
      formData.append("tabName", "Documents")
      formData.append("files", files)

      try {
        let res = await api.f_postDocuments(formData)
        if (!res) return
        dispatch({
          type: SET_PROGRESS_STEP,
          step: 8,
          progressStep: props.showAlert ? props.seekingStep + 1 : 8,
          percentage: 100,
          numerator: 1,
          denominator: 1,
        })
        retrieveDocuments(currentProfile?.profileRequestId)
        getProfileData(currentProfile?.profileRequestId)
        toast.success("File has been Uploaded Successfully")
      } catch (err) {
        toast.error("File upload has failed")
      } finally {
        setLoader(false)
      }
    }

    const onSubmitOnly = async () => {
      try {
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: 9 })
      } catch (err) {}
    }

    const onSubmitProgress = async (docType, step) => {
      if (!files) {
        return
      }
      var formData = new FormData()
      formData.append("roleId", "GenericRole")
      formData.append("userName", profile?.email)
      formData.append("profileRequestId", currentProfile?.profileRequestId)
      formData.append("documentTypeId", docType.documentTypeId)
      formData.append("documentTypeEntity", "Profile")
      formData.append(
        "documentTypeDescription",
        docType.documentTypeDescription
      )
      formData.append("tabName", "Documents")
      formData.append("files", files)

      try {
        let res = await api.f_postDocuments(formData)
        if (!res) return
        dispatch({
          type: SET_PROGRESS_STEP,
          step: 8,
          progressStep: step,
          percentage: 100,
          numerator: 1,
          denominator: 1,
        })
        retrieveDocuments(currentProfile?.profileRequestId)
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {}
    }
    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }

    const fetchAllDocumentTypes = async () => {
      try {
        let res = await api.g_getAllDocumentTypes()
        if (!res) return

        dispatch({ type: ALL_DOCUMENT_TYPES, payload: res })
      } catch (error) {}
    }

    useEffect(() => {
      fetchAllDocumentTypes()
    }, [])

    useEffect(() => {
      if (currentProfile?.profileRequestId) {
        retrieveDocuments(currentProfile?.profileRequestId)
      }
    }, [])

    useEffect(() => {
      if (finalAvailDocs?.length > 0) {
        dispatch({
          type: GET_PROGRESS_STEP,
          step: 8,
          progressStep: 8,
          percentage: 100,
          numerator: 1,
          denominator: 1,
        })
      } else {
        dispatch({
          type: GET_PROGRESS_STEP,
          step: 8,
          progressStep: 8,
          percentage: 0,
          numerator: 0,
          denominator: 0,
        })
      }
    }, [id, finalAvailDocs?.length])

    const backAction = () => {
      dispatch({ type: SET_ACTIVE_STEP, payload: 7 })
    }

    const cancelAction = () => {
      history.push(`/profiles`)
    }

    const handleAlerts = (values, errors, isValid, step) => {
      if (!files && Boolean(selectedDocType?.v)) {
        setShowError(true)
        props.setShowAlert(true)
      } else {
        setShowError(false)
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(values, step)
        props.setShowAlert(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formik,
          backAction,
          files,
          setFiles,
          onSubmit,
          downloadDocument,
          showError,
          setShowError,
          handleAlerts,
          cancelAction,
          onSubmitOnly,
          deleteDocument,
          loader,
          selectedDocType,
          setSelectedDocType,
        }}
      />
    )
  }

export default enhancer
