import React, { useEffect, useState } from "react"
import "./style.scss"

const Input = (props) => {
  const {
    placeholder,
    name,
    searchingZip,
    value,
    onFieldChange,
    onKeyChange,
    onBlurChange,
    clsName = "",
    parentCls = "",
    search = false,
    disabled = false,
    suggestions = [],
    onSelect,
    maxLen,
    noMaxLength,
    keyName = "",
    type,
    charlen,
    reference,
    firstInputRef,
  } = props
  // state that hold API data
  const [data, setData] = useState("")
  const [showSuggestions, setShowSuggestions] = useState(false)
  useEffect(() => {
    setData(value)
  }, [value])
  return (
    <div
      className={`col-sm-12 col-md-12 col-lg-12 ${
        parentCls.length > 0 ? parentCls : "input-text p-0"
      } ${search ? "pos-relative" : ""} dp-flx`}
    >
      {search ? (
        <>
          <input
            placeholder={placeholder}
            name={name}
            className={`col-sm-12 col-md-12 col-lg-12 ellipsis-input-mni ${
              clsName.length > 0 ? clsName : "input-box"
            }`}
            style={{ padding: "0 36px 0 15px" }}
            type="search"
            value={data}
            onChange={(e) => {
              onFieldChange(e)
              setShowSuggestions(true)
            }}
            disabled={disabled}
            autoComplete="off"
            title={data}
            // list="suggestions"
          />
          <button type="button" className="search-btns" style={{ width: 30 }}>
            Search
          </button>
          {data?.length > 2 && showSuggestions && (
            <ul
              id="suggestions"
              className="autocompleteul"
              style={{
                border: suggestions?.length > 0 ? "1px solid #D2D2D2" : "none",
              }}
            >
              {suggestions?.map((el, index) => {
                if (
                  el?.[keyName].toLowerCase().includes(value?.toLowerCase())
                ) {
                  return (
                    <li
                      className="autocompleteli"
                      key={index}
                      value={el?.[keyName]}
                      onClick={() => {
                        setData(el?.[keyName])
                        onSelect(el)
                        setShowSuggestions(false)
                      }}
                      title={el?.[keyName]}
                    >
                      {el?.[keyName]}
                    </li>
                  )
                }
                return ""
              })}
            </ul>
          )}
        </>
      ) : type === "number" ? (
        <input
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          className={`col-sm-12 col-md-12 col-lg-12 ${
            clsName.length > 0 ? clsName : "input-box"
          }`}
          maxLength={maxLen}
          value={data || ""}
          onChange={(e) => onFieldChange(e)}
          onBlur={(e) => onBlurChange(e)}
        />
      ) : searchingZip ? (
        <input
          placeholder={placeholder}
          name={name}
          className={`col-sm-12 col-md-12 col-lg-12 ${
            clsName.length > 0 ? clsName : "input-box"
          }`}
          value={value}
          ref={reference ? firstInputRef : null}
          onChange={(e) => onFieldChange(e)}
          onKeyDown={(e) => onKeyChange(e)}
          onBlur={(e) => onBlurChange(e)}
          disabled={disabled}
          maxLength={noMaxLength ? 250 : charlen ? charlen : undefined}
        />
      ) : (
        <input
          placeholder={placeholder}
          name={name}
          className={`col-sm-12 col-md-12 col-lg-12 ${
            clsName.length > 0 ? clsName : "input-box"
          }`}
          value={value}
          ref={reference ? firstInputRef : null}
          onChange={(e) => onFieldChange(e)}
          disabled={disabled}
          maxLength={noMaxLength ? 250 : charlen ? charlen : undefined}
        />
      )}
    </div>
  )
}

export default React.memo(Input)
