import React, { Fragment, useRef, useEffect } from "react"
// import enhancer from "./enhancer"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Select from "shared/Select"
import Input from "routes/Admin/Components/Input"
import regulatedconstituentsenhancer from "./regulatedconstituentsenhancer"
import { Form, Formik } from "formik"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import AlertModal from "./AlertModal"

function RegulatedConstituents(props) {
  let {
    formik,
    infoSupplied,
    setInfoSupplied,
    metals,
    setMetals,
    volatileCompounds,
    setVolatileCompounds,
    semiVolatiles,
    setSemiVolatiles,
    pesticidesHerbicides,
    setPesticidesHerbicides,
    uomOpts,
    backAction,
    cancelAction,
    selectAllMetalsFlag,
    setSelectedAllMetalsFlag,
    selectAllVolatilesFlag,
    setSelectedAllVolatilesFlag,
    selectAllSemiVolatilesFlag,
    setSelectedAllSemiVolatilesFlag,
    selectAllPestHerbFlag,
    setSelectedAllPestHerbFlag,
    checkOneOf,
    showAlert,
    setShowAlert,
    submit,
    setSubmit,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    error_highlight,
    genNameforView,
  } = props

  // const { id } = useParams()

  const { type } = useParams()
  const ref = useRef()

  // const [dataFlag, setDataFlag] = useState(true)

  const currentProfile = useSelector(
    (state) => state.profileDetails?.currentProfile
  )
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )

  const getDefaultGeneratorName = (id) => {
    return (
      generatorOptions?.filter((val) => val.generatorId === id)?.[0]
        ?.generatorName || ""
    )
  }

  // useEffect(() => {
  //   if (id && JSON.parse(current_profile?.profileMetals)?.length > 0) {
  //     setMetals(JSON.parse(current_profile?.profileMetals))
  //   }
  // }, [id, current_profile])

  useEffect(() => {
    if (showAlert) {
      ref.current.click()
    }
  }, [showAlert])

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={formik.values}
        {...formik}
      >
        {({ values, errors, touched, setFieldValue, isValid }) => {
          const handleSelectAll = (cData, flag) => {
            let data = cData
            if (flag === true) {
              if (data?.length > 0) {
                data?.map((item) => {
                  item["notPresent"] = 1
                  item["belowTclpLimit"] = 0
                  item["aboveTclpLimit"] = 0
                  item["range"] = ""
                  item["unitOfMeasure"] = { l: "None", v: "0" }
                })
              }
            } else {
              if (data?.length > 0) {
                data?.map((item) => {
                  item["notPresent"] = 0
                  item["belowTclpLimit"] = 0
                  item["aboveTclpLimit"] = 0
                  item["range"] = ""
                  item["unitOfMeasure"] = { l: "None", v: "0" }
                })
              }
            }
            if (data?.category === "metals") {
              setMetals([...data])
            }
            if (data?.category === "volatile") {
              setVolatileCompounds([...data])
            }
            if (data?.category === "semivolatile") {
              setSemiVolatiles([...data])
            }
            if (data?.category === "pesticides") {
              setPesticidesHerbicides([...data])
            }
          }
          const validateRangeSymbols = (lt, gt, range, limit, measure) => {
            if (lt || gt) {
              if (measure === "below") {
                return parseFloat(range) >= parseFloat(limit)
              } else return parseFloat(range) <= parseFloat(limit)
            } else {
              if (measure === "below") {
                return parseFloat(range) > parseFloat(limit)
              } else return parseFloat(range) < parseFloat(limit)
            }
          }
          const validateRange = (item) => {
            let isValid = true
            let lt_exists = String(item?.range).startsWith("<")
            let gt_exists = String(item?.range).startsWith(">")
            let range_trim =
              lt_exists || gt_exists
                ? String(item?.range).slice(1)
                : item?.range
            if (item?.aboveTclpLimit === 1) {
              isValid = !validateRangeSymbols(
                lt_exists,
                gt_exists,
                range_trim,
                item?.tclpRegLimit,
                "above"
              )
            }
            if (item?.belowTclpLimit === 1) {
              isValid = !validateRangeSymbols(
                lt_exists,
                gt_exists,
                range_trim,
                item?.tclpRegLimit,
                "below"
              )
            }
            return isValid
          }
          const getSelectAllChecked = (data) => {
            let leng = data?.length
            let countTrue = 0
            //eslint-disable-next-line
            for (const item in data) {
              if (data[item]?.notPresent === 1) countTrue += 1
            }
            return leng === countTrue
          }
          return (
            <Form>
              <div
                className="ml-4 regulated-consti"
                style={{
                  pointerEvents: type === "viewprofile" ? "none" : "auto",
                }}
              >
                {/* Header */}
                <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 addi-info generatorstatus text-left py-4">
                  <div className="m-0 labelgenerator myprofile">
                    <FormattedMessage id="profile.regulatedconst.title" />
                  </div>
                  <div className="form-input-block col-sm-4">
                    <span className="label">
                      <FormattedMessage id="profile.generator" />
                    </span>
                    <Input
                      value={
                        getDefaultGeneratorName(currentProfile?.generatorId) ||
                        genNameforView
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-b-2 position-relative">
                  <div className="col-sm-12">
                    <div
                      className="d-flex form-input-block"
                      style={{ alignContent: "baseline" }}
                    >
                      <div className="my-profile fs-14">
                        <FormattedMessage id="profile.regulatedconst.info" />
                        <span style={{ color: "red" }}>{" *"}</span>
                      </div>
                      <div className="d-flex py-1">
                        <label className="check-container">
                          <input
                            name={"analytical"}
                            type="checkbox"
                            checked={infoSupplied?.analytical}
                            onChange={(e) => {
                              setInfoSupplied({
                                ...infoSupplied,
                                analytical: e.target.checked,
                              })
                              // setFieldValue("Analytical", e.target.checked)
                            }}
                          />
                          <span className={"checkmark"} />
                        </label>
                        <span
                          className="px-4 text-description"
                          style={{ marginLeft: "16PX" }}
                        >
                          <FormattedMessage
                            id={"profile.regulatedconst.analytical"}
                          />
                        </span>
                      </div>
                      <div className="d-flex py-1">
                        <label className="check-container">
                          <input
                            name={"generatorKnowledge"}
                            type="checkbox"
                            checked={infoSupplied?.generatorKnowledge}
                            onChange={(e) => {
                              setInfoSupplied({
                                ...infoSupplied,
                                generatorKnowledge: e.target.checked,
                              })
                              // setFieldValue(
                              //   "GeneratorKnowledge",
                              //   e.target.checked
                              // )
                            }}
                          />
                          <span className={"checkmark"} />
                        </label>
                        <span
                          className="px-4 text-description"
                          style={{ marginLeft: "16PX" }}
                        >
                          <FormattedMessage
                            id={"profile.regulatedconst.genkldg"}
                          />
                        </span>
                      </div>
                      <div className="d-flex py-1">
                        <label className="check-container">
                          <input
                            name={"sds"}
                            type="checkbox"
                            checked={infoSupplied?.sds}
                            onChange={(e) => {
                              setInfoSupplied({
                                ...infoSupplied,
                                sds: e.target.checked,
                              })
                              // setFieldValue("SDS", e.target.checked)
                            }}
                          />
                          <span className={"checkmark"} />
                        </label>
                        <span
                          className="px-4 text-description"
                          style={{ marginLeft: "16PX" }}
                        >
                          <FormattedMessage id={"profile.regulatedconst.sds"} />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={"text-center"}
                    style={{
                      color: "red",
                      fontSize: 10,
                      position: "relative",
                      left: -24,
                    }}
                  >
                    {(submit &&
                      !infoSupplied?.analytical &&
                      !infoSupplied?.generatorKnowledge &&
                      !infoSupplied?.sds) ||
                    (error_highlight?.["6"]?.errorFlag &&
                      !infoSupplied?.analytical &&
                      !infoSupplied?.generatorKnowledge &&
                      !infoSupplied?.sds) ? (
                      <div>Please check one or more options</div>
                    ) : null}
                  </div>
                </div>
                {/* Metric Tables */}
                <div className="jstf-sb rc-parent">
                  <div
                    className="col-sm-12 col-md-12 col-lg-12 mx-3 p-0 table sticky isshipreport"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="row m-0 headers">
                      <div className={`tr active`}>
                        <div
                          className={`th customsort inactive title-container display-ib width235`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.metal`}
                          />
                          <span style={{ color: "red" }}>{" *"}</span>
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                          style={{ lineHeight: "20px" }}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.regtclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                          style={{ lineHeight: "20px" }}
                        >
                          <div className="d-flex py-1">
                            <label className="check-container">
                              <input
                                name={"notPresentSelectAllMetals"}
                                type="checkbox"
                                checked={
                                  getSelectAllChecked(metals) ||
                                  selectAllMetalsFlag
                                }
                                onChange={(e) => {
                                  setSelectedAllMetalsFlag(e.target.checked)
                                  handleSelectAll(metals, e.target.checked)
                                }}
                              />
                              <span
                                className={"checkmark"}
                                style={{ zIndex: 1 }}
                              />
                              <span></span>
                            </label>
                            <span className="px-4 text-description rc-checkbox">
                              <FormattedMessage
                                id={
                                  "profile.regulatedconst.notPresentSelectAll"
                                }
                              />
                            </span>
                          </div>
                          <div
                            className="not-present"
                            style={{ lineHeight: "10px" }}
                          >
                            <FormattedMessage
                              id={`profile.regulatedconst.notpresent`}
                            />
                          </div>
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.belowtclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.abovetclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width150`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.range`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width150`}
                        >
                          <FormattedMessage id={`profile.regulatedconst.uom`} />
                        </div>
                      </div>
                    </div>
                    {(submit && checkOneOf(metals)) ||
                    (error_highlight?.["6"]?.errorFlag &&
                      checkOneOf(metals)) ? (
                      <div
                        className={"tr active"}
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        Atleast one should be filled completely
                      </div>
                    ) : null}
                    <div
                      className="row m-0 body"
                      // style={{ height: "180px", overflowY: "scroll" }}
                    >
                      {metals?.map((dataItem, ind) => {
                        return (
                          <div
                            className="tr active"
                            key={ind}
                            style={{ display: "flex", minHeight: 32 }}
                          >
                            <div className="td custom width235 display-ib">
                              {dataItem?.name}
                            </div>
                            <div className="td custom width100 display-ib">
                              {dataItem?.tclpRegLimit}
                            </div>
                            <div className="td custom width100 display-ib">
                              <div
                                className="form-input-block mr-2"
                                style={{ marginTop: -15 }}
                              >
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name={`availability-metals-${ind}`}
                                  value={dataItem?.notPresent || false}
                                  checked={
                                    selectAllMetalsFlag ||
                                    (dataItem?.notPresent ? true : false)
                                  }
                                  onChange={(e) => {
                                    const list = [...metals]
                                    list[ind]["notPresent"] = e.target.checked
                                      ? 1
                                      : 0
                                    list[ind]["aboveTclpLimit"] = 0
                                    list[ind]["belowTclpLimit"] = 0
                                    list[ind].unitOfMeasure = {
                                      l: "None",
                                      v: "0",
                                    }
                                    list[ind]["range"] = ""
                                    setMetals([...list])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="td custom width100 display-ib">
                              <div
                                className="form-input-block mr-2"
                                style={{ marginTop: -15 }}
                              >
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  checked={dataItem?.belowTclpLimit}
                                  name={`availability-metals-${ind}`}
                                  // disabled={
                                  //   selectAllMetalsFlag ? "disabled" : ""
                                  // }
                                  onChange={(e) => {
                                    const list = [...metals]
                                    list[ind]["belowTclpLimit"] = e.target
                                      .checked
                                      ? 1
                                      : 0
                                    list[ind]["notPresent"] = 0
                                    list[ind]["aboveTclpLimit"] = 0
                                    list[ind].unitOfMeasure = {
                                      l: "TCLP(MG/L)",
                                      v: "2",
                                    }
                                    list[ind]["range"] = ""
                                    if (
                                      selectAllMetalsFlag &&
                                      e.target.checked
                                    ) {
                                      setSelectedAllMetalsFlag(false)
                                    }
                                    setMetals([...list])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="td custom width100 display-ib">
                              <div
                                className="form-input-block mr-2"
                                style={{ marginTop: -15 }}
                              >
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name={`availability-metals-${ind}`}
                                  // disabled={
                                  //   selectAllMetalsFlag ? "disabled" : ""
                                  // }
                                  checked={dataItem?.aboveTclpLimit}
                                  onChange={(e) => {
                                    const list = [...metals]
                                    list[ind]["aboveTclpLimit"] = e.target
                                      .checked
                                      ? 1
                                      : 0
                                    list[ind]["notPresent"] = 0
                                    list[ind]["belowTclpLimit"] = 0
                                    list[ind].unitOfMeasure = {
                                      l: "TCLP(MG/L)",
                                      v: "2",
                                    }
                                    list[ind]["range"] = ""
                                    if (
                                      selectAllMetalsFlag &&
                                      e.target.checked
                                    ) {
                                      setSelectedAllMetalsFlag(false)
                                    }
                                    setMetals([...list])
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="td custom width150 display-ib"
                              style={{ flexDirection: "column" }}
                            >
                              <div
                                className="form-input-block "
                                style={{
                                  width: 135,
                                  marginTop: 15,
                                  height: "40px",
                                }}
                              >
                                <Input
                                  // placeholder=">260"
                                  name="range"
                                  disabled={
                                    type === "viewprofile" ||
                                    dataItem?.notPresent ||
                                    selectAllMetalsFlag
                                  }
                                  clsName={
                                    submit &&
                                    (dataItem?.tclpRegLimit?.length > 0 &&
                                    dataItem?.notPresent === 0 &&
                                    (dataItem?.aboveTclpLimit !== 0 ||
                                      dataItem?.belowTclpLimit !== 0)
                                      ? dataItem?.range?.length === 0
                                        ? "input-border-red"
                                        : validateRange(dataItem) === false
                                        ? "input-border-red"
                                        : ""
                                      : "")
                                  }
                                  value={
                                    dataItem?.notPresent ? "" : dataItem?.range
                                  }
                                  onFieldChange={(e) => {
                                    const list = [...metals]
                                    list[ind]["range"] = e.target.value
                                    setMetals([...list])
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 10,
                                  // marginTop: "-16px",
                                  height: "16px",
                                  marginTop: 0,
                                }}
                              >
                                {submit &&
                                  (dataItem?.tclpRegLimit?.length > 0 &&
                                  dataItem?.notPresent === 0 &&
                                  (dataItem?.aboveTclpLimit !== 0 ||
                                    dataItem?.belowTclpLimit !== 0) ? (
                                    dataItem?.range?.length === 0 ? (
                                      <div> Range is required </div>
                                    ) : validateRange(dataItem) === false ? (
                                      <div>
                                        Range should be in selected limit
                                      </div>
                                    ) : null
                                  ) : null)}
                              </div>
                            </div>
                            <div
                              className="td custom width150 display-ib"
                              style={{
                                flexDirection: "column",
                                overflow: "visible",
                              }}
                            >
                              <div
                                className="form-input-block mb-dropdown ml-4 mni-style width135 reg-const"
                                style={{
                                  // marginTop: "20px",
                                  // marginBottom: "48px",
                                  marginTop: 15,
                                  height: "40px",
                                }}
                              >
                                <Select
                                  name="unitOfMeasure"
                                  labelkey={"l"}
                                  label=""
                                  className={`sort-dropdown ${
                                    dataItem?.unitOfMeasure?.v == 0
                                      ? "mni-selected-dropdown"
                                      : ""
                                  }`}
                                  options={uomOpts?.options}
                                  selected={
                                    dataItem?.notPresent
                                      ? { l: "None", v: "0" }
                                      : dataItem?.unitOfMeasure
                                  }
                                  // selected={typeof dataItem?.unitOfMeasure === 'string' ? {l : dataItem?.unitOfMeasure, v: dataItem?.unitOfMeasure} : dataItem?.unitOfMeasure}
                                  disabled={
                                    type === "viewprofile" ||
                                    dataItem?.notPresent ||
                                    selectAllMetalsFlag
                                  }
                                  onSelect={(e) => {
                                    const list = [...metals]
                                    list[ind].unitOfMeasure = e
                                    setMetals([...list])
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 10,
                                  // paddingTop: "8px",
                                  height: "16px",
                                  marginTop: -31,
                                }}
                              >
                                {submit &&
                                  (dataItem?.tclpRegLimit?.length > 0 &&
                                  dataItem?.notPresent === 0 &&
                                  !selectAllMetalsFlag &&
                                  (dataItem?.aboveTclpLimit !== 0 ||
                                    dataItem?.belowTclpLimit !== 0) ? (
                                    dataItem?.unitOfMeasure?.v === "0" ? (
                                      <div> UoM is required </div>
                                    ) : null
                                  ) : null)}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-12 col-lg-12 mx-3 p-0 table sticky isshipreport"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="row m-0 headers">
                      <div className={`tr active`}>
                        <div
                          className={`th customsort inactive title-container display-ib width235`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.volatilecompounds`}
                          />
                          <span style={{ color: "red" }}>{" *"}</span>
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                          style={{ lineHeight: "20px" }}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.regtclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                          style={{ lineHeight: "20px" }}
                        >
                          <div className="d-flex py-1">
                            <label className="check-container">
                              <input
                                name={"notPresentSelectAllVolatiles"}
                                type="checkbox"
                                checked={
                                  getSelectAllChecked(volatileCompounds) ||
                                  selectAllVolatilesFlag
                                }
                                onChange={(e) => {
                                  setSelectedAllVolatilesFlag(e.target.checked)
                                  handleSelectAll(
                                    volatileCompounds,
                                    e.target.checked
                                  )
                                }}
                              />
                              <span
                                className={"checkmark"}
                                style={{ zIndex: 1 }}
                              />
                              <span></span>
                            </label>
                            <span className="px-4 text-description rc-checkbox">
                              <FormattedMessage
                                id={
                                  "profile.regulatedconst.notPresentSelectAll"
                                }
                              />
                            </span>
                          </div>
                          <div
                            className="not-present"
                            style={{ lineHeight: "10px" }}
                          >
                            <FormattedMessage
                              id={`profile.regulatedconst.notpresent`}
                            />
                          </div>
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.belowtclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.abovetclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width150`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.range`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width150`}
                        >
                          <FormattedMessage id={`profile.regulatedconst.uom`} />
                        </div>
                      </div>
                    </div>
                    {(submit && checkOneOf(volatileCompounds)) ||
                    (error_highlight?.["6"]?.errorFlag &&
                      checkOneOf(volatileCompounds)) ? (
                      <div
                        className={"tr active"}
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        Atleast one should be filled completely
                      </div>
                    ) : null}
                    <div className="row m-0 body">
                      {volatileCompounds?.map((dataItem, ind) => (
                        <div
                          className="tr active"
                          key={ind}
                          style={{ display: "flex", minHeight: 32 }}
                        >
                          <div className="td custom width235 display-ib">
                            {dataItem?.name}
                          </div>
                          <div className="td custom width100 display-ib">
                            {dataItem?.tclpRegLimit}
                          </div>
                          <div className="td custom width100 display-ib">
                            <div
                              className="form-input-block mr-2"
                              style={{ marginTop: -15 }}
                            >
                              <input
                                id="radioCustomColor"
                                type="radio"
                                name={`availability-vc-${ind}`}
                                value={dataItem?.notPresent || false}
                                checked={
                                  selectAllVolatilesFlag ||
                                  (dataItem?.notPresent ? true : false)
                                }
                                onChange={(e) => {
                                  const list = [...volatileCompounds]
                                  list[ind]["notPresent"] = e.target.checked
                                    ? 1
                                    : 0
                                  list[ind]["aboveTclpLimit"] = 0
                                  list[ind]["belowTclpLimit"] = 0
                                  list[ind].unitOfMeasure = {
                                    l: "None",
                                    v: "0",
                                  }
                                  list[ind]["range"] = ""
                                  setVolatileCompounds([...list])
                                }}
                              />
                            </div>
                          </div>
                          <div className="td custom width100 display-ib">
                            <div
                              className="form-input-block mr-2"
                              style={{ marginTop: -15 }}
                            >
                              <input
                                id="radioCustomColor"
                                type="radio"
                                value={dataItem?.belowTclpLimit}
                                checked={dataItem?.belowTclpLimit}
                                name={`availability-vc-${ind}`}
                                // disabled={
                                //   selectAllVolatilesFlag ? "disabled" : ""
                                // }
                                onChange={(e) => {
                                  const list = [...volatileCompounds]
                                  list[ind]["belowTclpLimit"] = e.target.checked
                                    ? 1
                                    : 0
                                  list[ind]["notPresent"] = 0
                                  list[ind]["aboveTclpLimit"] = 0
                                  list[ind].unitOfMeasure = {
                                    l: "TCLP(MG/L)",
                                    v: "2",
                                  }
                                  list[ind]["range"] = ""
                                  if (
                                    selectAllVolatilesFlag &&
                                    e.target.checked
                                  ) {
                                    setSelectedAllVolatilesFlag(false)
                                  }
                                  setVolatileCompounds([...list])
                                }}
                              />
                            </div>
                          </div>
                          <div className="td custom width100 display-ib">
                            <div
                              className="form-input-block mr-2"
                              style={{ marginTop: -15 }}
                            >
                              <input
                                id="radioCustomColor"
                                type="radio"
                                name={`availability-vc-${ind}`}
                                // disabled={
                                //   selectAllVolatilesFlag ? "disabled" : ""
                                // }
                                checked={dataItem?.aboveTclpLimit}
                                value={dataItem?.aboveTclpLimit}
                                onChange={(e) => {
                                  const list = [...volatileCompounds]
                                  list[ind]["aboveTclpLimit"] = e.target.checked
                                    ? 1
                                    : 0
                                  list[ind]["notPresent"] = 0
                                  list[ind]["belowTclpLimit"] = 0
                                  list[ind].unitOfMeasure = {
                                    l: "TCLP(MG/L)",
                                    v: "2",
                                  }
                                  list[ind]["range"] = ""
                                  if (
                                    selectAllVolatilesFlag &&
                                    e.target.checked
                                  ) {
                                    setSelectedAllVolatilesFlag(false)
                                  }
                                  setVolatileCompounds([...list])
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="td custom width150 display-ib"
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              className="form-input-block "
                              style={{
                                width: 135,
                                marginTop: 15,
                                height: "40px",
                              }}
                            >
                              <Input
                                // placeholder=">260"
                                name="range"
                                disabled={
                                  type === "viewprofile" ||
                                  dataItem?.notPresent ||
                                  selectAllVolatilesFlag
                                }
                                clsName={
                                  submit &&
                                  (dataItem?.tclpRegLimit?.length > 0 &&
                                  dataItem?.notPresent === 0 &&
                                  (dataItem?.aboveTclpLimit !== 0 ||
                                    dataItem?.belowTclpLimit !== 0)
                                    ? dataItem?.range?.length === 0
                                      ? "input-border-red"
                                      : validateRange(dataItem) === false
                                      ? "input-border-red"
                                      : ""
                                    : "")
                                }
                                value={
                                  dataItem?.notPresent ? "" : dataItem.range
                                }
                                onFieldChange={(e) => {
                                  const list = [...volatileCompounds]
                                  list[ind]["range"] = e.target.value
                                  setVolatileCompounds([...list])
                                }}
                              />
                            </div>
                            <div
                              style={{
                                color: "red",
                                fontSize: 10,
                                // marginTop: "-16px",
                                height: "16px",
                                marginTop: 0,
                              }}
                            >
                              {submit &&
                                (dataItem?.tclpRegLimit?.length > 0 &&
                                dataItem?.notPresent === 0 &&
                                !selectAllVolatilesFlag &&
                                (dataItem?.aboveTclpLimit !== 0 ||
                                  dataItem?.belowTclpLimit !== 0) ? (
                                  dataItem?.range?.length === 0 ? (
                                    <div> Range is required </div>
                                  ) : validateRange(dataItem) === false ? (
                                    <div>Range should be in selected limit</div>
                                  ) : null
                                ) : null)}
                            </div>
                          </div>
                          <div
                            className="td custom width150 display-ib"
                            style={{
                              flexDirection: "column",
                              overflow: "visible",
                            }}
                          >
                            <div
                              className="form-input-block mb-dropdown ml-4 mni-style width135  reg-const"
                              style={{
                                // marginTop: "20px",
                                // marginBottom: "48px",
                                marginTop: 15,
                                height: "40px",
                              }}
                            >
                              <Select
                                name="unitOfMeasure"
                                label=""
                                labelkey={"l"}
                                className={`sort-dropdown ${
                                  dataItem?.unitOfMeasure?.v == 0
                                    ? "mni-selected-dropdown"
                                    : ""
                                }`}
                                options={uomOpts?.options}
                                selected={
                                  dataItem?.notPresent
                                    ? { l: "None", v: "0" }
                                    : dataItem?.unitOfMeasure
                                }
                                // selected={typeof dataItem?.unitOfMeasure === 'string' ? {l : dataItem?.unitOfMeasure, v: dataItem?.unitOfMeasure} : dataItem?.unitOfMeasure}
                                disabled={
                                  type === "viewprofile" || dataItem?.notPresent
                                }
                                onSelect={(e) => {
                                  const list = [...volatileCompounds]
                                  list[ind].unitOfMeasure = e
                                  setVolatileCompounds([...list])
                                }}
                              />
                            </div>
                            <div
                              style={{
                                color: "red",
                                fontSize: 10,
                                // paddingTop: "8px",
                                height: "16px",
                                marginTop: -31,
                              }}
                            >
                              {submit &&
                              dataItem?.tclpRegLimit?.length > 0 &&
                              dataItem?.notPresent === 0 &&
                              !selectAllVolatilesFlag &&
                              (dataItem?.aboveTclpLimit !== 0 ||
                                dataItem?.belowTclpLimit !== 0) ? (
                                dataItem?.unitOfMeasure?.v === "0" ? (
                                  <div>UoM is required</div>
                                ) : null
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-12 col-lg-12 mx-3 p-0 table sticky isshipreport"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="row m-0 headers">
                      <div className={`tr active`}>
                        <div
                          className={`th customsort inactive title-container display-ib width235`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.semivolatiles`}
                          />
                          <span style={{ color: "red" }}>{" *"}</span>
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                          style={{ lineHeight: "20px" }}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.regtclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                          style={{ lineHeight: "20px" }}
                        >
                          <div className="d-flex py-1">
                            <label className="check-container">
                              <input
                                name={"notPresentSelectAllSemi"}
                                type="checkbox"
                                checked={
                                  getSelectAllChecked(semiVolatiles) ||
                                  selectAllSemiVolatilesFlag
                                }
                                onChange={(e) => {
                                  setSelectedAllSemiVolatilesFlag(
                                    e.target.checked
                                  )
                                  handleSelectAll(
                                    semiVolatiles,
                                    e.target.checked
                                  )
                                }}
                              />
                              <span
                                className={"checkmark"}
                                style={{ zIndex: 1 }}
                              />
                              <span></span>
                            </label>
                            <span className="px-4 text-description rc-checkbox">
                              <FormattedMessage
                                id={
                                  "profile.regulatedconst.notPresentSelectAll"
                                }
                              />
                            </span>
                          </div>
                          <div
                            className="not-present"
                            style={{ lineHeight: "10px" }}
                          >
                            <FormattedMessage
                              id={`profile.regulatedconst.notpresent`}
                            />
                          </div>
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.belowtclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.abovetclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width150`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.range`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width150`}
                        >
                          <FormattedMessage id={`profile.regulatedconst.uom`} />
                        </div>
                      </div>
                    </div>
                    {(submit && checkOneOf(semiVolatiles)) ||
                    (error_highlight?.["6"]?.errorFlag &&
                      checkOneOf(semiVolatiles)) ? (
                      <div
                        className={"tr active"}
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        Atleast one should be filled completely
                      </div>
                    ) : null}
                    <div className="row m-0 body">
                      {semiVolatiles?.map((dataItem, ind) => (
                        <div
                          className="tr active"
                          key={ind}
                          style={{ display: "flex", minHeight: 32 }}
                        >
                          <div className="td custom width235 display-ib">
                            {dataItem?.name}
                          </div>
                          <div className="td custom width100 display-ib">
                            {dataItem?.tclpRegLimit}
                          </div>
                          <div className="td custom width100 display-ib">
                            <div
                              className="form-input-block mr-2"
                              style={{ marginTop: -15 }}
                            >
                              <input
                                id="radioCustomColor"
                                type="radio"
                                name={`availability-svc-${ind}`}
                                value={dataItem?.notPresent || false}
                                checked={
                                  selectAllSemiVolatilesFlag ||
                                  (dataItem?.notPresent ? true : false)
                                }
                                onChange={(e) => {
                                  const list = [...semiVolatiles]
                                  list[ind]["notPresent"] = e.target.checked
                                    ? 1
                                    : 0
                                  list[ind]["aboveTclpLimit"] = 0
                                  list[ind]["belowTclpLimit"] = 0
                                  list[ind].unitOfMeasure = {
                                    l: "None",
                                    v: "0",
                                  }
                                  list[ind]["range"] = ""
                                  setSemiVolatiles([...list])
                                }}
                              />
                            </div>
                          </div>
                          <div className="td custom width100 display-ib">
                            <div
                              className="form-input-block mr-2"
                              style={{ marginTop: -15 }}
                            >
                              <input
                                id="radioCustomColor"
                                type="radio"
                                name={`availability-svc-${ind}`}
                                value={dataItem?.belowTclpLimit}
                                checked={dataItem?.belowTclpLimit}
                                // disabled={
                                //   selectAllSemiVolatilesFlag ? "disabled" : ""
                                // }
                                onChange={(e) => {
                                  const list = [...semiVolatiles]
                                  list[ind]["belowTclpLimit"] = e.target.checked
                                    ? 1
                                    : 0
                                  list[ind]["notPresent"] = 0
                                  list[ind]["aboveTclpLimit"] = 0
                                  list[ind].unitOfMeasure = {
                                    l: "TCLP(MG/L)",
                                    v: "2",
                                  }
                                  list[ind]["range"] = ""
                                  if (
                                    selectAllSemiVolatilesFlag &&
                                    e.target.checked
                                  ) {
                                    setSelectedAllSemiVolatilesFlag(false)
                                  }
                                  setSemiVolatiles([...list])
                                }}
                              />
                            </div>
                          </div>
                          <div className="td custom width100 display-ib">
                            <div
                              className="form-input-block mr-2"
                              style={{ marginTop: -15 }}
                            >
                              <input
                                id="radioCustomColor"
                                type="radio"
                                name={`availability-svc-${ind}`}
                                value={dataItem?.aboveTclpLimit}
                                // disabled={
                                //   selectAllSemiVolatilesFlag ? "disabled" : ""
                                // }
                                checked={dataItem?.aboveTclpLimit}
                                onChange={(e) => {
                                  const list = [...semiVolatiles]
                                  list[ind]["aboveTclpLimit"] = e.target.checked
                                    ? 1
                                    : 0
                                  list[ind]["notPresent"] = 0
                                  list[ind]["belowTclpLimit"] = 0
                                  list[ind].unitOfMeasure = {
                                    l: "TCLP(MG/L)",
                                    v: "2",
                                  }
                                  list[ind]["range"] = ""
                                  if (
                                    selectAllSemiVolatilesFlag &&
                                    e.target.checked
                                  ) {
                                    setSelectedAllSemiVolatilesFlag(false)
                                  }
                                  setSemiVolatiles([...list])
                                }}
                              />
                            </div>
                          </div>
                          <div
                            className="td custom width150 display-ib"
                            style={{ flexDirection: "column" }}
                          >
                            <div
                              className="form-input-block mr-2"
                              style={{
                                width: 135,
                                marginTop: 15,
                                height: "40px",
                              }}
                            >
                              <Input
                                // placeholder=">260"
                                name="range"
                                disabled={
                                  type === "viewprofile" ||
                                  dataItem?.notPresent ||
                                  selectAllSemiVolatilesFlag
                                }
                                clsName={
                                  submit &&
                                  (dataItem?.tclpRegLimit?.length > 0 &&
                                  dataItem?.notPresent === 0 &&
                                  (dataItem?.aboveTclpLimit !== 0 ||
                                    dataItem?.belowTclpLimit !== 0)
                                    ? dataItem?.range?.length === 0
                                      ? "input-border-red"
                                      : validateRange(dataItem) === false
                                      ? "input-border-red"
                                      : ""
                                    : "")
                                }
                                value={
                                  dataItem?.notPresent ? "" : dataItem.range
                                }
                                onFieldChange={(e) => {
                                  const list = [...semiVolatiles]
                                  list[ind]["range"] = e.target.value
                                  setSemiVolatiles([...list])
                                }}
                              />
                            </div>
                            <div
                              style={{
                                color: "red",
                                fontSize: 10,
                                // marginTop: "-16px",
                                height: "16px",
                                marginTop: 0,
                              }}
                            >
                              {submit &&
                                (dataItem?.tclpRegLimit?.length > 0 &&
                                dataItem?.notPresent === 0 &&
                                !selectAllSemiVolatilesFlag &&
                                (dataItem?.aboveTclpLimit !== 0 ||
                                  dataItem?.belowTclpLimit !== 0) ? (
                                  dataItem?.range?.length === 0 ? (
                                    <div> Range is required </div>
                                  ) : validateRange(dataItem) === false ? (
                                    <div>Range should be in limit</div>
                                  ) : null
                                ) : null)}
                            </div>
                          </div>
                          <div
                            className="td custom width150 display-ib"
                            style={{
                              flexDirection: "column",
                              overflow: "visible",
                            }}
                          >
                            <div
                              className="form-input-block mb-dropdown ml-4 mni-style width135 reg-const"
                              style={{
                                // marginTop: "20px",
                                // marginBottom: "48px",
                                marginTop: 15,
                                height: "40px",
                              }}
                            >
                              <Select
                                name="unitOfMeasure"
                                labelkey={"l"}
                                label=""
                                className={`sort-dropdown ${
                                  dataItem?.unitOfMeasure?.v == 0
                                    ? "mni-selected-dropdown"
                                    : ""
                                }`}
                                options={uomOpts?.options}
                                selected={
                                  dataItem?.notPresent
                                    ? { l: "None", v: "0" }
                                    : dataItem?.unitOfMeasure
                                }
                                // selected={typeof dataItem?.unitOfMeasure === 'string' ? {l : dataItem?.unitOfMeasure, v: dataItem?.unitOfMeasure} : dataItem?.unitOfMeasure}
                                disabled={
                                  type === "viewprofile" || dataItem?.notPresent
                                }
                                onSelect={(e) => {
                                  const list = [...semiVolatiles]
                                  list[ind].unitOfMeasure = e
                                  setSemiVolatiles([...list])
                                }}
                              />
                            </div>
                            <div
                              style={{
                                color: "red",
                                fontSize: 10,
                                // paddingTop: "8px",
                                height: "16px",
                                marginTop: -31,
                              }}
                            >
                              {dataItem?.tclpRegLimit?.length > 0 &&
                              dataItem?.notPresent === 0 &&
                              !selectAllSemiVolatilesFlag &&
                              (dataItem?.aboveTclpLimit !== 0 ||
                                dataItem?.belowTclpLimit !== 0) ? (
                                dataItem?.unitOfMeasure?.v === "0" ? (
                                  <div>UoM is required</div>
                                ) : null
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-12 col-lg-12 mx-3 p-0 table sticky isshipreport"
                    style={{ overflow: "hidden" }}
                  >
                    <div className="row m-0 headers">
                      <div className={`tr active`}>
                        <div
                          className={`th customsort inactive title-container display-ib width235`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.pesticidesherbicides`}
                          />
                          <span style={{ color: "red" }}>{" *"}</span>
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                          style={{ lineHeight: "20px" }}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.regtclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                          style={{ lineHeight: "20px" }}
                        >
                          <div className="d-flex py-1">
                            <label className="check-container">
                              <input
                                name={"notPresentSelectAllPest"}
                                type="checkbox"
                                checked={
                                  getSelectAllChecked(pesticidesHerbicides) ||
                                  selectAllPestHerbFlag
                                }
                                onChange={(e) => {
                                  setSelectedAllPestHerbFlag(e.target.checked)
                                  handleSelectAll(
                                    pesticidesHerbicides,
                                    e.target.checked
                                  )
                                }}
                              />
                              <span
                                className={"checkmark"}
                                style={{ zIndex: 1 }}
                              />
                              <span></span>
                            </label>
                            <span className="px-4 text-description rc-checkbox">
                              <FormattedMessage
                                id={
                                  "profile.regulatedconst.notPresentSelectAll"
                                }
                              />
                            </span>
                          </div>
                          <div
                            className="not-present"
                            style={{ lineHeight: "10px" }}
                          >
                            <FormattedMessage
                              id={`profile.regulatedconst.notpresent`}
                            />
                          </div>
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.belowtclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width100`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.abovetclp`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width150`}
                        >
                          <FormattedMessage
                            id={`profile.regulatedconst.range`}
                          />
                        </div>
                        <div
                          className={`th customsort inactive title-container display-ib width150`}
                        >
                          <FormattedMessage id={`profile.regulatedconst.uom`} />
                        </div>
                      </div>
                    </div>
                    {(submit && checkOneOf(pesticidesHerbicides)) ||
                    (error_highlight?.["6"]?.errorFlag &&
                      checkOneOf(pesticidesHerbicides)) ? (
                      <div
                        className={"tr active"}
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        Atleast one should be filled completely
                      </div>
                    ) : null}
                    <div className="row m-0 body">
                      {pesticidesHerbicides?.map((dataItem, ind) => {
                        return (
                          <div
                            className="tr active"
                            key={ind}
                            style={{ display: "flex", minHeight: 32 }}
                          >
                            <div className="td custom width235 display-ib">
                              {dataItem?.name}
                            </div>
                            <div className="td custom width100 display-ib">
                              {dataItem?.tclpRegLimit}
                            </div>
                            <div className="td custom width100 display-ib">
                              <div
                                className="form-input-block mr-2"
                                style={{ marginTop: -15 }}
                              >
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name={`availability-ph-${ind}`}
                                  value={dataItem?.notPresent || false}
                                  checked={
                                    selectAllPestHerbFlag ||
                                    (dataItem?.notPresent ? true : false)
                                  }
                                  onChange={(e) => {
                                    const list = [...pesticidesHerbicides]
                                    list[ind]["notPresent"] = e.target.checked
                                      ? 1
                                      : 0
                                    list[ind]["aboveTclpLimit"] = 0
                                    list[ind]["belowTclpLimit"] = 0
                                    list[ind].unitOfMeasure = {
                                      l: "None",
                                      v: "0",
                                    }
                                    list[ind]["range"] = ""
                                    setPesticidesHerbicides([...list])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="td custom width100 display-ib">
                              <div
                                className="form-input-block mr-2"
                                style={{ marginTop: -15 }}
                              >
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name={`availability-ph-${ind}`}
                                  value={dataItem?.belowTclpLimit}
                                  checked={dataItem?.belowTclpLimit}
                                  // disabled={
                                  //   selectAllPestHerbFlag ? "disabled" : ""
                                  // }
                                  onChange={(e) => {
                                    const list = [...pesticidesHerbicides]
                                    list[ind]["belowTclpLimit"] = e.target
                                      .checked
                                      ? 1
                                      : 0
                                    list[ind]["notPresent"] = 0
                                    list[ind]["aboveTclpLimit"] = 0
                                    list[ind].unitOfMeasure = {
                                      l: "TCLP(MG/L)",
                                      v: "2",
                                    }
                                    list[ind]["range"] = ""
                                    if (
                                      selectAllPestHerbFlag &&
                                      e.target.checked
                                    ) {
                                      setSelectedAllPestHerbFlag(false)
                                    }
                                    setPesticidesHerbicides([...list])
                                  }}
                                />
                              </div>
                            </div>
                            <div className="td custom width100 display-ib">
                              <div
                                className="form-input-block mr-2"
                                style={{ marginTop: -15 }}
                              >
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  value={dataItem.aboveTclpLimit}
                                  checked={dataItem?.aboveTclpLimit}
                                  name={`availability-ph-${ind}`}
                                  // disabled={
                                  //   selectAllPestHerbFlag ? "disabled" : ""
                                  // }
                                  onChange={(e) => {
                                    const list = [...pesticidesHerbicides]
                                    list[ind]["aboveTclpLimit"] = e.target
                                      .checked
                                      ? 1
                                      : 0
                                    list[ind]["notPresent"] = 0
                                    list[ind]["belowTclpLimit"] = 0
                                    list[ind].unitOfMeasure = {
                                      l: "TCLP(MG/L)",
                                      v: "2",
                                    }
                                    list[ind]["range"] = ""
                                    if (
                                      selectAllPestHerbFlag &&
                                      e.target.checked
                                    ) {
                                      setSelectedAllPestHerbFlag(false)
                                    }
                                    setPesticidesHerbicides([...list])
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="td custom width150 display-ib"
                              style={{ flexDirection: "column" }}
                            >
                              <div
                                className="form-input-block mr-2"
                                style={{
                                  width: 135,
                                  marginTop: 15,
                                  height: "40px",
                                }}
                              >
                                <Input
                                  // placeholder=">260"
                                  name="range"
                                  disabled={
                                    type === "viewprofile" ||
                                    dataItem?.notPresent ||
                                    selectAllPestHerbFlag
                                  }
                                  clsName={
                                    submit &&
                                    (dataItem?.tclpRegLimit?.length > 0 &&
                                    dataItem?.notPresent === 0 &&
                                    (dataItem?.aboveTclpLimit !== 0 ||
                                      dataItem?.belowTclpLimit !== 0)
                                      ? dataItem?.range?.length === 0
                                        ? "input-border-red"
                                        : validateRange(dataItem) === false
                                        ? "input-border-red"
                                        : ""
                                      : "")
                                  }
                                  value={
                                    dataItem?.notPresent ? "" : dataItem.range
                                  }
                                  onFieldChange={(e) => {
                                    const list = [...pesticidesHerbicides]
                                    list[ind]["range"] = e.target.value
                                    setPesticidesHerbicides([...list])
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 10,
                                  // marginTop: "-16px",
                                  height: "16px",
                                  marginTop: 0,
                                }}
                              >
                                {submit &&
                                  (dataItem?.tclpRegLimit?.length > 0 &&
                                  dataItem?.notPresent === 0 &&
                                  !selectAllPestHerbFlag &&
                                  (dataItem?.aboveTclpLimit !== 0 ||
                                    dataItem?.belowTclpLimit !== 0) ? (
                                    dataItem?.range?.length === 0 ? (
                                      <div> Range is required </div>
                                    ) : validateRange(dataItem) === false ? (
                                      <div>Range should be in limit</div>
                                    ) : null
                                  ) : null)}
                              </div>
                            </div>
                            <div
                              className="td custom width150 display-ib"
                              style={{
                                flexDirection: "column",
                                overflow: "visible",
                              }}
                            >
                              <div
                                className="form-input-block mb-dropdown ml-4 mni-style width135 reg-const"
                                style={{
                                  // marginTop: "20px",
                                  // marginBottom: "48px",
                                  marginTop: 15,
                                  height: "40px",
                                }}
                              >
                                <Select
                                  name="unitOfMeasure"
                                  labelkey={"l"}
                                  label=""
                                  className={`sort-dropdown ${
                                    dataItem?.unitOfMeasure?.v == 0
                                      ? "mni-selected-dropdown"
                                      : ""
                                  }`}
                                  options={uomOpts?.options}
                                  selected={
                                    dataItem?.notPresent
                                      ? { l: "None", v: "0" }
                                      : dataItem?.unitOfMeasure
                                  }
                                  // selected={typeof dataItem?.unitOfMeasure === 'string' ? {l : dataItem?.unitOfMeasure, v: dataItem?.unitOfMeasure} : dataItem?.unitOfMeasure}
                                  disabled={
                                    type === "viewprofile" ||
                                    dataItem?.notPresent
                                  }
                                  onSelect={(e) => {
                                    const list = [...pesticidesHerbicides]
                                    list[ind].unitOfMeasure = e
                                    setPesticidesHerbicides([...list])
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 10,
                                  // paddingTop: "8px",
                                  height: "16px",
                                  marginTop: -31,
                                }}
                              >
                                {submit &&
                                dataItem?.tclpRegLimit?.length > 0 &&
                                !selectAllPestHerbFlag &&
                                (dataItem?.aboveTclpLimit !== 0 ||
                                  dataItem?.belowTclpLimit !== 0) &&
                                dataItem?.notPresent === 0 ? (
                                  dataItem?.unitOfMeasure?.v === "0" ? (
                                    <div>UoM is required</div>
                                  ) : null
                                ) : null}
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {/*Back, Save and Cancel actions*/}
                {type !== "viewprofile" && (
                  <div className="row m-0 new-row-block">
                    <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4">
                      <div className="row m-0 float-left">
                        <button
                          type="button"
                          className={`cancel-button`}
                          onClick={() => backAction()}
                        >
                          Back
                        </button>
                      </div>
                      <div className="row m-0 float-right m-r-2r">
                        <button
                          type="submit"
                          ref={ref}
                          className={`green-button`}
                          onClick={() => {
                            setSubmit(true)
                          }}
                        >
                          Save & Continue
                        </button>
                      </div>
                      <div className="row m-0 float-right m-r-2r">
                        <button
                          type="button"
                          className="cancel-button"
                          onClick={() => cancelAction()}
                        >
                          Cancel
                        </button>
                      </div>
                      <span className="float-right text-red m-r-2r">
                        {`*Required Fields`}
                      </span>
                    </div>
                    <AlertModal
                      showError={showError}
                      setShowError={setShowError}
                      seekingStep={seekingStep}
                      submitAction={() =>
                        handleAlerts(values, errors, isValid, seekingStep)
                      }
                      showAlert={showAlert}
                      setShowAlert={setShowAlert}
                    />
                  </div>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}

export default regulatedconstituentsenhancer(RegulatedConstituents)
