import React, { useEffect, useState, useRef } from "react"
import api from "api"
import {
  ALL_PROFILE_LIST,
  ALL_PROFILE_STATUS,
  CURRENT_PROFILE,
  SORT_PROFILE_LIST,
  // ALL_EPAWASTE_CODES,
  // ALL_FACILITY_PROCESS_CODES,
  // ALL_FORM_CODES,
  // ALL_FREQUENCY_TYPES,
  // ALL_ORIGIN_CODES,
  // ALL_PROCESS_CODES,
  // ALL_SOURCE_CODES,
  // ALL_VENDORS,
  SET_PROGRESS_STEP,
  SET_NEW_PROGRESS_STEP,
  ALL_GENERATORS,
  // GET_DEFAULT_GENE,
  GET_PRINT_STATUS,
  SET_SHOW_DELETE,
  RECERTIFY_PROFILE,
  GENERATOR_DETAILS,
  GET_ALL_PROFILE_TEMPLATES,
  SET_PROGRESS_STEP_ONLY,
  SET_PROFILE_NAME,
  PROFILE_CRETAE_FROM_GENE,
  GET_PROFILE_FOR_PRINT_LABEL,
  SELECTED_EPA_WASTE_CODES,
  SELECTED_EPA_UHCS,
  SELECTED_STATE_CODE,
  SELECTED_LDR_RCRA_CODE,
  SELECTED_LDR_UHCS,
  RECERTIFY_VALIDATE,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const [printLoader, setPrintLoader] = useState(false)
    const [downloadLoader, setDownloadLoader] = useState(false)
    const [loader, setLoader] = useState(true)
    const firstTimeFetch = useRef(true)

    const profileList = useSelector(
      (state) => state.profileDetails.profilesList
    )

    const getAllProfileStatus = async () => {
      try {
        let res = await api.g_getAllProfileStatus()
        if (!res) return

        dispatch({ type: ALL_PROFILE_STATUS, payload: res })
      } catch (error) {}
    }

    // const fetchAllProfiles = async (payload) => {
    //   try {
    //     let res = await api.p_getAllProfilesList(payload)
    //     if (!res) return

    //     dispatch({ type: ALL_PROFILE_LIST, payload: res })
    //   } catch (error) {
    //     dispatch({ type: ALL_PROFILE_LIST, payload: { data: [] } })
    //   } finally {
    //     setLoading(false)
    //   }
    // }

    const fetchAllProfiles = async (payload) => {
      if (
        payload?.generatorIdList?.length > 0 ||
        payload?.generatorIdList === ""
      ) {
        try {
          let res = await api.p_getAllProfilesList(payload)
          if (!res) return

          dispatch({ type: ALL_PROFILE_LIST, payload: res })
          setLoading(false)
        } catch (error) {
          dispatch({ type: ALL_PROFILE_LIST, payload: { data: [] } })
          setLoading(false)
        } finally {
          setLoading(false)
        }
      } else {
        dispatch({ type: ALL_PROFILE_LIST, payload: { data: [] } })
        setLoading(false)
      }
    }

    const mapDefaultGenerator = async (val) => {
      if (val) {
        await dispatch({
          type: PROFILE_CRETAE_FROM_GENE,
          payload: {
            isProfileFromGenerator: true,
            selectedProfileGenerator: val,
          },
        })
      } else {
        await dispatch({
          type: PROFILE_CRETAE_FROM_GENE,
          payload: {
            isProfileFromGenerator: false,
            selectedProfileGenerator: null,
          },
        })
      }
    }

    const downloadDocument = async (id, status, revsNo) => {
      let payload

      if (status?.toLowerCase() == "draft" && id != 0) {
        payload = {
          profileId: id,
        }
      } else {
        payload = {
          profileId: 0,
          profileNumber: id,
          coreRevisionNumber: revsNo,
        }
      }
      try {
        let res = await api.pb_downloadProfileDocuments(payload)
        if (res?.type === "application/pdf") {
          let url = window.URL.createObjectURL(res)
          let a = document.createElement("a")
          a.href = url
          a.download = `profile${id}.pdf`
          a.click()
          a.remove()
          setTimeout(() => window.URL.revokeObjectURL(url), 100)
        } else {
          toast.error("Waste profile form not found.")
        }
      } catch (error) {
      } finally {
        setDownloadLoader(false)
      }
    }

    const printDocument = async (id, status, revsNo) => {
      let payload

      if (status?.toLowerCase() == "draft" && id != 0) {
        payload = {
          profileId: id,
        }
      } else {
        payload = {
          profileId: 0,
          profileNumber: id,
          coreRevisionNumber: revsNo,
        }
      }
      try {
        let res = await api.pb_downloadProfileDocuments(payload)
        let url = window.URL.createObjectURL(res)
        const iframe = document.querySelector("#pdf-frame")
        iframe.src = ""
        iframe.src = url
        iframe.style.display = "none"
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
        setTimeout(function () {
          document.querySelector("#pdf-frame").contentWindow.print()
        }, 1000)
      } catch (error) {
      } finally {
        setPrintLoader(false)
      }
    }

    const getAllGenerators = async () => {
      try {
        // let res = await api.g_getAllGenerators(customer_name)
        setLoader(true)
        let res = await api.g_getAllGenerators()
        if (!res) return

        dispatch({ type: ALL_GENERATORS, payload: res })
      } catch (error) {
      } finally {
        setLoader(false)
      }
    }

    const deleteProfile = async (profileId, payload) => {
      let req = {
        profileRequestId: profileId,
      }
      try {
        let res = await api.pu_deleteProfile(req)
        if (!res) return
        showDelete(true)
        fetchAllProfiles(payload)
      } catch (error) {}
    }

    const goTorecertifyValidateFromEdit = async (profileRequestId) => {
      try {
        let res = await api.p_recertifyValidate({
          ProfileRequestId: profileRequestId,
          ProfileNumber: null,
          RevisionNum: 0,
        })
        if (!res) return

        dispatch({ type: RECERTIFY_VALIDATE, payload: res })
      } catch (error) {}
    }

    const goTorecertifyValidate = async (version, profileRequestId) => {
      try {
        let res = await api.p_recertifyValidate({
          ProfileRequestId: null,
          ProfileNumber: profileRequestId,
          RevisionNum: version,
        })
        if (!res) return

        dispatch({ type: RECERTIFY_VALIDATE, payload: res })
      } catch (error) {}
    }

    const editProfile = async (profileId, generatorId) => {
      await dispatch({
        type: PROFILE_CRETAE_FROM_GENE,
        payload: {
          isProfileFromGenerator: true,
          selectedProfileGenerator: generatorId,
        },
      })
      goToEditProfile("editprofile", profileId)
    }

    const viewProfile = async (profileId, version) => {
      goToEditProfile("viewprofile", profileId, version)
    }
    // useEffect(() => {
    //   getAllProfileStatus()
    //   dispatch({
    //     type: SET_NEW_PROGRESS_STEP,
    //   })
    //   dispatch({
    //     type: SET_PROGRESS_STEP,
    //     step: 1,
    //     progressStep: 1,
    //     percentage: 0,
    //   })
    //   dispatch({ type: CURRENT_PROFILE, payload: {} })
    // }, [])

    const getProfileData = async (reqId, template, isNonDraft) => {
      let req
      if (isNonDraft || reqId == 0) {
        req = {
          profileRequestId: 0,
          webProfileId: "",
          coreProfileNumber: reqId?.profile,
          coreVersionNumber: Number(reqId?.version),
        }
      } else {
        req = {
          profileRequestId: reqId,
        }
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        if (template)
          dispatch({
            type: CURRENT_PROFILE,
            payload: { ...res, templateFlag: true, profileName: "" },
          })
        else dispatch({ type: CURRENT_PROFILE, payload: res })
        setLoading(false)
      } catch (error) {
        setLoading(false)
      } finally {
        setLoading(false)
      }
    }

    const createProfileFromTemplate = async (
      page,
      profileNumber,
      revisionNumber
    ) => {
      mapDefaultGenerator()
      try {
        let res = await api.p_getCloneProfile({
          profileId: 0,
          profileNumber: profileNumber,
          revisionNum: revisionNumber,
          isProfileFromTemplate: true,
        })
        if (!res) return
        await getProfileData(res?.data?.profileRequestId, true)
        let pId = res?.data?.profileRequestId
        await goToEditProfile(page, pId)
      } catch (error) {}
    }

    const goToCloneProfile = async (page, profileId) => {
      const getProfile = profileList.find(
        (item) => item.profileId == profileId || item.profile == profileId
      )

      if (getProfile !== undefined) {
        try {
          let res = await api.p_getCloneProfile({
            profileId: getProfile?.profileId || 0,
            profileNumber: getProfile?.profile,
            revisionNum: getProfile?.version,
          })
          if (!res) return
          await getProfileData(res?.data?.profileRequestId, true)
          let pId = res?.data?.profileRequestId
          await goToEditProfile(page, pId)
        } catch (error) {}
      }
    }

    const showDelete = async (res = false) => {
      dispatch({ type: SET_SHOW_DELETE, payload: res })
    }

    const goToCreateProfile = async (page) => {
      await dispatch({ type: GENERATOR_DETAILS, payload: {} })
      await dispatch({ type: CURRENT_PROFILE, payload: {} })
      await dispatch({ type: SELECTED_EPA_WASTE_CODES, payload: [] })
      await dispatch({ type: SELECTED_EPA_UHCS, payload: [] })
      await dispatch({ type: SELECTED_STATE_CODE, payload: [] })
      await dispatch({ type: SELECTED_LDR_RCRA_CODE, payload: [] })
      await dispatch({ type: SELECTED_LDR_UHCS, payload: [] })
      await dispatch({
        type: SET_NEW_PROGRESS_STEP,
      })
      await dispatch({
        type: SET_PROGRESS_STEP,
        step: 1,
        progressStep: 1,
        percentage: 0,
      })
      history.push(`/profiles/${page}`)
    }
    const goToEditProfile = async (page, id, vers) => {
      await dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: 1 })
      await dispatch({ type: GENERATOR_DETAILS, payload: {} })
      // await dispatch({
      //   type: SET_NEW_PROGRESS_STEP,
      // })
      // await dispatch({
      //   type: SET_PROGRESS_STEP,
      //   step: 0,
      //   percentage: 100,
      // })
      dispatch({ type: RECERTIFY_VALIDATE, payload: [] })
      if (page !== "viewprofile") {
        await goTorecertifyValidateFromEdit(id)
      }
      if (vers) {
        history.push(`/profiles/${page}/${id}/${vers}`)
      } else {
        history.push(`/profiles/${page}/${id}`)
      }
    }

    const goToRecertifyProfile = async (data) => {
      // await dispatch({ type: CURRENT_PROFILE, payload: {} })
      dispatch({ type: RECERTIFY_PROFILE, payload: data })
      dispatch({ type: RECERTIFY_VALIDATE, payload: [] })
      await getProfileData(data, false, true)
      await goTorecertifyValidate(data?.version, data?.profile)
      history.push(`/profiles/recertify`)
    }

    const setProfileNameAction = async (data) => {
      await dispatch({ type: SET_PROFILE_NAME, payload: data })
    }

    const searchSubmit = (payload) => {
      fetchAllProfiles(payload)
    }

    const sortData = (options, data) => {
      dispatch({
        type: SORT_PROFILE_LIST,
        payload: options,
        data: {
          type: data?.type,
          fieldName: data?.fieldName,
        },
      })
    }

    // const fetchAllFacilityProcessCodes = async () => {
    //   const vendorId = 547
    //   try {
    //     let res = await api.g_getAllFacilityProcessCodes(vendorId)
    //     if (!res) return

    //     dispatch({ type: ALL_FACILITY_PROCESS_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllProcessCodes = async () => {
    //   const req = {
    //     vendorId: 2525,
    //     plantGeneratorId: 0,
    //     mode: "profile",
    //   }

    //   try {
    //     let res = await api.p_getAllProcessCodes(req)
    //     if (!res) return

    //     dispatch({ type: ALL_PROCESS_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllSourceCodes = async () => {
    //   try {
    //     let res = await api.g_getAllSourceCodes()
    //     if (!res) return

    //     dispatch({ type: ALL_SOURCE_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllFormCodes = async () => {
    //   try {
    //     let res = await api.g_getAllFormCodes()
    //     if (!res) return

    //     dispatch({ type: ALL_FORM_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllOriginCodes = async () => {
    //   let req = {
    //     mode: "OriginCode",
    //   }
    //   try {
    //     let res = await api.g_getAllOriginCodes()
    //     if (!res) return

    //     dispatch({ type: ALL_ORIGIN_CODES, payload: res })
    //   } catch (error) {}
    // }
    // const fetchAllVendorsData = async () => {
    //   try {
    //     let res = await api.g_getAllVendors()
    //     if (!res) return

    //     dispatch({ type: ALL_VENDORS, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllProcessCodes = async () => {
    //   const req = {
    //     vendorId: 2525,
    //     plantGeneratorId: 0,
    //     mode: "profile",
    //   }
    //   try {
    //     let res = await api.p_getAllProcessCodes(req)
    //     if (!res) return

    //     dispatch({ type: ALL_PROCESS_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllSourceCodes = async () => {
    //   try {
    //     let res = await api.g_getAllSourceCodes()
    //     if (!res) return

    //     dispatch({ type: ALL_SOURCE_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllFormCodes = async () => {
    //   try {
    //     let res = await api.g_getAllFormCodes()
    //     if (!res) return

    //     dispatch({ type: ALL_FORM_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllOriginCodes = async () => {
    //   let req = {
    //     mode: "OriginCode",
    //   }
    //   try {
    //     let res = await api.g_getAllOriginCodes()
    //     if (!res) return

    //     dispatch({ type: ALL_ORIGIN_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllEPAWasteCodes = async () => {
    //   let req = {
    //     ModeId: 1,
    //     ProfileId: 2,
    //     FileterId: 3,
    //   }
    //   try {
    //     let res = await api.g_getAllEPAWasteCodes(req)
    //     if (!res) return

    //     dispatch({ type: ALL_EPAWASTE_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllFacilityProcessCodes = async () => {
    //   const vendorId = 547
    //   try {
    //     let res = await api.g_getAllFacilityProcessCodes(vendorId)
    //     if (!res) return

    //     dispatch({ type: ALL_FACILITY_PROCESS_CODES, payload: res })
    //   } catch (error) {}
    // }

    // const fetchAllFrequencyTypes = async () => {
    //   try {
    //     let res = await api.g_getAllFrequencyTypes()
    //     if (!res) return

    //     dispatch({ type: ALL_FREQUENCY_TYPES, payload: res })
    //   } catch (error) {}
    // }

    const handlePrintState = (print, data) => {
      const isNonDraft = data.status?.toLowerCase() != "draft"
      getProfileData(
        isNonDraft || data?.profileId == 0 ? data : data?.profileId,
        false,
        isNonDraft
      )
      dispatch({ type: GET_PROFILE_FOR_PRINT_LABEL, payload: data })
      dispatch({ type: GET_PRINT_STATUS, payload: print })
    }

    const fetchAllProfileTemplates = async () => {
      try {
        let res = await api.g_getAllProfileTemplates()
        if (!res) return
        dispatch({ type: GET_ALL_PROFILE_TEMPLATES, payload: res })
      } catch (error) {}
    }

    useEffect(() => {
      if (firstTimeFetch.current) {
        setProfileNameAction("")
        getAllGenerators()
        fetchAllProfileTemplates()
        getAllProfileStatus()
        dispatch({
          type: SET_NEW_PROGRESS_STEP,
        })
        dispatch({
          type: SET_PROGRESS_STEP,
          step: 1,
          progressStep: 1,
          percentage: 0,
        })
        dispatch({ type: CURRENT_PROFILE, payload: {} })
        dispatch({ type: RECERTIFY_VALIDATE, payload: {} })
        firstTimeFetch.current = false
      }
      // fetchAllVendorsData()
      // fetchAllProcessCodes()
      // fetchAllFormCodes()
      // fetchAllOriginCodes()
      // fetchAllSourceCodes()
      // fetchAllEPAWasteCodes()
      // fetchAllFacilityProcessCodes()
      // fetchAllFrequencyTypes()
    }, [])

    return (
      <Component
        {...props}
        {...{
          // docData,
          // updateDocData,
          sortData,
          goToCreateProfile,
          goToCloneProfile,
          goToRecertifyProfile,
          mapDefaultGenerator,
          searchSubmit,
          deleteProfile,
          editProfile,
          handlePrintState,
          viewProfile,
          showDelete,
          downloadDocument,
          printDocument,
          createProfileFromTemplate,
          setProfileNameAction,
          setLoading,
          loading,
          printLoader,
          setPrintLoader,
          downloadLoader,
          setDownloadLoader,
          loader,
          // changeAllDocs,
          // downloadPDF,
          // allDocsCheck,
          // pageNo,
          // gotoPage,
          // submitted,
          // viewDoc,
          // handleSearchChange,
        }}
      />
    )
  }

export default enhancer
