import React, { useState, useEffect } from "react"
import api from "api"
import {
  SET_ACTIVE_STEP,
  SET_PROGRESS_STEP,
  GET_PROGRESS_STEP,
  CURRENT_PROFILE,
  AVAILABLE_REG_CONST,
  SET_PROGRESS_STEP_ONLY,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useParams } from "react-router-dom/cjs/react-router-dom"
// import { getBooleanValues, getValues } from "routes/Utilities/util"
import { useHistory } from "react-router-dom"
// import { isArray } from "lodash"

let regulatedconstituentsenhancer =
  (Component) =>
  ({ ...props }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [showError, setShowError] = useState(false)
    const [submit, setSubmit] = useState(false)
    const [initialDataFlag, setInitialDataFlag] = useState(true)
    const [selectAllMetalsFlag, setSelectedAllMetalsFlag] = useState(false)
    const [selectAllVolatilesFlag, setSelectedAllVolatilesFlag] =
      useState(false)
    const [selectAllSemiVolatilesFlag, setSelectedAllSemiVolatilesFlag] =
      useState(false)
    const [selectAllPestHerbFlag, setSelectedAllPestHerbFlag] = useState(false)

    const currentProfile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )
    const error_highlight = useSelector(
      (state) => state.profileDetails.errorHighlight
    )

    const availableRegConstData = useSelector(
      (state) => state.profileDetails?.availableRegConst
    )

    const [infoSupplied, setInfoSupplied] = useState({
      analytical: false,
      generatorKnowledge: false,
      sds: false,
    })

    let uomOpts = {
      id: 102,
      options: [
        {
          l: "None",
          v: "0",
        },
        {
          l: "TCLP(MG/L) ",
          v: "1",
        },
        {
          l: "Total(PPM)",
          v: "2",
        },
        {
          l: "ppb (ug/L) ",
          v: "3",
        },
      ],
    }

    const initValues = {
      infoSuppliedBelow: {
        analytical: false,
        generatorKnowledge: false,
        sds: false,
      },
    }
    const { id } = useParams()
    let initialValues = initValues
    // const [initialValues, setInitialValues] = useState(initValues)
    const [metals, setMetals] = useState([])
    const [volatileCompounds, setVolatileCompounds] = useState([])
    const [semiVolatiles, setSemiVolatiles] = useState([])
    const [pesticidesHerbicides, setPesticidesHerbicides] = useState([])

    let valSchema = {
      // infoSuppliedBelow: Yup.object()
      //   .shape({
      //     analyticalInd: Yup.boolean().required("Please check any one of options"),
      //     generatorKnowledgeInd: Yup.boolean().when("analyticalInd", {
      //       is: false,
      //       then: Yup.boolean().required("Please check any one of options"),
      //     }),
      //     sdsInd: Yup.boolean().when("generatorKnowledgeInd", {
      //       is: false,
      //       then: Yup.boolean().required("Please check any one of options"),
      //     }),
      //   })
      //   .nullable(),
      name: Yup.string(),
      tclpRegLimit: Yup.number(),
      notPresent: Yup.number(),
      belowTclpLimit: Yup.number(),
      aboveTclpLimit: Yup.number(),
      range: Yup.string(),
      unitOfMeasure: Yup.string(),
    }

    const checkOneOf = (mData) => {
      let d = Array.isArray(mData) ? [...mData] : []
      let count = 0
      //eslint-disable-next-line
      for (const item of d) {
        if (
          (item?.notPresent === 0 ||
            item?.notPresent === null ||
            item?.notPresent === undefined) &&
          (item?.belowTclpLimit === 0 ||
            item?.belowTclpLimit === null ||
            item?.belowTclpLimit === undefined) &&
          (item?.aboveTclpLimit === 0 ||
            item?.aboveTclpLimit === null ||
            item?.aboveTclpLimit === undefined)
        ) {
          count = count + 1
        }
      }
      if (count === d?.length) return true
      return false
    }

    const validateRangeSymbols = (lt, gt, range, limit, measure) => {
      if (lt || gt) {
        if (measure === "below") {
          return Number(range) >= Number(limit)
        } else return Number(range) <= Number(limit)
      } else {
        if (measure === "below") {
          return Number(range) > Number(limit)
        } else return Number(range) < Number(limit)
      }
    }

    const hasRangeUomError = (csts) => {
      let constituents = [...csts]
      let hasRange = false
      let hasUoM = false
      //eslint-disable-next-line
      for (const item in constituents) {
        if (
          constituents[item]?.aboveTclpLimit === 1 ||
          constituents[item]?.belowTclpLimit === 1
        ) {
          let lt_exists = String(constituents[item]?.range).startsWith("<")
          let gt_exists = String(constituents[item]?.range).startsWith(">")
          let range_trim =
            gt_exists || lt_exists
              ? String(constituents[item]?.range).slice(1)
              : constituents[item]?.range
          let belowError =
            constituents[item]?.belowTclpLimit === 1
              ? constituents[item]?.tclpRegLimit?.length > 0 &&
                constituents[item]?.tclpRegLimit !== null &&
                constituents[item]?.tclpRegLimit !== undefined &&
                validateRangeSymbols(
                  lt_exists,
                  gt_exists,
                  range_trim,
                  constituents[item]?.tclpRegLimit,
                  "below"
                )
              : false
          let aboveError =
            constituents[item]?.aboveTclpLimit === 1
              ? constituents[item]?.tclpRegLimit?.length > 0 &&
                constituents[item]?.tclpRegLimit !== null &&
                constituents[item]?.tclpRegLimit !== undefined &&
                validateRangeSymbols(
                  lt_exists,
                  gt_exists,
                  range_trim,
                  constituents[item]?.tclpRegLimit,
                  "above"
                )
              : false
          hasRange =
            constituents[item]?.tclpRegLimit?.length > 0 &&
            constituents[item]?.tclpRegLimit !== null &&
            constituents[item]?.tclpRegLimit !== undefined &&
            (constituents[item]?.range === "" ||
              constituents[item]?.range === undefined ||
              belowError ||
              aboveError)
              ? true
              : false
          hasUoM =
            constituents[item]?.unitOfMeasure?.v === "0" ||
            constituents[item]?.unitOfMeasure?.v === ""
              ? true
              : false
        } else if (constituents[item]?.notPresent === 1) {
          hasUoM =
            hasUoM ||
            constituents[item]?.unitOfMeasure?.v !== "0" ||
            constituents[item]?.unitOfMeasure?.v === ""
              ? true
              : false
        } else {
          hasUoM = hasUoM || false
        }
        if (hasRange || hasUoM) return true
      }
      return false
    }

    const getProfileData = async (reqId) => {
      const req = {
        profileRequestId: id || reqId,
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }
    const getAllRegConstNames = async () => {
      try {
        let res = await api.g_getAllRegConstNameByCategory()
        if (!res) return
        dispatch({ type: AVAILABLE_REG_CONST, payload: res })
      } catch (error) {}
    }

    const transformUoM = (obj) => {
      if (typeof obj?.unitOfMeasure === "string") {
        obj.unitOfMeasure = {
          l: "None",
          v: "0",
        }
      }
      return obj
    }

    useEffect(() => {
      getAllRegConstNames()
    }, [])

    useEffect(() => {
      if (initialDataFlag) {
        let data = availableRegConstData
        let mData = data?.filter((val) => val.category === "metals")
        let metalsData = mData?.map(transformUoM)
        let vData = data?.filter((val) => val.category === "volatile")
        let volatilesData = vData?.map(transformUoM)
        let svData = data?.filter((val) => val.category === "semivolatile")
        let semiVolatilesData = svData?.map(transformUoM)
        let pData = data?.filter((val) => val.category === "pesticides")
        let pesticidesData = pData?.map(transformUoM)
        setMetals(metalsData)
        setVolatileCompounds(volatilesData)
        setSemiVolatiles(semiVolatilesData)
        setPesticidesHerbicides(pesticidesData)
      }
    }, [availableRegConstData])

    const toCamelCase = (str) => {
      return str.charAt(0).toLowerCase() + str.slice(1)
    }

    const transformKeys = (obj) => {
      if (obj?.UnitOfMeasure) {
        if (obj?.NotPresent === 1) {
          obj.UnitOfMeasure = {
            l: "None",
            v: "0",
          }
        } else {
          obj.UnitOfMeasure = {
            l: uomOpts.options[obj?.UnitOfMeasure]?.l,
            v: obj?.UnitOfMeasure,
          }
        }
      }
      return Object.keys(obj).reduce((acc, key) => {
        const camelCaseKey = toCamelCase(key)
        acc[camelCaseKey] = obj[key]
        return acc
      }, {})
    }

    useEffect(() => {
      let rescount = 0
      if (id && currentProfile?.profileMetals) {
        // (type === "editProfile" || type === "viewProfile")
        const profileMetals = currentProfile?.profileMetals
          ? JSON.parse(currentProfile?.profileMetals)
          : null
        // if (dataFlag) {
        if (profileMetals) {
          setInfoSupplied({
            ...infoSupplied,
            analytical: profileMetals?.Analytical,
            generatorKnowledge: profileMetals?.GeneratorKnowledge,
            sds: profileMetals?.SDS,
          })
          const mappedMetals = profileMetals?.Metals?.map(transformKeys)
          setMetals(mappedMetals)
          const mappedVolatiles =
            profileMetals?.VolatileCompounds?.map(transformKeys)
          setVolatileCompounds(mappedVolatiles)
          const mappedSemiVolatiles =
            profileMetals?.SemiVolatiles?.map(transformKeys)
          setSemiVolatiles(mappedSemiVolatiles)
          const mappedPestHerbs =
            profileMetals?.PesticidesHerbicides?.map(transformKeys)
          setPesticidesHerbicides(mappedPestHerbs)
          setInitialDataFlag(false)
          setSubmit(false)
          if (
            profileMetals?.Analytical ||
            profileMetals?.GeneratorKnowledge ||
            profileMetals?.SDS
          )
            rescount = rescount + 1
          // let totalCount =
          //   metals?.length +
          //   volatileCompounds?.length +
          //   semiVolatiles?.length +
          //   pesticidesHerbicides?.length
          if (mappedMetals?.length > 0) {
            // mappedMetals?.map((item) => {
            //   if (
            //     item?.notPresent === 1 ||
            //     item?.belowTclpLimit === 1 ||
            //     item?.aboveTclpLimit === 1
            //   )
            //     rescount = rescount + 1
            // })
            let metalsMandatory = mappedMetals?.some(
              ({
                notPresent,
                belowTclpLimit,
                aboveTclpLimit,
                range,
                unitOfMeasure,
              }) => {
                if (notPresent) return true
                else if (
                  (belowTclpLimit || aboveTclpLimit) &&
                  range &&
                  unitOfMeasure?.v
                ) {
                  return true
                } else return false
              }
            )
            if (metalsMandatory) rescount = rescount + 1
          }
          if (mappedVolatiles?.length > 0) {
            // mappedVolatiles?.map((item) => {
            //   if (
            //     item?.notPresent === 1 ||
            //     item?.belowTclpLimit === 1 ||
            //     item?.aboveTclpLimit === 1
            //   )
            //     rescount = rescount + 1
            // })
            let volatilesMandatory = mappedVolatiles?.some(
              ({
                notPresent,
                belowTclpLimit,
                aboveTclpLimit,
                range,
                unitOfMeasure,
              }) => {
                if (notPresent) return true
                else if (
                  (belowTclpLimit || aboveTclpLimit) &&
                  range &&
                  unitOfMeasure?.v
                ) {
                  return true
                } else return false
              }
            )
            if (volatilesMandatory) rescount = rescount + 1
          }
          if (mappedSemiVolatiles?.length > 0) {
            // mappedSemiVolatiles?.map((item) => {
            //   if (
            //     item?.notPresent === 1 ||
            //     item?.belowTclpLimit === 1 ||
            //     item?.aboveTclpLimit === 1
            //   )
            //     rescount = rescount + 1
            // })
            let semiVolatilesMandatory = mappedSemiVolatiles?.some(
              ({
                notPresent,
                belowTclpLimit,
                aboveTclpLimit,
                range,
                unitOfMeasure,
              }) =>
                notPresent ||
                ((belowTclpLimit || aboveTclpLimit) &&
                  range &&
                  unitOfMeasure?.v)
            )
            if (semiVolatilesMandatory) rescount = rescount + 1
          }
          if (mappedPestHerbs?.length > 0) {
            // mappedPestHerbs?.map((item) => {
            //   if (
            //     item?.notPresent === 1 ||
            //     item?.belowTclpLimit === 1 ||
            //     item?.aboveTclpLimit === 1
            //   )
            //     rescount = rescount + 1
            // })
            let pestsMandatory = mappedPestHerbs?.some(
              ({
                notPresent,
                belowTclpLimit,
                aboveTclpLimit,
                range,
                unitOfMeasure,
              }) =>
                notPresent ||
                ((belowTclpLimit || aboveTclpLimit) &&
                  range &&
                  unitOfMeasure?.v)
            )
            if (pestsMandatory) rescount = rescount + 1
          }
          calcProgress(rescount, 5)
        } else {
          calcProgress(0, 5)
        }
      }
    }, [id, currentProfile?.generatorId])

    const setProgress = (data, length) => {
      let count = 0
      let metalsProgress = data?.metals
      let volatileProgress = data?.volatileCompounds
      let semiVolatileProgress = data?.semiVolatiles
      let pesticidesProgress = data?.pesticidesHerbicides
      if (data?.analytical || data?.generatorKnowledge || data?.sds)
        count = count + 1
      // let len =
      //   metalsProgress?.length +
      //   volatileProgress?.length +
      //   semiVolatileProgress?.length +
      //   pesticidesProgress?.length
      if (metalsProgress.length > 0) {
        // metalsProgress?.map((item) => {
        //   if (item?.notPresent || item?.belowTclpLimit || item?.aboveTclpLimit)
        //     count = count + 1
        // })
        let metalsMandated = metalsProgress?.some(
          ({
            notPresent,
            belowTclpLimit,
            aboveTclpLimit,
            range,
            unitOfMeasure,
          }) =>
            notPresent ||
            ((belowTclpLimit || aboveTclpLimit) && range && unitOfMeasure?.v)
        )
        if (metalsMandated) count = count + 1
      }
      if (volatileProgress.length > 0) {
        // volatileProgress?.map((item) => {
        //   if (item?.notPresent || item?.belowTclpLimit || item?.aboveTclpLimit)
        //     count = count + 1
        // })
        let volatilesMandated = volatileProgress?.some(
          ({
            notPresent,
            belowTclpLimit,
            aboveTclpLimit,
            range,
            unitOfMeasure,
          }) =>
            notPresent ||
            ((belowTclpLimit || aboveTclpLimit) && range && unitOfMeasure?.v)
        )
        if (volatilesMandated) count = count + 1
      }
      if (semiVolatileProgress.length > 0) {
        // semiVolatileProgress?.map((item) => {
        //   if (item?.notPresent || item?.belowTclpLimit || item?.aboveTclpLimit)
        //     count = count + 1
        // })
        let semiVolatilesMandated = semiVolatileProgress?.some(
          ({
            notPresent,
            belowTclpLimit,
            aboveTclpLimit,
            range,
            unitOfMeasure,
          }) =>
            notPresent ||
            ((belowTclpLimit || aboveTclpLimit) && range && unitOfMeasure?.v)
        )
        if (semiVolatilesMandated) count = count + 1
      }
      if (pesticidesProgress.length > 0) {
        // pesticidesProgress?.map((item) => {
        //   if (item?.notPresent || item?.belowTclpLimit || item?.aboveTclpLimit)
        //     count = count + 1
        // })
        let pestsMandated = pesticidesProgress?.some(
          ({
            notPresent,
            belowTclpLimit,
            aboveTclpLimit,
            range,
            unitOfMeasure,
          }) =>
            notPresent ||
            ((belowTclpLimit || aboveTclpLimit) && range && unitOfMeasure?.v)
        )
        if (pestsMandated) count = count + 1
      }
      dispatch({
        type: SET_PROGRESS_STEP,
        step: 6,
        progressStep: props.showAlert ? props.seekingStep + 1 : 7,
        percentage: (count / length) * 100,
        numerator: count,
        denominator: length,
      })
    }

    const calcProgress = async (count, leng) => {
      await dispatch({
        type: GET_PROGRESS_STEP,
        step: 6,
        progressStep: 6,
        percentage: (count / leng) * 100,
        numerator: count,
        denominator: leng,
      })
    }

    const onSubmit = async () => {
      let isMetals = checkOneOf(metals)
      let isVolatiles = checkOneOf(volatileCompounds)
      let isSemiVolatiles = checkOneOf(semiVolatiles)
      let isPestsHerbs = checkOneOf(pesticidesHerbicides)
      let hasErrorMetals = hasRangeUomError(metals)
      let hasErrorVolatiles = hasRangeUomError(volatileCompounds)
      let hasErrorSemiVolatiles = hasRangeUomError(semiVolatiles)
      let hasErrorPesticides = hasRangeUomError(pesticidesHerbicides)
      if (
        (!infoSupplied?.analytical &&
          !infoSupplied?.generatorKnowledge &&
          !infoSupplied?.sds) ||
        hasErrorMetals ||
        hasErrorVolatiles ||
        hasErrorSemiVolatiles ||
        hasErrorPesticides ||
        isMetals ||
        isVolatiles ||
        isSemiVolatiles ||
        isPestsHerbs
      ) {
        return
      }
      props.setShowAlert(false)
      const payload = {
        profileRequestId: currentProfile?.profileRequestId || id,
        SaveRegulatedConstituentsDetailRequest: {
          analytical: infoSupplied?.analytical || false,
          generatorKnowledge: infoSupplied?.generatorKnowledge || false,
          sds: infoSupplied?.sds || false,
          metals: metals?.map((val) => {
            return {
              category: "metals",
              id: val?.id || 0,
              name: val?.name || "",
              tclpRegLimit: val?.tclpRegLimit || "",
              notPresent: val?.notPresent || 0,
              belowTclpLimit: val?.belowTclpLimit || 0,
              aboveTclpLimit: val?.aboveTclpLimit || 0,
              range: val?.range || "",
              unitOfMeasure: val?.unitOfMeasure?.v,
            }
          }),
          volatileCompounds: volatileCompounds?.map((val) => {
            return {
              category: "volatile",
              id: val?.id,
              name: val?.name || "",
              tclpRegLimit: val?.tclpRegLimit || "",
              notPresent: val?.notPresent || 0,
              belowTclpLimit: val?.belowTclpLimit || 0,
              aboveTclpLimit: val?.aboveTclpLimit || 0,
              range: val?.range || "",
              unitOfMeasure: val?.unitOfMeasure?.v || "",
            }
          }),
          semiVolatiles: semiVolatiles?.map((val) => {
            return {
              category: "semivolatile",
              id: val?.id,
              name: val?.name || "",
              tclpRegLimit: val?.tclpRegLimit || "",
              notPresent: val?.notPresent || 0,
              belowTclpLimit: val?.belowTclpLimit || 0,
              aboveTclpLimit: val?.aboveTclpLimit || 0,
              range: val?.range || "",
              unitOfMeasure: val?.unitOfMeasure?.v || "",
            }
          }),
          pesticidesHerbicides: pesticidesHerbicides?.map((val) => {
            return {
              category: "pesticides",
              id: val?.id,
              name: val?.name || "",
              tclpRegLimit: val?.tclpRegLimit || "",
              notPresent: val?.notPresent || 0,
              belowTclpLimit: val?.belowTclpLimit || 0,
              aboveTclpLimit: val?.aboveTclpLimit || 0,
              range: val?.range || "",
              unitOfMeasure: val?.unitOfMeasure?.v || "",
            }
          }),
        },
      }
      let formData = {
        analytical: infoSupplied?.analytical,
        generatorKnowledge: infoSupplied?.generatorKnowledge,
        sds: infoSupplied?.sds,
        metals: metals,
        volatileCompounds: volatileCompounds,
        semiVolatiles: semiVolatiles,
        pesticidesHerbicides: pesticidesHerbicides,
      }
      try {
        let res = await api.p_saveRegulatoryConstituents(payload)
        if (!res) return
        setProgress(formData, 5)
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {}
    }

    const onSubmitProgress = async (step) => {
      let isMetals = checkOneOf(metals)
      let isVolatiles = checkOneOf(volatileCompounds)
      let isSemiVolatiles = checkOneOf(semiVolatiles)
      let isPestsHerbs = checkOneOf(pesticidesHerbicides)
      let hasErrorMetals = hasRangeUomError(metals)
      let hasErrorVolatiles = hasRangeUomError(volatileCompounds)
      let hasErrorSemiVolatiles = hasRangeUomError(semiVolatiles)
      let hasErrorPesticides = hasRangeUomError(pesticidesHerbicides)
      if (
        (!infoSupplied?.analytical &&
          !infoSupplied?.generatorKnowledge &&
          !infoSupplied?.sds) ||
        hasErrorMetals ||
        hasErrorVolatiles ||
        hasErrorSemiVolatiles ||
        hasErrorPesticides ||
        isMetals ||
        isVolatiles ||
        isSemiVolatiles ||
        isPestsHerbs
      ) {
        return
      }
      const payload = {
        profileRequestId: currentProfile?.profileRequestId || id,
        SaveRegulatedConstituentsDetailRequest: {
          analytical: infoSupplied?.analytical || false,
          generatorKnowledge: infoSupplied?.generatorKnowledge || false,
          sds: infoSupplied?.sds || false,
          metals: metals?.map((val) => {
            return {
              category: "metals",
              id: val?.id || 0,
              name: val?.name || "",
              tclpRegLimit: val?.tclpRegLimit || "",
              notPresent: val?.notPresent || 0,
              belowTclpLimit: val?.belowTclpLimit || 0,
              aboveTclpLimit: val?.aboveTclpLimit || 0,
              range: val?.range || "",
              unitOfMeasure: val?.unitOfMeasure?.v,
            }
          }),
          volatileCompounds: volatileCompounds?.map((val) => {
            return {
              category: "volatile",
              id: val?.id,
              name: val?.name || "",
              tclpRegLimit: val?.tclpRegLimit || "",
              notPresent: val?.notPresent || 0,
              belowTclpLimit: val?.belowTclpLimit || 0,
              aboveTclpLimit: val?.aboveTclpLimit || 0,
              range: val?.range || "",
              unitOfMeasure: val?.unitOfMeasure?.v || "",
            }
          }),
          semiVolatiles: semiVolatiles?.map((val) => {
            return {
              category: "semivolatile",
              id: val?.id,
              name: val?.name || "",
              tclpRegLimit: val?.tclpRegLimit || "",
              notPresent: val?.notPresent || 0,
              belowTclpLimit: val?.belowTclpLimit || 0,
              aboveTclpLimit: val?.aboveTclpLimit || 0,
              range: val?.range || "",
              unitOfMeasure: val?.unitOfMeasure?.v || "",
            }
          }),
          pesticidesHerbicides: pesticidesHerbicides?.map((val) => {
            return {
              category: "pesticides",
              id: val?.id,
              name: val?.name || "",
              tclpRegLimit: val?.tclpRegLimit || "",
              notPresent: val?.notPresent || 0,
              belowTclpLimit: val?.belowTclpLimit || 0,
              aboveTclpLimit: val?.aboveTclpLimit || 0,
              range: val?.range || "",
              unitOfMeasure: val?.unitOfMeasure?.v || "",
            }
          }),
        },
      }
      // let leng =
      //   metals?.length +
      //   volatileCompounds?.length +
      //   semiVolatiles?.length +
      //   pesticidesHerbicides?.length
      let formData = {
        analytical: infoSupplied?.analytical,
        generatorKnowledge: infoSupplied?.generatorKnowledge,
        sds: infoSupplied?.sds,
        metals: metals,
        volatileCompounds: volatileCompounds,
        semiVolatiles: semiVolatiles,
        pesticidesHerbicides: pesticidesHerbicides,
      }
      try {
        let res = await api.p_saveRegulatoryConstituents(payload)
        if (!res) return
        setProgress(formData, 5)
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {}
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }
    const goToProfilePages = (page) => {
      history.push(`/profiles/${page}`)
    }

    const backAction = () => {
      const profileMetals = currentProfile?.profileMetals
        ? JSON.parse(currentProfile?.profileMetals)
        : null

      if (profileMetals) {
        setInfoSupplied({
          ...infoSupplied,
          analytical: profileMetals?.Analytical,
          generatorKnowledge: profileMetals?.GeneratorKnowledge,
          sds: profileMetals?.SDS,
        })
        const mappedMetals = profileMetals?.Metals?.map(transformKeys)
        setMetals(mappedMetals)
        const mappedVolatiles =
          profileMetals?.VolatileCompounds?.map(transformKeys)
        setVolatileCompounds(mappedVolatiles)
        const mappedSemiVolatiles =
          profileMetals?.SemiVolatiles?.map(transformKeys)
        setSemiVolatiles(mappedSemiVolatiles)
        const mappedPestHerbs =
          profileMetals?.PesticidesHerbicides?.map(transformKeys)
        setPesticidesHerbicides(mappedPestHerbs)
      } else {
        if (initialDataFlag) {
          let data = availableRegConstData
          let mData = data?.filter((val) => val.category === "metals")
          let metalsData = mData?.map(transformUoM)
          let vData = data?.filter((val) => val.category === "volatile")
          let volatilesData = vData?.map(transformUoM)
          let svData = data?.filter((val) => val.category === "semivolatile")
          let semiVolatilesData = svData?.map(transformUoM)
          let pData = data?.filter((val) => val.category === "pesticides")
          let pesticidesData = pData?.map(transformUoM)
          setMetals(metalsData)
          setVolatileCompounds(volatilesData)
          setSemiVolatiles(semiVolatilesData)
          setPesticidesHerbicides(pesticidesData)
        }
      }
      dispatch({ type: SET_ACTIVE_STEP, payload: 5 })
    }

    const cancelAction = () => {
      history.push(`/profiles`)
    }

    const handleAlerts = (values, errors, isValid, step) => {
      if (
        !infoSupplied?.analytical &&
        !infoSupplied?.generatorKnowledge &&
        !infoSupplied?.sds
      ) {
        setShowError(true)
        props.setShowAlert(true)
      } else {
        setShowError(false)
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(step)
        props.setShowAlert(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          // docData,
          // updateDocData,
          formik,
          goToProfilePages,
          infoSupplied,
          setInfoSupplied,
          metals,
          setMetals,
          volatileCompounds,
          setVolatileCompounds,
          semiVolatiles,
          setSemiVolatiles,
          pesticidesHerbicides,
          setPesticidesHerbicides,
          uomOpts,
          backAction,
          cancelAction,
          checkOneOf,
          selectAllMetalsFlag,
          setSelectedAllMetalsFlag,
          selectAllVolatilesFlag,
          setSelectedAllVolatilesFlag,
          selectAllSemiVolatilesFlag,
          setSelectedAllSemiVolatilesFlag,
          selectAllPestHerbFlag,
          setSelectedAllPestHerbFlag,
          showError,
          submit,
          setSubmit,
          setShowError,
          error_highlight,
          handleAlerts,
        }}
      />
    )
  }

export default regulatedconstituentsenhancer
