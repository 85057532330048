import React, { useState, useEffect } from "react"
import api from "api"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import {
  CURRENT_PROFILE,
  GET_PROGRESS_STEP,
  SET_ACTIVE_STEP,
  SET_PROGRESS_STEP,
  SET_PROGRESS_STEP_ONLY,
} from "store/reducer/actionType"
import { getBooleanValues, getValues } from "routes/Utilities/util"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import { useHistory } from "react-router-dom"
import { useHarsco } from "shared"

let additionalinfoenhancer =
  (Component) =>
  ({ ...props }) => {
    const { profile } = useHarsco()
    const [showError, setShowError] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const currentProfile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )
    const { id, type } = useParams()

    const initValues = {
      additionalInfoNoneFlag: false,
      ammoniaFlag: false,
      asbestosFriableFlag: false,
      asbestosNonFriableFlag: false,
      aphisWasteFlag: false,
      cerclaFlag: false,
      dioxinsFlag: false,
      dustHazardFlag: false,
      flammableSolidFlag: false,
      medicalSharpsNeedlesFlag: false,
      organicPeroxideFlag: false,
      oxidizerFlag: false,
      pesticideHerbicideFlag: false,
      pfasPfoaFlag: false,
      phenolicsFlag: false,
      polimerizesFlag: false,
      pusoFlag: false,
      rcraDebrisFlag: false,
      subpartPFlag: false,
      noReactivityFlag: false,
      cyanidesReactivityFlag: false,
      sulfidesReactivityFlag: false,
      waterReactivityFlag: false,
      reactiveTempSensFlag: false,
      metalsFlag: false,
      dotExplosiveFlag: false,
      polymerizableFlag: false,
      pyrophoricReactivityFlag: false,
      shockReactivityFlag: false,
      otherReactivity: "",
      selectAllCheck: false,
      selectAllReactive: false,
    }
    const goToProfilePages = (page) => {
      history.push(`/profiles/${page}`)
    }
    const error_highlight = useSelector(
      (state) => state.profileDetails.errorHighlight
    )
    const [initialValues, setInitialValues] = useState(initValues)
    let check_all = [
      { id: "additionalInfoNoneFlag", value: "none" },
      { id: "ammoniaFlag", value: "ammonia" },
      { id: "asbestosFriableFlag", value: "asbestosFriable" },
      { id: "asbestosNonFriableFlag", value: "asbestosNonFriable" },
      { id: "aphisWasteFlag", value: "APHISWaste" },
      { id: "cerclaFlag", value: "CERCLA" },
      { id: "dioxinsFlag", value: "dioxins" },
      { id: "dustHazardFlag", value: "dusthazard" },
      { id: "flammableSolidFlag", value: "flammableSolid" },
      { id: "medicalSharpsNeedlesFlag", value: "medicalSharpsNeedles" },
      { id: "organicPeroxideFlag", value: "organicperoxide" },
      { id: "oxidizerFlag", value: "oxidizer" },
      { id: "pesticideHerbicideFlag", value: "pestherb" },
      { id: "pfasPfoaFlag", value: "pfaspfoa" },
      { id: "phenolicsFlag", value: "polimerizes" },
      { id: "polimerizesFlag", value: "phenolics" },
      { id: "pusoFlag", value: "puso" },
      { id: "rcraDebrisFlag", value: "rcra" },
      { id: "subpartPFlag", value: "subpart" },
    ]
    let reactive_check = [
      { id: "noReactivityFlag", value: "noreactivity" },
      { id: "cyanidesReactivityFlag", value: "cyanide" },
      { id: "sulfidesReactivityFlag", value: "sulphide" },
      { id: "waterReactivityFlag", value: "water" },
      { id: "reactiveTempSensFlag", value: "other" },
      { id: "metalsFlag", value: "metals" },
      { id: "dotExplosiveFlag", value: "explosive" },
      // { id: "polymerizableFlag", value: "polymerize" },
      { id: "pyrophoricReactivityFlag", value: "pyrophoric" },
      { id: "shockReactivityFlag", value: "shock" },
    ]
    let valSchema = {
      additionalInfoNoneFlag: Yup.boolean(),
      ammoniaFlag: Yup.boolean(),
      asbestosFriableFlag: Yup.boolean(),
      asbestosNonFriableFlag: Yup.boolean(),
      aphisWasteFlag: Yup.boolean(),
      cerclaFlag: Yup.boolean(),
      dioxinsFlag: Yup.boolean(),
      dustHazardFlag: Yup.boolean(),
      flammableSolidFlag: Yup.boolean(),
      medicalSharpsNeedlesFlag: Yup.boolean(),
      organicPeroxideFlag: Yup.boolean(),
      oxidizerFlag: Yup.boolean(),
      pesticideHerbicideFlag: Yup.boolean(),
      pfasPfoaFlag: Yup.boolean(),
      phenolicsFlag: Yup.boolean(),
      polimerizesFlag: Yup.boolean(),
      pusoFlag: Yup.boolean(),
      rcraDebrisFlag: Yup.boolean(),
      subpartPFlag: Yup.boolean(),
      noReactivityFlag: Yup.boolean(),
      cyanidesReactivityFlag: Yup.boolean(),
      sulfidesReactivityFlag: Yup.boolean(),
      waterReactivityFlag: Yup.boolean(),
      reactiveTempSensFlag: Yup.boolean(),
      metalsFlag: Yup.boolean(),
      dotExplosiveFlag: Yup.boolean(),
      polymerizableFlag: Yup.boolean(),
      pyrophoricReactivityFlag: Yup.boolean(),
      shockReactivityFlag: Yup.boolean(),
      otherReactivity: Yup.string(),
    }

    const checkOneof = (fields, values) => {
      const fieldsUpdated = fields.map((val) => val.id)

      return !fieldsUpdated?.some((val) => values[val] === true)
    }

    const backAction = () => {
      dispatch({ type: SET_ACTIVE_STEP, payload: 3 })
    }
    const getProfileData = async (reqId) => {
      const req = {
        profileRequestId: id || reqId,
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const setProgress = (data, length) => {
      let count = 0
      for (var index in check_all) {
        let prop = check_all[index]?.id || ""
        if (data[prop]) {
          count = count + 1
          break
        }
      }
      for (var ind in reactive_check) {
        let prop = reactive_check[ind]?.id || ""
        if (data["otherReactivity"]) {
          count = count + 1
          break
        } else {
          if (data[prop]) {
            count = count + 1
            break
          }
        }
      }
      dispatch({
        type: SET_PROGRESS_STEP,
        step: 4,
        progressStep: props.showAlert ? props.seekingStep + 1 : 5,
        percentage: (count / length) * 100,
        numerator: count,
        denominator: length,
      })
    }

    const onSubmit = async (formData) => {
      if (
        checkOneof(check_all, formData) ||
        (checkOneof(reactive_check, formData) &&
          formData?.otherReactivity?.trim()?.length === 0)
      ) {
        return
      }
      props.setShowAlert(false)
      const payload = {
        roleId: 0,
        username: profile?.email,
        profileRequestId: currentProfile?.profileRequestId || id,
        additionalInfoNoneFlag: formData?.additionalInfoNoneFlag,
        ammoniaFlag: formData?.ammoniaFlag,
        asbestosFriableFlag: formData?.asbestosFriableFlag,
        asbestosNonFriableFlag: formData?.asbestosNonFriableFlag,
        aphisWasteFlag: formData?.aphisWasteFlag,
        cerclaFlag: formData?.cerclaFlag,
        dioxinsFlag: formData?.dioxinsFlag ? 1 : 0,
        dustHazardFlag: formData?.dustHazardFlag,
        flammableSolidFlag: formData?.flammableSolidFlag,
        medicalSharpsNeedlesFlag: formData?.medicalSharpsNeedlesFlag,
        organicPeroxideFlag: formData?.organicPeroxideFlag,
        oxidizerFlag: formData?.oxidizerFlag,
        pesticideHerbicideFlag: formData?.pesticideHerbicideFlag,
        pfasPfoaFlag: formData?.pfasPfoaFlag,
        phenolicsFlag: formData?.phenolicsFlag ? 1 : 0,
        polimerizesFlag: formData?.polimerizesFlag,
        pusoFlag: formData?.pusoFlag,
        rcraDebrisFlag: formData?.rcraDebrisFlag,
        subpartPFlag: formData?.subpartPFlag,
        noReactivityFlag: formData?.noReactivityFlag ? 1 : 0,
        cyanidesReactivityFlag: formData?.cyanidesReactivityFlag ? 1 : 0,
        sulfidesReactivityFlag: formData?.sulfidesReactivityFlag ? 1 : 0,
        waterReactivityFlag: formData?.waterReactivityFlag ? 1 : 0,
        reactiveTempSensFlag: formData?.reactiveTempSensFlag,
        metalsFlag: formData?.metalsFlag,
        dotExplosiveFlag: formData?.dotExplosiveFlag ? 1 : 0,
        polymerizableFlag: formData?.polymerizableFlag ? 1 : 0,
        pyrophoricReactivityFlag: formData?.pyrophoricReactivityFlag ? 1 : 0,
        shockReactivityFlag: formData?.shockReactivityFlag ? 1 : 0,
        otherReactivity: formData?.otherReactivity,
      }

      try {
        let res = await api.p_saveAdditionalInfo(payload)
        if (!res) return
        setProgress(formData, 2)
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {
        // dispatch({
        //   type: "SET_CHANGE_CUSTOMER",
        //   status: false,
        //   customer_name: changeCustomer?.customer,
        //   source: false,
        // })
      }
    }

    const onSubmitProgress = async (formData, step) => {
      if (
        checkOneof(check_all, formData) ||
        (checkOneof(reactive_check, formData) &&
          formData?.otherReactivity?.trim()?.length === 0)
      ) {
        return
      }
      const payload = {
        roleId: 0,
        username: profile?.email,
        profileRequestId: currentProfile?.profileRequestId || id,
        additionalInfoNoneFlag: formData?.additionalInfoNoneFlag,
        ammoniaFlag: formData?.ammoniaFlag,
        asbestosFriableFlag: formData?.asbestosFriableFlag,
        asbestosNonFriableFlag: formData?.asbestosNonFriableFlag,
        aphisWasteFlag: formData?.aphisWasteFlag,
        cerclaFlag: formData?.cerclaFlag,
        dioxinsFlag: formData?.dioxinsFlag ? 1 : 0,
        dustHazardFlag: formData?.dustHazardFlag,
        flammableSolidFlag: formData?.flammableSolidFlag,
        medicalSharpsNeedlesFlag: formData?.medicalSharpsNeedlesFlag,
        organicPeroxideFlag: formData?.organicPeroxideFlag,
        oxidizerFlag: formData?.oxidizerFlag,
        pesticideHerbicideFlag: formData?.pesticideHerbicideFlag,
        pfasPfoaFlag: formData?.pfasPfoaFlag,
        phenolicsFlag: formData?.phenolicsFlag ? 1 : 0,
        polimerizesFlag: formData?.polimerizesFlag,
        pusoFlag: formData?.pusoFlag,
        rcraDebrisFlag: formData?.rcraDebrisFlag,
        subpartPFlag: formData?.subpartPFlag,
        noReactivityFlag: formData?.noReactivityFlag ? 1 : 0,
        cyanidesReactivityFlag: formData?.cyanidesReactivityFlag ? 1 : 0,
        sulfidesReactivityFlag: formData?.sulfidesReactivityFlag ? 1 : 0,
        waterReactivityFlag: formData?.waterReactivityFlag ? 1 : 0,
        reactiveTempSensFlag: formData?.reactiveTempSensFlag,
        metalsFlag: formData?.metalsFlag,
        dotExplosiveFlag: formData?.dotExplosiveFlag ? 1 : 0,
        polymerizableFlag: formData?.polymerizableFlag ? 1 : 0,
        pyrophoricReactivityFlag: formData?.pyrophoricReactivityFlag ? 1 : 0,
        shockReactivityFlag: formData?.shockReactivityFlag ? 1 : 0,
        otherReactivity: formData?.otherReactivity,
      }

      try {
        let res = await api.p_saveAdditionalInfo(payload)
        if (!res) return
        getProfileData(currentProfile?.profileRequestId)
        setProgress(formData, 2)
      } catch (err) {}
    }

    const calcProgress = async (count, leng) => {
      await dispatch({
        type: GET_PROGRESS_STEP,
        step: 4,
        progressStep: 4,
        percentage: (count / leng) * 100,
        numerator: count,
        denominator: leng,
      })
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }

    const allReactivityFlags = (data) => {
      const filteredCheck = reactive_check.filter(
        (item) => item.id !== "noReactivityFlag"
      )
      return filteredCheck.every((item) => data[item.id] === true)
    }

    const allCheckFlags = (data) => {
      const filteredCheck = check_all.filter(
        (item) => item.id !== "additionalInfoNoneFlag"
      )
      return filteredCheck.every((item) => data[item.id] === true)
    }

    useEffect(() => {
      let data = {}
      if (currentProfile?.generatorId || type == "viewprofile") {
        let check_allCount = 0
        let reactive_count = 0
        for (var index in check_all) {
          let prop = check_all[index]?.id || ""
          data[prop] = getBooleanValues(
            currentProfile,
            prop,
            initValues?.[prop]
          )
          if (data[prop]) {
            check_allCount = check_allCount + 1
          }
        }
        for (var i in reactive_check) {
          data["otherReactivity"] = getValues(
            currentProfile,
            "otherReactivity",
            initValues?.["otherReactivity"]
          )
          if (data["otherReactivity"]) {
            reactive_count = reactive_count + 1
            break
          }
          let prop = reactive_check[i]?.id || ""
          data[prop] = getBooleanValues(
            currentProfile,
            prop,
            initValues?.[prop]
          )
          if (data[prop]) {
            reactive_count = reactive_count + 1
          }
        }
        let reactive = allReactivityFlags(data)
        let allcheck = allCheckFlags(data)
        data.selectAllReactive = reactive
        data.selectAllCheck = allcheck
        setInitialValues(data)
        let count = check_allCount
          ? reactive_count
            ? 2
            : 1
          : reactive_count
          ? 1
          : 0
        calcProgress(count, 2)
      }
    }, [currentProfile?.generatorId])

    const cancelAction = () => {
      history.push(`/profiles`)
    }

    const handleAlerts = (values, errors, isValid, step) => {
      if (
        (Object.keys(errors)?.length > 0 && !isValid) ||
        checkOneof(check_all, values) ||
        (checkOneof(reactive_check, values) &&
          values?.otherReactivity?.trim()?.length === 0)
      ) {
        setShowError(true)
        props.setShowAlert(true)
      } else {
        setShowError(false)
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(values, step)
        props.setShowAlert(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formik,
          backAction,
          reactive_check,
          goToProfilePages,
          check_all,
          cancelAction,
          showError,
          setShowError,
          handleAlerts,
          error_highlight,
        }}
      />
    )
  }

export default additionalinfoenhancer
