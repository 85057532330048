import React, { useState, useEffect } from "react"
import { MultiSelect } from "react-multi-select-component"
//1. select all option - select all multiselect option
//2. unselect all option - unselect all multiselect options
//3.
const MultiSelectComponent = ({
  options = [{ label: "", value: "" }],
  name = "",
  isAllReqd = false,
  disableSearch = true,
  isOpen,
  className = "",
  selected = [],
  handleOnChange,
}) => {
  const [isSelected, setIsSelected] = useState(false)
  const [filteredOptions, setFilteredOptions] = useState(options) // Manage filtered options
  const [searchFilter, setSearchFilter] = useState("") // Search filter value
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    setIsSelected(selected.length > 0)
  }, [selected])

  useEffect(() => {
    // Update filtered options only when searchFilter or options change
    if (searchFilter) {
      const newFilteredOptions = options.filter(({ label }) =>
        label.toLowerCase().includes(searchFilter.toLowerCase())
      )
      setTimeout(() => {
        const items = document.querySelectorAll(
          ".mni-multiselect .item-renderer"
        )
        items.forEach((item, index) => {
          const option = newFilteredOptions[index - 1] // Adjust for "Select All"
          item.title = option ? option.label : "Select All"
        })
      }, 0)
      setFilteredOptions(newFilteredOptions)
    } else {
      setFilteredOptions(options)
    }
  }, [searchFilter, options])

  const onHandleChange = (selectedItems) => {
    handleOnChange(selectedItems)
  }

  const clearAllSelections = (e) => {
    e.stopPropagation()
    e.preventDefault()
    onHandleChange([])
  }

  const customFilterOptions = (options, filter) => {
    setSearchFilter(filter) // Update search filter state
    return options // Return options without triggering an update loop
  }

  const handleMenuToggle = (isOpen) => {
    setIsDropdownOpen(isOpen)
    if (isOpen) {
      // Dynamically update hover titles after dropdown opens
      setTimeout(() => {
        const items = document.querySelectorAll(
          ".mni-multiselect .item-renderer"
        )
        items.forEach((item, index) => {
          const option = filteredOptions[index - 1] // Adjust for "Select All"
          item.title = option ? option.label : "Select All"
        })
      }, 0)
    } else {
      setSearchFilter("") // Clear search filter on close
    }
  }

  return (
    <div
      className={`multi-select-wrapper mni-multiselect ${
        isSelected ? "selected" : ""
      }`}
    >
      <MultiSelect
        options={
          isAllReqd
            ? filteredOptions
            : options?.length > 0
            ? options
            : [{ label: "", value: "" }]
        }
        value={selected}
        onChange={onHandleChange}
        name={name}
        className={className}
        hasSelectAll={isAllReqd}
        isOpen={isOpen}
        disableSearch={disableSearch}
        filterOptions={customFilterOptions} // Use custom filter
        onMenuToggle={handleMenuToggle} // Handle menu toggle
        overrideStrings={{
          selectAll: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Select All</span>
              <span
                onClick={clearAllSelections}
                style={{
                  cursor: "pointer",
                  color: "#000000",
                  marginLeft: "120px",
                  textDecoration: "underline",
                }}
              >
                Clear All
              </span>
            </div>
          ),
        }}
        valueRenderer={(selected) => {
          if (!selected || selected.length === 0) {
            return "Select..."
          }
          if (selected.length === options.length) {
            return "All items are selected."
          }
          // return selected.map((item) => (
          //   <span
          //     key={item.value}
          //     title={selected.map((item) => item.label).join(", ")}
          //     style={{ marginRight: "5px" }}
          //   >
          //     {item.label}
          //   </span>
          // ))
          const firstItem = selected[0].label
          const remainingCount = selected.length - 1
          const title =
            remainingCount > 0
              ? `${firstItem}, ${remainingCount}+ more`
              : firstItem

          return (
            <span title={title} style={{ marginRight: "5px" }}>
              {selected.map((item) => (
                <span key={item.value} style={{ marginRight: "5px" }}>
                  {item.label}
                </span>
              ))}
            </span>
          )
        }}
      />
      <div
        className={`dropdown-heading-dropdown-arrow ${
          isDropdownOpen ? "open" : ""
        }`}
      >
        <svg
          width="18"
          height="22"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M 5 8 L 15 8 L 10 13 Z" fill="#6fb744" />
        </svg>
      </div>
    </div>
  )
}

export default MultiSelectComponent
