import React, { useEffect, useState } from "react"
import api from "api"
import {
  CURRENT_PROFILE,
  GET_PROGRESS_STEP,
  SET_ACTIVE_STEP,
  SET_PROGRESS_STEP,
  SET_PROGRESS_STEP_ONLY,
  AVAILABLE_DOT_CONST,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import {
  getBooleanValues,
  getObjectValues,
  getValues,
  getOptions,
} from "routes/Utilities/util"
import { useHistory } from "react-router-dom"

let shippinginfoenhancer =
  (Component) =>
  ({ ...props }) => {
    const history = useHistory()
    const [showError, setShowError] = useState(false)
    const availbleDots = useSelector(
      (state) => state.profileDetails?.availableDotConst
    )
    const error_highlight = useSelector(
      (state) => state.profileDetails.errorHighlight
    )
    const initValues = {
      bulkLiquidFlag: false,
      bulkSolidFlag: false,
      containerTypesizeFlag: false,
      containerTypesize: "",
      containerTypeId: { l: "Select container type", v: "", id: null },
      frequencyId: { l: "Select", v: "", id: null },
      quantity: "",
      searchAnywhereFlag: false,
      dotId: { l: "Type a Shipping Name", v: "" },
      hazardousConstituents: "",
      rq: false,
      limitedQty: false,
      rqDescription: "",
      shipmentsOverSeaFlashpoint: "",
      marinePollutants: false,
      specialPermitsCaLetter: "",
    }
    const initErrors = {
      bulkLiquidFlag: false,
      bulkSolidFlag: false,
      containerTypesizeFlag: false,
      containerTypesize: false,
      containerTypeId: false,
      frequencyId: false,
      quantity: false,
      searchAnywhereFlag: false,
      dotId: false,
      hazardousConstituents: false,
      rq: false,
      limitedQty: false,
      rqDescription: false,
      shipmentsOverSeaFlashpoint: false,
      marinePollutants: false,
      specialPermitsCaLetter: false,
    }
    const [initialValues, setInitialValues] = useState(initValues)
    const [errorFields, setErrorFields] = useState(initErrors)
    let containerTypeOpts = {
      id: 1,
      options: [
        {
          l: "BA",
          v: "BA",
          id: 5,
        },
        {
          l: "CF",
          v: "CF",
          id: 6,
        },
        {
          l: "CM",
          v: "CM",
          id: 3,
        },
        {
          l: "CW",
          v: "CW",
          id: 7,
        },
        {
          l: "CY",
          v: "CY",
          id: 8,
        },
        {
          l: "DF",
          v: "DF",
          id: 2,
        },
        {
          l: "DM",
          v: "DM",
          id: 1,
        },
        {
          l: "DT",
          v: "DT",
          id: 9,
        },
        {
          l: "DW",
          v: "DW",
          id: 10,
        },
        {
          l: "HG",
          v: "HG",
          id: 12,
        },
        {
          l: "TC",
          v: "TC",
          id: 11,
        },
        {
          l: "TP",
          v: "TP",
          id: 13,
        },
        {
          l: "TT",
          v: "TT",
          id: 4,
        },
      ],
    }
    let frequencyOpts = {
      id: 2,
      options: [
        {
          l: "1 Time",
          v: "onetime",
          id: 1,
        },
        {
          l: "Daily",
          v: "daily",
          id: 2,
        },
        {
          l: "Weekly",
          v: "weekly",
          id: 3,
        },
        {
          l: "Monthly",
          v: "monthly",
          id: 4,
        },
        {
          l: "Quarterly",
          v: "quarterly",
          id: 5,
        },
        {
          l: "Yearly",
          v: "yearly",
          id: 6,
        },
        {
          l: "As Needed",
          v: "asneeded",
          id: 7,
        },
      ],
    }
    let filteredDotName =
      availbleDots && availbleDots.length !== 0
        ? availbleDots.filter(
            (data) => data.dotId !== 3740 && data.dotId !== 5039
          )
        : []
    let dotOptionNames = getOptions(filteredDotName, "dropDownText", "dotId")
    let dotOpts = {
      id: 3,
      options: [
        {
          l: "1,1,1,2-Tetrafluoroethane, UN3159 2,2",
          v: "tfe",
          id: 1,
        },
        {
          l: "1,1,1 Trichloroethane, solution, UN2831 6.1, PG-III",
          v: "tceSol",
          id: 2,
        },
        {
          l: "1,1,1-Trichloroethane, UN2831 6.1, PG-III",
          v: "tce",
          id: 3,
        },
      ],
    }
    const { id, type } = useParams()
    const dispatch = useDispatch()
    const currentProfile = useSelector(
      (state) => state.profileDetails?.currentProfile
    )

    const getAllDotNames = async () => {
      try {
        const req = {
          profileID: "0",
        }
        let res = await api.p_getAllDotNames(req)
        if (!res) return
        dispatch({ type: AVAILABLE_DOT_CONST, payload: res })
      } catch (error) {}
    }

    useEffect(() => {
      getAllDotNames()
    }, [])

    let valSchema = {
      bulkLiquidFlag: Yup.boolean(),
      bulkSolidFlag: Yup.boolean(),
      containerTypesizeFlag: Yup.boolean(),
      containerTypesize: Yup.string().when("containerTypesizeFlag", {
        is: (containerTypesizeFlag) => (containerTypesizeFlag ? true : false),
        then: Yup.string().required("Container type size is required"),
        otherwise: Yup.string(),
      }),
      containerTypeId: Yup.object().shape({
        l: Yup.string(),
        v: Yup.string(),
        id: Yup.number().nullable().required("Container type is required"),
      }),
      frequencyId: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
          id: Yup.number().nullable().required("Frequency type is required"),
        })
        .nullable()
        .required("Frequency type is required"),
      quantity: Yup.string().required("Quantity is required"),
      searchAnywhereFlag: Yup.boolean(),
      dotId: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number().nullable().required("Dot Name is required"),
        })
        .nullable()
        .required("Dot Name is required"),
      hazardousConstituents: Yup.string(),
      rq: Yup.boolean(),
      limitedQty: Yup.boolean(),
      rqDescription: Yup.string(),
      shipmentsOverSeaFlashpoint: Yup.string().nullable(),
      // .matches(/^\d+$/, "Flashpoint is not valid"),
      marinePollutants: Yup.boolean(),
      specialPermitsCaLetter: Yup.string(),
    }
    const getProfileData = async (reqId) => {
      const req = {
        profileRequestId: id || reqId,
      }
      try {
        let res = await api.p_getProfileById(req)
        if (!res) return
        dispatch({ type: CURRENT_PROFILE, payload: res })
      } catch (error) {}
    }

    const onSubmit = async (formData) => {
      props.setShowAlert(false)
      let required_length = 6
      if (formData?.containerTypesizeFlag) required_length = required_length + 1
      const payload = {
        ...formData,
        profileRequestId: currentProfile?.profileRequestId || id,
        containerTypeId: formData?.containerTypeId?.id || 0,
        shipmentsOverSeaFlashpoint:
          formData?.shipmentsOverSeaFlashpoint || null,
        containerTypesizeFlag: formData?.containerTypesizeFlag,
        containerTypesize: `${formData?.containerTypesize}`,
        // formData?.containerTypesizeFlag === false
        //   ? ""
        //   : formData?.containerTypesize,
        frequencyId: formData?.frequencyId?.id || 0,
        dotId: formData?.dotId?.v || 0,
      }

      try {
        let res = await api.p_saveShipmentInformation(payload)
        if (!res) return
        let setProgress_count = setProgress(formData, required_length)
        dispatch({
          type: SET_PROGRESS_STEP,
          step: 7,
          progressStep: props.showAlert ? props.seekingStep + 1 : 8,
          percentage: (setProgress_count / required_length) * 100,
          numerator: setProgress_count,
          denominator: required_length,
        })
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {
        // dispatch({
        //   type: "SET_CHANGE_CUSTOMER",
        //   status: false,
        //   customer_name: changeCustomer?.customer,
        //   source: false,
        // })
      }
    }

    const onSubmitProgress = async (formData, step) => {
      props.setShowAlert(false)
      let required_length = 6
      const payload = {
        ...formData,
        profileRequestId: currentProfile?.profileRequestId || id,
        containerTypeId: formData?.containerTypeId?.id || 0,
        containerTypesizeFlag: formData?.containerTypesizeFlag,
        containerTypesize: `${formData?.containerTypesize}`,
        frequencyId: formData?.frequencyId?.id || 0,
        dotId: formData?.dotId?.v || 0,
      }
      if (formData?.containerTypesizeFlag) required_length = required_length + 1
      try {
        let res = await api.p_saveShipmentInformation(payload)
        if (!res) return
        let setProgressCount = setProgress(formData, required_length)
        dispatch({
          type: SET_PROGRESS_STEP,
          step: 7,
          progressStep: step,
          percentage: (setProgressCount / required_length) * 100,
          numerator: setProgressCount,
          denominator: required_length,
        })
        getProfileData(currentProfile?.profileRequestId)
      } catch (err) {}
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }

    const goToProfilePages = (page) => {
      history.push(`/profiles/${page}`)
    }

    const getCodeOptions = (keys) => {
      switch (keys) {
        case "containerTypeId":
          return containerTypeOpts?.options
        case "frequencyId":
          return frequencyOpts?.options
        case "dotId":
          return dotOptionNames
        default:
          return []
      }
    }

    const farenheitToCelcius = (fahrenheit) => {
      let c = ((fahrenheit - 32) * 5) / 9
      return c.toFixed(2)
    }

    const setProgress = (data, length) => {
      let count = 0
      let non_requiredcount = 0
      for (var prop in initValues) {
        if (
          prop == "containerTypeId" ||
          prop == "frequencyId" ||
          prop == "dotId"
        ) {
          if (data[prop]?.v) {
            count = count + 1
          }
        } else if (prop == "quantity") {
          if (data[prop]) count = count + 1
        } else if (prop == "containerTypesize") {
          if (data["containerTypesizeFlag"] && data[prop]) count = count + 1
        } else if (prop == "bulkSolidFlag" || prop == "bulkLiquidFlag") {
          if (data[prop] === false || data[prop] === true) count = count + 1
        } else {
          if (data[prop]) {
            non_requiredcount = non_requiredcount + 1
          }
        }
      }
      return count
    }

    const calcProgress = async (count, leng) => {
      await dispatch({
        type: GET_PROGRESS_STEP,
        step: 7,
        progressStep: 7,
        percentage: (count / leng) * 100,
        numerator: count,
        denominator: leng,
      })
    }

    const customValidate = () => {
      let data = {}
      let errorsData = {}
      if (error_highlight?.["7"]?.errorFlag) {
        let sizeFlag = getBooleanValues(
          currentProfile,
          "containerTypesizeFlag",
          initValues?.["containerTypesizeFlag"]
        )
        for (var val in initValues) {
          if (
            val == "containerTypesizeFlag" ||
            val == "searchAnywhereFlag" ||
            val == "rq" ||
            val == "limitedQty" ||
            val == "marinePollutants"
          ) {
            data[val] = getBooleanValues(currentProfile, val, initValues?.[val])
            if (data[val]) {
              errorsData[val] = false
            }
          } else if (val == "bulkLiquidFlag" || val == "bulkSolidFlag") {
            data[val] = getBooleanValues(currentProfile, val, initValues?.[val])
            if (data[val] === true || data[val] === false) {
              errorsData[val] = false
            } else errorsData[val] = true
          } else if (val == "containerTypeId" || val == "frequencyId") {
            data[val] = getObjectValues(
              getCodeOptions(val),
              currentProfile,
              val,
              initValues?.[val],
              "id"
            )
            if (data[val]?.v) {
              errorsData[val] = false
            } else errorsData[val] = true
          } else if (val == "dotId") {
            if (currentProfile?.dotId) {
              data[val] = getObjectValues(
                getCodeOptions(val),
                currentProfile,
                val,
                initValues?.[val]
              )
            } else if (currentProfile?.dotNames && !currentProfile?.dotId) {
              data[val] = getCodeOptions(val)?.find(
                (value) =>
                  value?.dotId ==
                  JSON.parse(currentProfile?.dotNames)?.[0]?.dot_id
              )
              // getObjectValues(
              //   getCodeOptions(prop),
              //   currentProfile,
              //   prop,
              //   JSON.parse(currentProfile?.dotNames)?.[0]?.dot_id,
              // )
            } else {
              data[val] = getObjectValues(
                getCodeOptions(val),
                currentProfile,
                val,
                initValues?.[val]
              )
            }
            if (data[val]?.v) errorsData[val] = false
            else errorsData[val] = true
          } else if (val == "containerTypesize") {
            data[val] = getValues(currentProfile, val, initValues?.[val])
            if (sizeFlag && data[val]) {
              errorsData[val] = false
            } else errorsData[val] = true
          } else if (val == "quantity") {
            data[val] = getValues(currentProfile, val, initValues?.[val])
            if (data[val]) {
              errorsData[val] = false
            } else errorsData[val] = true
          } else if (val === "shipmentsOverSeaFlashpoint") {
            let fpScale = currentProfile?.flashpointScale
            let fpActual = currentProfile?.flashpointActual
            let fpFlag = currentProfile?.flashPointFlag
            if (fpFlag)
              data[val] =
                fpScale === "F" ? farenheitToCelcius(fpActual) : fpActual
          } else {
            data[val] = getValues(currentProfile, val, initValues?.[val])
            if (data[val]) {
              errorsData[val] = false
            }
          }
        }
        setErrorFields(errorsData)
      }
    }

    useEffect(() => {
      customValidate()
      if (type !== "viewprofile") {
        getProfileData(currentProfile?.profileRequestId)
      }
      //getProfileData(currentProfile?.profileRequestId)s
    }, [error_highlight])

    useEffect(() => {
      let data = {}
      if (currentProfile?.generatorId || type == "viewprofile") {
        let count = 0
        let non_requiredcount = 0
        let sizeFlag = getBooleanValues(
          currentProfile,
          "containerTypesizeFlag",
          initValues?.["containerTypesizeFlag"]
        )
        let required_length = 6
        if (sizeFlag) required_length = required_length + 1
        for (var prop in initValues) {
          if (
            prop == "containerTypesizeFlag" ||
            prop == "searchAnywhereFlag" ||
            prop == "rq" ||
            prop == "limitedQty" ||
            prop == "marinePollutants"
          ) {
            data[prop] = getBooleanValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          } else if (prop == "bulkLiquidFlag" || prop == "bulkSolidFlag") {
            data[prop] = getBooleanValues(
              currentProfile,
              prop,
              initValues?.[prop]
            )
            if (data[prop] === true || data[prop] === false) {
              count = count + 1
            }
          } else if (prop == "containerTypeId" || prop == "frequencyId") {
            data[prop] = getObjectValues(
              getCodeOptions(prop),
              currentProfile,
              prop,
              initValues?.[prop],
              "id"
            )
            if (data[prop]?.v) {
              count = count + 1
            }
          } else if (prop == "dotId") {
            if (currentProfile?.dotId) {
              data[prop] = getObjectValues(
                getCodeOptions(prop),
                currentProfile,
                prop,
                initValues?.[prop]
              )
            } else if (currentProfile?.dotNames && !currentProfile?.dotId) {
              data[prop] = getCodeOptions(prop)?.find(
                (val) =>
                  val?.dotId ==
                  JSON.parse(currentProfile?.dotNames)?.[0]?.dot_id
              )
              // getObjectValues(
              //   getCodeOptions(prop),
              //   currentProfile,
              //   prop,
              //   JSON.parse(currentProfile?.dotNames)?.[0]?.dot_id,
              // )
            } else {
              data[prop] = getObjectValues(
                getCodeOptions(prop),
                currentProfile,
                prop,
                initValues?.[prop]
              )
            }
            if (data[prop]?.v) count = count + 1
          } else if (prop == "containerTypesize") {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (sizeFlag && data[prop]) {
              count = count + 1
            }
          } else if (prop == "quantity") {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) {
              count = count + 1
            }
          } else if (prop === "shipmentsOverSeaFlashpoint") {
            let fpScale = currentProfile?.flashpointScale
            let fpActual = currentProfile?.flashpointActual
            let fpFlag = currentProfile?.flashPointFlag
            if (fpFlag)
              data[prop] =
                fpScale === "F" ? farenheitToCelcius(fpActual) : fpActual
          } else {
            data[prop] = getValues(currentProfile, prop, initValues?.[prop])
            if (data[prop]) {
              non_requiredcount = non_requiredcount + 1
            }
          }
        }
        calcProgress(count, required_length)
        setInitialValues(data)
      }
    }, [currentProfile?.generatorId])

    const backAction = () => {
      dispatch({ type: SET_ACTIVE_STEP, payload: 6 })
    }

    const cancelAction = () => {
      history.push(`/profiles`)
    }

    const handleAlerts = (values, errors, isValid, step) => {
      if (Object.keys(errors)?.length > 0 && !isValid) {
        setShowError(true)
        props.setShowAlert(true)
      } else {
        setShowError(false)
        dispatch({ type: SET_PROGRESS_STEP_ONLY, progressStep: step + 1 })
        onSubmitProgress(values, step)
        props.setShowAlert(false)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formik,
          goToProfilePages,
          containerTypeOpts,
          frequencyOpts,
          dotOpts,
          dotOptionNames,
          backAction,
          cancelAction,
          showError,
          setShowError,
          handleAlerts,
          error_highlight,
          errorFields,
        }}
      />
    )
  }

export default shippinginfoenhancer
