import React, { useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import api from "api"
import {
  ALL_GENERATORS,
  ALL_ER_SERVICE_NAMES,
  PHONE_NUMBER_BY_PROVIDER_NAME,
  GENERATOR_ID_STATUS,
  NAICS_CODE,
  SHIP_COUNTRY_LIST,
  SHIP_STATE_LIST,
  PROFILE_CRETAE_FROM_GENE,
  CURRENT_PROFILE,
  SET_NEW_PROGRESS_STEP,
  SET_PROGRESS_STEP,
  GENERATOR_DETAILS,
  SET_NAICS_CDDETAILS,
} from "store/reducer/actionType"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { getOptions } from "routes/Utilities/util"
// import { useHarsco } from "shared"
import { toast } from "react-toastify"
// import { useParams } from "react-router-dom/cjs/react-router-dom.min"

let enhancer =
  (Component) =>
  ({ history, ...props }) => {
    const [TSDFFile, setTSDFFile] = useState(null)
    const [erNameValue, setERNameValue] = useState("")
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [shippingError, setShippingError] = useState(false)
    const [manifestError, setManifestError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [hasNoErrors, setHasNoErrors] = useState(true)
    const [files, setFile] = useState(null)
    const firstTimeFetch = useRef(true)
    const setFiles = (f) => {
      setFile(f)
    }
    const useQuery = () => {
      return new URLSearchParams(useLocation().search)
    }
    const query = useQuery()
    const generatorFrom = query.get("from")
    const profileId = query.get("id")
    // const {from, id} = useParams()
    const shipCountryCodes = useSelector(
      (state) => state.generatorDetails?.shipCountryList
    )
    const genCountries = getOptions(
      shipCountryCodes,
      "country",
      "countryTerritoryCode"
    )

    const shipStateCodes = useSelector(
      (state) => state.generatorDetails?.shipStateList
    )
    const changeCustomer = useSelector((state) => state.changeCustomer)
    const shipStateOpts = getOptions(shipStateCodes, "state", "stateCode")

    const [shipStates, setShipStates] = useState([])
    const [manifestStates, setManifestStates] = useState([])
    const [createdGenId, setCreatedGenId] = useState("")
    const [showAddressModal, setShowAddressModal] = useState(false)
    const [recShippingAddr, setRecShippingAddr] = useState({
      city: "",
      state: "",
      streetAddress: "",
      zipCode: "",
    })
    const [recManifestAddr, setRecManifestAddr] = useState({
      city: "",
      state: "",
      streetAddress: "",
      zipCode: "",
    })
    const [recManifestError, setRecManifestError] = useState("")
    const [recShipError, setRecShipError] = useState("")
    const [enterAddress, setEnterAddress] = useState(true)
    const [enterManifestAddress, setEnterManifestAddress] = useState(true)
    const [addrLoader, setAddrLoader] = useState(false)
    const [createdGenIDProfile, setCreatedGenIDProfile] = useState("")
    const firstInputRef = useRef(null)
    const [showDupGenModal, setShowDupGenModal] = useState(false)

    const ERNameCodes = useSelector(
      (state) => state.generatorDetails?.allErServiceNames
    )

    const knowyourcsr = useSelector(
      (state) => state.generatorDetails?.knowyourcsr
    )

    let ERNameOpts = getOptions(ERNameCodes, "name", "erServiceProviderId")

    const genIdStatusCodes = useSelector(
      (state) => state.generatorDetails?.generatorIdStatus
    )

    let genIdStatusOpts = getOptions(
      genIdStatusCodes,
      "generatorIdStatusValue",
      "generatorIdStatus"
    )

    const naicsCodes = useSelector((state) => state.generatorDetails?.naicsCode)

    let naicsOpts = getOptions(naicsCodes, "naicsName", "sfdcId")

    let subpartPOpts = [
      {
        l: "Not Applicable",
        v: "Not Applicable",
      },
      {
        l: "Registered",
        v: "Registered",
      },
      {
        l: "Opted out",
        v: "Opted out",
      },
    ]

    const dispatch = useDispatch()

    let initValues = {
      generatorName: "",
      emergencyResponseProvider: true,
      emergencyResponseInfo: { l: "", v: 0 },
      emergencyResponsePhoneNo: "(877) 577-2669",
      shippingCountry: null,
      shippingAddressLine1: "",
      shippingCity: "",
      shippingState: null,
      shippingZip: "",
      shippingCounty: "",
      shippingPhoneNo: "",
      shippingFax: "",
      shippingEmail: "",
      shippingAttention: "",
      sameAsShipping: false,
      manifestName: "",
      manifestAddressLine1: "",
      manifestCity: "",
      manifestState: null,
      manifestZip: "",
      manifestCountry: null,
      manifestPhoneNo: "",
      manifestFax: "",
      manifestEmail: "",
      manifestAttention: "",
      NoEpaId: false,
      naics: null,
      generatorIdStatus: null,
      epaId: "",
      stateId: "",
      subpartP: null,
      isTsdfApprovalFile: false,
      disposalInstructions: "",
    }
    const [initialValues, setInitialValues] = useState(initValues)
    const [savedInfo, setSavedInfo] = useState(initValues)
    const [state, setState] = useState("")

    let valSchema = {
      generatorName: Yup.string().required("Generator Name is required"),
      emergencyResponseProvider: Yup.boolean(),
      emergencyResponseInfo: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.number(),
        })
        .nullable(),
      emergencyResponsePhoneNo: Yup.string(),
      shippingCountry: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("Country is required"),
      shippingAddressLine1: Yup.string().required("Street is required"),
      shippingCity: Yup.string().required("City is required"),
      shippingState: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("State/Province is required"),
      // shippingZip: Yup.string()
      //   .matches(/^\d{5}(-\d{4})?$/, "ZIP code is not valid")
      //   .required("ZIP code is required"),
      shippingZip: Yup.string().when("shippingCountry.v", {
        is: "US",
        then: Yup.string()
          .matches(/^\d{5}(-\d{4})?$/, "ZIP code is not valid")
          .required("ZIP code is required"),
        otherwise: Yup.string()
          .max(20, "ZIP code can be a maximum of 20 characters")
          .required("ZIP code is required"),
      }),
      shippingCounty: Yup.string(),
      shippingPhoneNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number is not valid")
        .required("Phone number is required"),
      shippingFax: Yup.string(),
      shippingEmail: Yup.string().email("Invalid email address"),
      shippingAttention: Yup.string().required("Contact name is required"),
      sameAsShipping: Yup.boolean(),
      manifestName: Yup.string().required("Manifest name is required"),
      manifestAddressLine1: Yup.string().required("Street is required"),
      // Yup.string().when("sameAsShipping", {
      //   is: true,
      //   then: Yup.string().required("Street is required"),
      //   otherwise: Yup.string().required("Street is required"),
      // }),
      manifestCity: Yup.string().required("City is required"),
      manifestState: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("State/Province is required"),
      manifestZip: Yup.string().when(["manifestCountry.v"], {
        is: (country) => country === "US",
        then: Yup.string()
          .matches(/^\d{5}(-\d{4})?$/, "ZIP code is not valid")
          .required("ZIP code is required"),
        otherwise: Yup.string()
          .max(20, "ZIP code can be a maximum of 20 characters")
          .required("ZIP code is required"),
      }),
      //  Yup.string().when(["sameAsShipping", "manifestCountry.v"], {
      //   is: (sameAsShipping, country) =>
      //     sameAsShipping  &&
      //     country === "US",
      //   then: Yup.string()
      //     .matches(/^\d{5}(-\d{4})?$/, "ZIP code is not valid")
      //     .required("ZIP code is required"),
      //   otherwise: Yup.string().when("sameAsShipping", {
      //     is: true,
      //     then: Yup.string()
      //       .max(20, "ZIP code can be a maximum of 20 characters")
      //       .required("ZIP code is required"),
      //     otherwise: Yup.string().nullable().required("ZIP code is required"),
      //   }),
      // }),
      manifestCountry: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("Country is required"),
      manifestPhoneNo: Yup.string()
        .matches(/^[0-9]{10}$/, "Phone number is not valid")
        .required("Phone number is required"),
      // Yup.string().when("sameAsShipping", {
      //   is: true,
      //   then: Yup.string()
      //     .matches(/^[0-9]{10}$/, "Phone number is not valid")
      //     .required("Phone number is required"),
      //   otherwise: Yup.string().nullable().required("Phone number is required"),
      // }),
      manifestFax: Yup.string(),
      manifestEmail: Yup.string().email("Invalid email address"),
      // Yup.string().when("sameAsShipping", {
      //   is:  true,
      //   then: Yup.string().email("Invalid email address"),
      //   otherwise: Yup.string().email("Invalid email address"),
      // }),
      manifestAttention: Yup.string().required("Contact name is required"),
      // Yup.string().when("sameAsShipping", {
      //   is:  true,
      //   then: Yup.string().required("Contact name is required"),
      //   otherwise: Yup.string().nullable().required("Contact name is required"),
      // }),
      NoEpaId: Yup.boolean(),
      naics: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("NAICS is required"),
      generatorIdStatus: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("Generator Id Status is required"),
      epaId: Yup.string().when("generatorIdStatus.v", {
        is: (value) =>
          ["To Be Assigned (TBA)", "Exempt", "HHW", "CESQG", "VSQG"].includes(
            value
          ),
        then: Yup.string()
          // .matches(
          //   /^(TBA|Exempt|HHW|[A-Za-z]{2}[A-Za-z0-9]{10})$/,
          //   "EPAID is not valid"
          // )
          .test("is-valid-epaId", "EPA ID is not valid", function (value) {
            const { generatorIdStatus } = this.parent
            const regexPattern = /^[A-Za-z]{2}[A-Za-z0-9]{10}$/

            if (state === "CT" && generatorIdStatus.v === "CESQG") {
              return (
                value?.toUpperCase() === "CTCESQG" ||
                value?.toUpperCase() === "CTCESQG99999" ||
                value?.toUpperCase() === "CVS024248900" ||
                regexPattern.test(value)
              )
            }
            if (state === "MI" && generatorIdStatus.v === "CESQG") {
              return (
                value?.toUpperCase() === "MIVSQG" ||
                value?.toUpperCase() === "MILIB" ||
                value?.toUpperCase() === "MIVSQGLIB" ||
                regexPattern.test(value)
              )
            }

            if (["CESQG", "VSQG"].includes(generatorIdStatus.v)) {
              return (
                value?.toUpperCase() === "CESQG" ||
                value?.toUpperCase() === "VSQG" ||
                regexPattern.test(value)
              )
            }
            if (generatorIdStatus.v === "To Be Assigned (TBA)") {
              return value?.toUpperCase() === "TBA" || regexPattern.test(value)
            }
            if (generatorIdStatus.v === "HHW") {
              return value?.toUpperCase() === "HHW" || regexPattern.test(value)
            }
            if (generatorIdStatus.v === "Exempt") {
              return (
                value?.toUpperCase() === "EXEMPT" || regexPattern.test(value)
              )
            }
          })
          .required("EPA ID is required"),
        otherwise: Yup.string()
          .matches(/^[A-Za-z]{2}[A-Za-z0-9]{10}$/, "EPAID is not valid")
          .required("EPA ID is required"),
      }),
      subpartP: Yup.object()
        .shape({
          l: Yup.string(),
          v: Yup.string(),
        })
        .nullable()
        .required("Subpart P is required"),
      disposalInstructions: Yup.string(),
      files: Yup.string().when("isTsdfApprovalFile", {
        is: true,
        then: Yup.string().required("File is required"),
        otherwise: Yup.string(),
      }),
    }

    useEffect(() => {
      if (!showDupGenModal && firstInputRef.current) {
        firstInputRef.current.focus()
      }
    }, [showDupGenModal])

    const getGeneratorDetails = async (id) => {
      try {
        let res = await api.g_getGeneratorDetails(id)
        if (!res) return
        dispatch({
          type: GENERATOR_DETAILS,
          payload: res,
        })
        if (res?.naics) {
          getNaicsDetails(res?.naics)
        }
      } catch (error) {}
    }

    const getNaicsDetails = async (code) => {
      let req = {
        naicsCodeKey: code,
      }
      try {
        let res = await api.p_getNAICSCodeDetals(req)
        if (!res) return
        dispatch({
          type: SET_NAICS_CDDETAILS,
          payload: res,
        })
      } catch (error) {}
    }

    const formatPhoneNumber = (phoneNumber) => {
      let phnumber = ("" + phoneNumber).replace(/\D/g, "")
      let match = phnumber.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3]
      }
      return phoneNumber
    }

    const formatCustomerName = (account) => {
      const { l, v } = account
      if (l.includes(` - ${v}`)) {
        return l // Return as is if it's already formatted
      }
      return `${l} - ${v}`
    }

    const onFinalSubmit = async (data) => {
      var formData = new FormData()
      let zipCode
      if (!enterAddress) {
        zipCode = recShippingAddr?.zipCode
      } else if (enterAddress) {
        zipCode = data?.shippingZip
      }

      let mzipCode
      if (!enterManifestAddress) {
        mzipCode = recManifestAddr?.zipCode
      } else if (enterManifestAddress) {
        mzipCode =
          // data?.sameAsShipping ? data?.shippingZip :
          data?.manifestZip
      }

      formData.append("GeneratorName", data?.generatorName || "")
      formData.append(
        "CustomerName",
        formatCustomerName(changeCustomer?.customer)
      )
      formData.append(
        "EmergencyResponseProvider",
        data?.emergencyResponseProvider || false
      )

      formData.append(
        "EmergencyResponseInfo",
        data?.emergencyResponseInfo?.v === 0
          ? "Clean Earth"
          : data?.emergencyResponseInfo?.l
      )
      formData.append(
        "EmergencyResponsePhoneNo",
        data?.emergencyResponseInfo?.v === 0 ? "(877) 577-2669" : erNameValue
      )
      formData.append("ShippingCountry", data?.shippingCountry?.v)
      formData.append(
        "ShippingAddressLine1",
        enterAddress
          ? data?.shippingAddressLine1 || ""
          : recShippingAddr?.streetAddress || ""
      )
      formData.append(
        "ShippingCity",
        enterAddress ? data?.shippingCity || "" : recShippingAddr?.city || ""
      )
      formData.append(
        "ShippingState",
        enterAddress ? data?.shippingState?.v : recShippingAddr?.state || ""
      )
      formData.append("ShippingZip", zipCode || "")
      formData.append("ShippingCounty", data?.shippingCounty || "")
      formData.append(
        "ShippingPhoneNo",
        data && data.shippingPhoneNo
          ? formatPhoneNumber(data.shippingPhoneNo)
          : ""
      )
      formData.append("ShippingFax", data?.shippingFax || "")
      formData.append("ShippingEmail", data?.shippingEmail || "")
      formData.append("ShippingAttention", data?.shippingAttention || "")
      formData.append("SameAsShipping", data?.sameAsShipping || false)
      formData.append("ManifestName", data?.manifestName || "")
      formData.append(
        "ManifestAddressLine1",
        enterManifestAddress
          ? // data?.sameAsShipping
            //   ? data?.shippingAddressLine1:
            data?.manifestAddressLine1 || ""
          : recManifestAddr?.streetAddress || ""
      )
      formData.append(
        "ManifestCity",
        enterManifestAddress
          ? // data?.sameAsShipping
            //   ? data?.shippingCity:
            data?.manifestCity || ""
          : recManifestAddr?.city || ""
      )
      formData.append(
        "ManifestState",
        enterManifestAddress
          ? // data?.sameAsShipping
            //   ? data?.shippingState?.v :
            data?.manifestState?.v || ""
          : recManifestAddr?.state
      )
      formData.append("ManifestZip", mzipCode || "")
      formData.append(
        "ManifestCountry",
        // data?.sameAsShipping
        //   ? data?.shippingCountry?.v :
        data?.manifestCountry?.v
      )
      formData.append(
        "ManifestPhoneNo",
        // data?.sameAsShipping
        //   ? formatPhoneNumber(data?.shippingPhoneNo) :
        data?.manifestPhoneNo ? formatPhoneNumber(data?.manifestPhoneNo) : ""
      )
      formData.append(
        "ManifestFax",
        // data?.sameAsShipping ? data?.shippingFax :
        data?.manifestFax || ""
      )
      formData.append(
        "ManifestEmail",
        // data?.sameAsShipping ? data?.shippingEmail :
        data?.manifestEmail || ""
      )
      formData.append(
        "ManifestAttention",
        // data?.sameAsShipping
        //   ? data?.shippingAttention :
        data?.manifestAttention || ""
      )
      formData.append("NoEpaId", data?.NoEpaId)
      formData.append("Naics", data?.naics?.v || 0)
      formData.append("EpaId", data?.epaId || "")
      formData.append("StateId", data?.stateId || "")
      formData.append("SubpartP", data?.subpartP?.v || "")
      formData.append("DisposalRestrictions", data?.disposalInstructions || "")
      formData.append("CsrEmail", knowyourcsr?.email)
      formData.append("TsdfFileUpload", files)
      formData.append("GeneratorIdStatus", data?.generatorIdStatus?.v || "")
      formData.append("GeneratorTypeDesc", "Desc")
      formData.append("ExpireDay", 90)
      formData.append("Loadit", true)
      formData.append("Submit", true)
      if (hasNoErrors) {
        try {
          setAddrLoader(false)
          setShowAddressModal(false)
          setLoading(true)
          setInitialValues(data)
          let res = await api.f_saveNewGenerator(formData)
          if (!res) return
          if (res?.data?.generatorId !== null) {
            if (res?.statusCode === 500) {
              toast.error(res?.message)
              setLoading(false)
            } else {
              setLoading(false)
              setCreatedGenId(data?.generatorName)
              setCreatedGenIDProfile(res?.data?.generatorId)
              setShowSuccessModal(true)
              if (
                generatorFrom === "createProfile" ||
                generatorFrom === "editprofile"
              ) {
                await mapGeneratorToProfile(profileId)
                await getGeneratorDetails(res?.data?.generatorId)
                history.push(`/profiles/${generatorFrom}/${profileId}`)
              }
            }
          } else {
            if (res?.message) {
              toast.error(res?.message)
            } else {
              toast.error("Generator Creation is not successful")
            }
          }
        } catch (err) {
        } finally {
          setLoading(false)
        }
      }
    }

    const areAddressesEqual = (enteredAddress, uspsaResponseAddress) => {
      const normalize = (str) => (str ? str.toLowerCase() : "")
      return (
        enteredAddress?.ZIPCode === uspsaResponseAddress?.zipCode &&
        normalize(enteredAddress.state) ===
          normalize(uspsaResponseAddress.state) &&
        normalize(enteredAddress.city) ===
          normalize(uspsaResponseAddress.city) &&
        normalize(enteredAddress.streetAddress) ===
          normalize(uspsaResponseAddress.streetAddress) &&
        enteredAddress?.ZIPPlus4 === uspsaResponseAddress?.zipPlus4
      )
    }

    const onDuplicateSubmit = async (finaldata) => {
      if (finaldata?.shippingCountry?.v === "US") {
        setRecManifestError("")
        setRecShipError("")
        let zipCode = ""
        let zipPlus = ""
        if ((finaldata?.shippingZip).length > 5) {
          let [zip1, zip2] = finaldata?.shippingZip.split("-")
          zipCode = zip1
          zipPlus = zip2
        } else {
          zipCode = finaldata?.shippingZip
        }
        let mzipCode = ""
        let mzipPlus = ""
        if (finaldata?.manifestZip.length > 5) {
          let [mzip1, mzip2] = finaldata?.manifestZip.split("-")
          mzipCode = mzip1
          mzipPlus = mzip2
        } else {
          mzipCode = finaldata?.manifestZip
        }
        let USPSaddr = {
          streetAddress: finaldata?.shippingAddressLine1 || "",
          secondaryAddress: "",
          city: finaldata?.shippingCity || "",
          state: finaldata?.shippingState?.v || "",
          ZIPCode: zipCode,
          ZIPPlus4: zipPlus,
        }
        let USPSManifestAddr = {
          streetAddress: finaldata?.sameAsShipping
            ? finaldata?.shippingAddressLine1
            : finaldata?.manifestAddressLine1 || "",
          secondaryAddress: "",
          city: finaldata?.sameAsShipping
            ? finaldata?.shippingCity
            : finaldata?.manifestCity || "",
          state: finaldata?.sameAsShipping
            ? finaldata?.shippingState?.v
            : finaldata?.manifestState?.v || "",
          ZIPCode: finaldata?.sameAsShipping ? zipCode : mzipCode,
          ZIPPlus4: finaldata?.sameAsShipping ? zipPlus : mzipPlus,
        }
        setSavedInfo(finaldata)
        if (finaldata?.sameAsShipping) {
          try {
            setLoading(true)
            let res = await api.p_USPSAddressValidation(USPSaddr)
            const { data } = res
            const zipc = data?.zipPlus4
              ? `${data?.zipCode}-${data?.zipPlus4}`
              : data?.zipCode
            let shipaddr = {
              city: data?.city || "",
              state: data?.state || "",
              streetAddress: data?.streetAddress || "",
              zipCode: zipc || "",
            }
            let manifestaddr = {
              city: data?.city || "",
              state: data?.state || "",
              streetAddress: data?.streetAddress || "",
              zipCode: zipc || "",
            }
            if (res?.statusCode === 200) {
              setRecShippingAddr(shipaddr)
              setRecManifestAddr(manifestaddr)
            }
            if (res?.statusCode === 500) {
              setRecManifestError("Invalid Address")
              setRecShipError("Invalid Address")
            }
            if (areAddressesEqual(USPSaddr, data)) {
              onFinalSubmit(finaldata)
            } else {
              setLoading(false)
              setShowAddressModal(true)
              setAddrLoader(true)
            }
          } catch (error) {
          } finally {
            setAddrLoader(false)
            setInitialValues(finaldata)
          }
        }
        if (finaldata?.sameAsShipping === false) {
          try {
            setLoading(true)
            let shipRes = await api.p_USPSAddressValidation(USPSaddr)
            const { data } = shipRes
            const zipc = data?.zipPlus4
              ? `${data?.zipCode}-${data?.zipPlus4}`
              : data?.zipCode
            let shipaddr = {
              city: data?.city || "",
              state: data?.state || "",
              streetAddress: data?.streetAddress || "",
              zipCode: zipc || "",
            }
            if (shipRes?.statusCode === 200) {
              setRecShippingAddr(shipaddr)
            } else if (shipRes?.statusCode === 500) {
              setRecShipError("Invalid Address")
            }
            let manifestRes = await api.p_USPSAddressValidation(
              USPSManifestAddr
            )
            const manifestData = manifestRes && manifestRes.data
            const zipm = manifestData?.zipPlus4
              ? `${manifestData?.zipCode}-${manifestData?.zipPlus4}`
              : manifestData?.zipCode
            let manifestaddr = {
              city: manifestData?.city || "",
              state: manifestData?.state || "",
              streetAddress: manifestData?.streetAddress || "",
              zipCode: zipm || "",
            }
            if (manifestRes?.statusCode === 200) {
              setRecManifestAddr(manifestaddr)
            } else if (manifestRes?.statusCode === 500) {
              setRecManifestError("Invalid Address")
            }
            if (
              areAddressesEqual(USPSaddr, data) &&
              areAddressesEqual(USPSManifestAddr, manifestData)
            ) {
              onFinalSubmit(finaldata)
            } else {
              setLoading(false)
              setShowAddressModal(true)
              setAddrLoader(true)
            }
          } catch (error) {
          } finally {
            setAddrLoader(false)
            setInitialValues(finaldata)
          }
        }
      } else {
        var formData = new FormData()
        let zipCode = finaldata?.shippingZip
        let mzipCode = finaldata?.sameAsShipping
          ? finaldata?.shippingZip
          : finaldata?.manifestZip
        formData.append("GeneratorName", finaldata?.generatorName || "")
        formData.append("CustomerName", changeCustomer?.customer?.l)
        formData.append(
          "EmergencyResponseProvider",
          finaldata?.emergencyResponseProvider || false
        )

        formData.append(
          "EmergencyResponseInfo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "Clean Earth"
            : finaldata?.emergencyResponseInfo?.l
        )
        formData.append(
          "EmergencyResponsePhoneNo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "(877) 577-2669"
            : erNameValue
        )
        formData.append("ShippingCountry", finaldata?.shippingCountry?.v)
        formData.append(
          "ShippingAddressLine1",
          finaldata?.shippingAddressLine1 || ""
        )
        formData.append("ShippingCity", finaldata?.shippingCity || "")
        formData.append("ShippingState", finaldata?.shippingState?.v)
        formData.append("ShippingZip", zipCode || "")
        formData.append("ShippingCounty", finaldata?.shippingCounty || "")
        formData.append(
          "ShippingPhoneNo",
          finaldata && finaldata.shippingPhoneNo
            ? formatPhoneNumber(finaldata.shippingPhoneNo)
            : ""
        )
        formData.append("ShippingFax", finaldata?.shippingFax || "")
        formData.append("ShippingEmail", finaldata?.shippingEmail || "")
        formData.append("ShippingAttention", finaldata?.shippingAttention || "")
        formData.append("SameAsShipping", finaldata?.sameAsShipping || false)
        formData.append("ManifestName", finaldata?.manifestName || "")
        formData.append(
          "ManifestAddressLine1",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAddressLine1
            : finaldata?.manifestAddressLine1 || ""
        )
        formData.append(
          "ManifestCity",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCity
            : finaldata?.manifestCity || ""
        )
        formData.append(
          "ManifestState",
          finaldata?.sameAsShipping
            ? finaldata?.shippingState?.v
            : finaldata?.manifestState?.v
        )
        formData.append("ManifestZip", mzipCode || "")
        formData.append(
          "ManifestCountry",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCountry?.v
            : finaldata?.manifestCountry?.v
        )
        formData.append(
          "ManifestPhoneNo",
          finaldata?.sameAsShipping
            ? formatPhoneNumber(finaldata?.shippingPhoneNo)
            : finaldata?.manifestPhoneNo
            ? formatPhoneNumber(finaldata?.manifestPhoneNo)
            : ""
        )
        formData.append(
          "ManifestFax",
          finaldata?.sameAsShipping
            ? finaldata?.shippingFax
            : finaldata?.manifestFax || ""
        )
        formData.append(
          "ManifestEmail",
          finaldata?.sameAsShipping
            ? finaldata?.shippingEmail
            : finaldata?.manifestEmail || ""
        )
        formData.append(
          "ManifestAttention",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAttention
            : finaldata?.manifestAttention || ""
        )
        formData.append("Naics", finaldata?.naics?.v || 0)
        formData.append("EpaId", finaldata?.epaId || "")
        formData.append("StateId", finaldata?.stateId || "")
        formData.append("SubpartP", finaldata?.subpartP?.v || "")
        formData.append(
          "DisposalRestrictions",
          finaldata?.disposalInstructions || ""
        )
        formData.append("CsrEmail", knowyourcsr?.email)
        formData.append("TsdfFileUpload", files)
        formData.append(
          "GeneratorIdStatus",
          finaldata?.generatorIdStatus?.v || ""
        )
        formData.append("GeneratorTypeDesc", "Desc")
        formData.append("ExpireDay", 90)
        formData.append("Loadit", true)
        formData.append("Submit", true)
        if (hasNoErrors) {
          try {
            setLoading(true)
            setInitialValues(finaldata)
            let res = await api.f_saveNewGenerator(formData)
            if (!res) return
            if (res?.data?.generatorId !== null) {
              if (res?.statusCode === 500) {
                toast.error(res?.message)
                setLoading(false)
              } else {
                setLoading(false)
                setCreatedGenId(finaldata?.generatorName)
                setCreatedGenIDProfile(res?.data?.generatorId)
                setShowSuccessModal(true)
              }
            } else {
              if (res?.message) {
                toast.error(res?.message)
              } else {
                toast.error("Generator Creation is not successful")
              }
            }
          } catch (err) {
          } finally {
            setLoading(false)
          }
        }
      }
    }

    const onSubmit = async (finaldata) => {
      if (
        finaldata?.emergencyResponseProvider === false &&
        finaldata?.emergencyResponseInfo?.v === 0
      ) {
        return
      }
      if (
        finaldata?.shippingCountry?.v === "US" &&
        finaldata?.manifestCountry?.v === "US"
      ) {
        setRecManifestError("")
        setRecShipError("")
        let zipCode = ""
        let zipPlus = ""
        if ((finaldata?.shippingZip).length > 5) {
          let [zip1, zip2] = finaldata?.shippingZip.split("-")
          zipCode = zip1
          zipPlus = zip2
        } else {
          zipCode = finaldata?.shippingZip
        }
        let mzipCode = ""
        let mzipPlus = ""
        if (finaldata?.manifestZip.length > 5) {
          let [mzip1, mzip2] = finaldata?.manifestZip.split("-")
          mzipCode = mzip1
          mzipPlus = mzip2
        } else {
          mzipCode = finaldata?.manifestZip
        }
        let USPSaddr = {
          streetAddress: finaldata?.shippingAddressLine1 || "",
          secondaryAddress: "",
          city: finaldata?.shippingCity || "",
          state: finaldata?.shippingState?.v || "",
          ZIPCode: zipCode,
          ZIPPlus4: zipPlus,
        }
        let USPSManifestAddr = {
          streetAddress:
            // finaldata?.sameAsShipping
            //   ? finaldata?.shippingAddressLine1 :
            finaldata?.manifestAddressLine1 || "",
          secondaryAddress: "",
          city:
            // finaldata?.sameAsShipping
            //   ? finaldata?.shippingCity :
            finaldata?.manifestCity || "",
          state:
            // finaldata?.sameAsShipping
            //   ? finaldata?.shippingState?.v :
            finaldata?.manifestState?.v || "",
          ZIPCode:
            // finaldata?.sameAsShipping ? zipCode :
            mzipCode,
          ZIPPlus4:
            // finaldata?.sameAsShipping ? zipPlus :
            mzipPlus,
        }
        setSavedInfo(finaldata)
        var formData = new FormData()
        formData.append("GeneratorName", finaldata?.generatorName || "")
        formData.append("CustomerName", changeCustomer?.customer?.l)
        formData.append(
          "EmergencyResponseProvider",
          finaldata?.emergencyResponseProvider || false
        )

        formData.append(
          "EmergencyResponseInfo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "Clean Earth"
            : finaldata?.emergencyResponseInfo?.l
        )
        formData.append(
          "EmergencyResponsePhoneNo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "(877) 577-2669"
            : erNameValue
        )
        formData.append("ShippingCountry", finaldata?.shippingCountry?.v)
        formData.append(
          "ShippingAddressLine1",
          finaldata?.shippingAddressLine1 || ""
        )
        formData.append("ShippingCity", finaldata?.shippingCity || "")
        formData.append("ShippingState", finaldata?.shippingState?.v)
        formData.append("ShippingZip", zipCode || "")
        formData.append("ShippingCounty", finaldata?.shippingCounty || "")
        formData.append(
          "ShippingPhoneNo",
          finaldata && finaldata.shippingPhoneNo
            ? formatPhoneNumber(finaldata.shippingPhoneNo)
            : ""
        )
        formData.append("ShippingFax", finaldata?.shippingFax || "")
        formData.append("ShippingEmail", finaldata?.shippingEmail || "")
        formData.append("ShippingAttention", finaldata?.shippingAttention || "")
        formData.append("SameAsShipping", finaldata?.sameAsShipping || false)
        formData.append("ManifestName", finaldata?.manifestName || "")
        formData.append(
          "ManifestAddressLine1",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAddressLine1
            : finaldata?.manifestAddressLine1 || ""
        )
        formData.append(
          "ManifestCity",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCity
            : finaldata?.manifestCity || ""
        )
        formData.append(
          "ManifestState",
          finaldata?.sameAsShipping
            ? finaldata?.shippingState?.v
            : finaldata?.manifestState?.v
        )
        formData.append("ManifestZip", mzipCode || "")
        formData.append(
          "ManifestCountry",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCountry?.v
            : finaldata?.manifestCountry?.v
        )
        formData.append(
          "ManifestPhoneNo",
          finaldata?.sameAsShipping
            ? formatPhoneNumber(finaldata?.shippingPhoneNo)
            : finaldata?.manifestPhoneNo
            ? formatPhoneNumber(finaldata?.manifestPhoneNo)
            : ""
        )
        formData.append(
          "ManifestFax",
          finaldata?.sameAsShipping
            ? finaldata?.shippingFax
            : finaldata?.manifestFax || ""
        )
        formData.append(
          "ManifestEmail",
          finaldata?.sameAsShipping
            ? finaldata?.shippingEmail
            : finaldata?.manifestEmail || ""
        )
        formData.append(
          "ManifestAttention",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAttention
            : finaldata?.manifestAttention || ""
        )
        formData.append("Naics", finaldata?.naics?.v || 0)
        formData.append("EpaId", finaldata?.epaId || "")
        formData.append("StateId", finaldata?.stateId || "")
        formData.append("SubpartP", finaldata?.subpartP?.v || "")
        formData.append(
          "DisposalRestrictions",
          finaldata?.disposalInstructions || ""
        )
        formData.append("CsrEmail", knowyourcsr?.email)
        formData.append("TsdfFileUpload", files)
        formData.append(
          "GeneratorIdStatus",
          finaldata?.generatorIdStatus?.v || ""
        )
        formData.append("GeneratorTypeDesc", "Desc")
        formData.append("ExpireDay", 90)
        formData.append("Loadit", true)
        formData.append("Submit", true)
        setLoading(true)
        let response = await api.f_checkDuplicateGenerator(formData)
        if (response && response.isDuplicate === true) {
          setInitialValues(finaldata)
          setShowDupGenModal(true)
          setLoading(false)
        } else {
          try {
            setLoading(true)
            let shipRes = await api.p_USPSAddressValidation(USPSaddr)
            const { data } = shipRes
            const zipc = data?.zipPlus4
              ? `${data?.zipCode}-${data?.zipPlus4}`
              : data?.zipCode
            let shipaddr = {
              city: data?.city || "",
              state: data?.state || "",
              streetAddress: data?.streetAddress || "",
              zipCode: zipc || "",
            }
            if (shipRes?.statusCode === 200) {
              setRecShippingAddr(shipaddr)
            } else if (shipRes?.statusCode === 500) {
              setRecShipError("Invalid Address")
            }
            let manifestRes = await api.p_USPSAddressValidation(
              USPSManifestAddr
            )
            const manifestData = manifestRes && manifestRes.data
            const zipm = manifestData?.zipPlus4
              ? `${manifestData?.zipCode}-${manifestData?.zipPlus4}`
              : manifestData?.zipCode
            let manifestaddr = {
              city: manifestData?.city || "",
              state: manifestData?.state || "",
              streetAddress: manifestData?.streetAddress || "",
              zipCode: zipm || "",
            }
            if (manifestRes?.statusCode === 200) {
              setRecManifestAddr(manifestaddr)
            } else if (manifestRes?.statusCode === 500) {
              setRecManifestError("Invalid Address")
            }
            if (
              areAddressesEqual(USPSaddr, data) &&
              areAddressesEqual(USPSManifestAddr, manifestData)
            ) {
              onFinalSubmit(finaldata)
            } else {
              setLoading(false)
              setShowAddressModal(true)
              setAddrLoader(true)
            }
          } catch (error) {
            setLoading(false)
          } finally {
            setAddrLoader(false)
            setInitialValues(finaldata)
          }
        }
      } else if (
        finaldata?.shippingCountry?.v === "US" &&
        finaldata?.manifestCountry?.v !== "US"
      ) {
        setRecShipError("")
        let zipCode = ""
        let zipPlus = ""
        if ((finaldata?.shippingZip).length > 5) {
          let [zip1, zip2] = finaldata?.shippingZip.split("-")
          zipCode = zip1
          zipPlus = zip2
        } else {
          zipCode = finaldata?.shippingZip
        }
        let USPSaddr = {
          streetAddress: finaldata?.shippingAddressLine1 || "",
          secondaryAddress: "",
          city: finaldata?.shippingCity || "",
          state: finaldata?.shippingState?.v || "",
          ZIPCode: zipCode,
          ZIPPlus4: zipPlus,
        }
        setSavedInfo(finaldata)
        var formData1 = new FormData()
        formData1.append("GeneratorName", finaldata?.generatorName || "")
        formData1.append("CustomerName", changeCustomer?.customer?.l)
        formData1.append(
          "EmergencyResponseProvider",
          finaldata?.emergencyResponseProvider || false
        )

        formData1.append(
          "EmergencyResponseInfo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "Clean Earth"
            : finaldata?.emergencyResponseInfo?.l
        )
        formData1.append(
          "EmergencyResponsePhoneNo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "(877) 577-2669"
            : erNameValue
        )
        formData1.append("ShippingCountry", finaldata?.shippingCountry?.v)
        formData1.append(
          "ShippingAddressLine1",
          finaldata?.shippingAddressLine1 || ""
        )
        formData1.append("ShippingCity", finaldata?.shippingCity || "")
        formData1.append("ShippingState", finaldata?.shippingState?.v)
        formData1.append("ShippingZip", zipCode || "")
        formData1.append("ShippingCounty", finaldata?.shippingCounty || "")
        formData1.append(
          "ShippingPhoneNo",
          finaldata && finaldata.shippingPhoneNo
            ? formatPhoneNumber(finaldata.shippingPhoneNo)
            : ""
        )
        formData1.append("ShippingFax", finaldata?.shippingFax || "")
        formData1.append("ShippingEmail", finaldata?.shippingEmail || "")
        formData1.append(
          "ShippingAttention",
          finaldata?.shippingAttention || ""
        )
        formData1.append("SameAsShipping", finaldata?.sameAsShipping || false)
        formData1.append("ManifestName", finaldata?.manifestName || "")
        formData1.append(
          "ManifestAddressLine1",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAddressLine1
            : finaldata?.manifestAddressLine1 || ""
        )
        formData1.append(
          "ManifestCity",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCity
            : finaldata?.manifestCity || ""
        )
        formData1.append(
          "ManifestState",
          finaldata?.sameAsShipping
            ? finaldata?.shippingState?.v
            : finaldata?.manifestState?.v
        )
        formData1.append("ManifestZip", finaldata?.manifestZip || "")
        formData1.append(
          "ManifestCountry",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCountry?.v
            : finaldata?.manifestCountry?.v
        )
        formData1.append(
          "ManifestPhoneNo",
          finaldata?.sameAsShipping
            ? formatPhoneNumber(finaldata?.shippingPhoneNo)
            : finaldata?.manifestPhoneNo
            ? formatPhoneNumber(finaldata?.manifestPhoneNo)
            : ""
        )
        formData1.append(
          "ManifestFax",
          finaldata?.sameAsShipping
            ? finaldata?.shippingFax
            : finaldata?.manifestFax || ""
        )
        formData1.append(
          "ManifestEmail",
          finaldata?.sameAsShipping
            ? finaldata?.shippingEmail
            : finaldata?.manifestEmail || ""
        )
        formData1.append(
          "ManifestAttention",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAttention
            : finaldata?.manifestAttention || ""
        )
        formData1.append("Naics", finaldata?.naics?.v || 0)
        formData1.append("EpaId", finaldata?.epaId || "")
        formData1.append("StateId", finaldata?.stateId || "")
        formData1.append("SubpartP", finaldata?.subpartP?.v || "")
        formData1.append(
          "DisposalRestrictions",
          finaldata?.disposalInstructions || ""
        )
        formData1.append("CsrEmail", knowyourcsr?.email)
        formData1.append("TsdfFileUpload", files)
        formData1.append(
          "GeneratorIdStatus",
          finaldata?.generatorIdStatus?.v || ""
        )
        formData1.append("GeneratorTypeDesc", "Desc")
        formData1.append("ExpireDay", 90)
        formData1.append("Loadit", true)
        formData1.append("Submit", true)
        setLoading(true)
        let response = await api.f_checkDuplicateGenerator(formData1)
        if (response && response.isDuplicate === true) {
          setInitialValues(finaldata)
          setShowDupGenModal(true)
          setLoading(false)
        } else {
          try {
            setLoading(true)
            let shipRes = await api.p_USPSAddressValidation(USPSaddr)
            const { data } = shipRes
            const zipc = data?.zipPlus4
              ? `${data?.zipCode}-${data?.zipPlus4}`
              : data?.zipCode
            let shipaddr = {
              city: data?.city || "",
              state: data?.state || "",
              streetAddress: data?.streetAddress || "",
              zipCode: zipc || "",
            }
            if (shipRes?.statusCode === 200) {
              setRecShippingAddr(shipaddr)
            } else if (shipRes?.statusCode === 500) {
              setRecShipError("Invalid Address")
            }
            setLoading(false)
            setShowAddressModal(true)
            setAddrLoader(true)
          } catch (error) {
            setLoading(false)
          } finally {
            setAddrLoader(false)
            setInitialValues(finaldata)
          }
        }
      } else if (
        finaldata?.shippingCountry?.v !== "US" &&
        finaldata?.manifestCountry?.v === "US"
      ) {
        setRecManifestError("")
        let mzipCode = ""
        let mzipPlus = ""
        if (finaldata?.manifestZip.length > 5) {
          let [mzip1, mzip2] = finaldata?.manifestZip.split("-")
          mzipCode = mzip1
          mzipPlus = mzip2
        } else {
          mzipCode = finaldata?.manifestZip
        }
        let USPSManifestAddr = {
          streetAddress: finaldata?.manifestAddressLine1 || "",
          secondaryAddress: "",
          city: finaldata?.manifestCity || "",
          state: finaldata?.manifestState?.v || "",
          ZIPCode: mzipCode,
          ZIPPlus4: mzipPlus,
        }
        setSavedInfo(finaldata)
        var formData2 = new FormData()
        formData2.append("GeneratorName", finaldata?.generatorName || "")
        formData2.append("CustomerName", changeCustomer?.customer?.l)
        formData2.append(
          "EmergencyResponseProvider",
          finaldata?.emergencyResponseProvider || false
        )

        formData2.append(
          "EmergencyResponseInfo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "Clean Earth"
            : finaldata?.emergencyResponseInfo?.l
        )
        formData2.append(
          "EmergencyResponsePhoneNo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "(877) 577-2669"
            : erNameValue
        )
        formData2.append("ShippingCountry", finaldata?.shippingCountry?.v)
        formData2.append(
          "ShippingAddressLine1",
          finaldata?.shippingAddressLine1 || ""
        )
        formData2.append("ShippingCity", finaldata?.shippingCity || "")
        formData2.append("ShippingState", finaldata?.shippingState?.v)
        formData2.append("ShippingZip", finaldata?.shippingZip || "")
        formData2.append("ShippingCounty", finaldata?.shippingCounty || "")
        formData2.append(
          "ShippingPhoneNo",
          finaldata && finaldata.shippingPhoneNo
            ? formatPhoneNumber(finaldata.shippingPhoneNo)
            : ""
        )
        formData2.append("ShippingFax", finaldata?.shippingFax || "")
        formData2.append("ShippingEmail", finaldata?.shippingEmail || "")
        formData2.append(
          "ShippingAttention",
          finaldata?.shippingAttention || ""
        )
        formData2.append("SameAsShipping", finaldata?.sameAsShipping || false)
        formData2.append("ManifestName", finaldata?.manifestName || "")
        formData2.append(
          "ManifestAddressLine1",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAddressLine1
            : finaldata?.manifestAddressLine1 || ""
        )
        formData2.append(
          "ManifestCity",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCity
            : finaldata?.manifestCity || ""
        )
        formData2.append(
          "ManifestState",
          finaldata?.sameAsShipping
            ? finaldata?.shippingState?.v
            : finaldata?.manifestState?.v
        )
        formData2.append("ManifestZip", finaldata?.manifestZip || "")
        formData2.append(
          "ManifestCountry",
          finaldata?.sameAsShipping
            ? finaldata?.shippingCountry?.v
            : finaldata?.manifestCountry?.v
        )
        formData2.append(
          "ManifestPhoneNo",
          finaldata?.sameAsShipping
            ? formatPhoneNumber(finaldata?.shippingPhoneNo)
            : finaldata?.manifestPhoneNo
            ? formatPhoneNumber(finaldata?.manifestPhoneNo)
            : ""
        )
        formData2.append(
          "ManifestFax",
          finaldata?.sameAsShipping
            ? finaldata?.shippingFax
            : finaldata?.manifestFax || ""
        )
        formData2.append(
          "ManifestEmail",
          finaldata?.sameAsShipping
            ? finaldata?.shippingEmail
            : finaldata?.manifestEmail || ""
        )
        formData2.append(
          "ManifestAttention",
          finaldata?.sameAsShipping
            ? finaldata?.shippingAttention
            : finaldata?.manifestAttention || ""
        )
        formData2.append("Naics", finaldata?.naics?.v || 0)
        formData2.append("EpaId", finaldata?.epaId || "")
        formData2.append("StateId", finaldata?.stateId || "")
        formData2.append("SubpartP", finaldata?.subpartP?.v || "")
        formData2.append(
          "DisposalRestrictions",
          finaldata?.disposalInstructions || ""
        )
        formData2.append("CsrEmail", knowyourcsr?.email)
        formData2.append("TsdfFileUpload", files)
        formData2.append(
          "GeneratorIdStatus",
          finaldata?.generatorIdStatus?.v || ""
        )
        formData2.append("GeneratorTypeDesc", "Desc")
        formData2.append("ExpireDay", 90)
        formData2.append("Loadit", true)
        formData2.append("Submit", true)
        setLoading(true)
        let response = await api.f_checkDuplicateGenerator(formData2)
        if (response && response.isDuplicate === true) {
          setInitialValues(finaldata)
          setShowDupGenModal(true)
          setLoading(false)
        } else {
          try {
            setLoading(true)
            let manifestRes = await api.p_USPSAddressValidation(
              USPSManifestAddr
            )
            const manifestData = manifestRes && manifestRes.data
            const zipm = manifestData?.zipPlus4
              ? `${manifestData?.zipCode}-${manifestData?.zipPlus4}`
              : manifestData?.zipCode
            let manifestaddr = {
              city: manifestData?.city || "",
              state: manifestData?.state || "",
              streetAddress: manifestData?.streetAddress || "",
              zipCode: zipm || "",
            }
            if (manifestRes?.statusCode === 200) {
              setRecManifestAddr(manifestaddr)
            } else if (manifestRes?.statusCode === 500) {
              setRecManifestError("Invalid Address")
            }
            setLoading(false)
            setShowAddressModal(true)
            setAddrLoader(true)
          } catch (error) {
            setLoading(false)
          } finally {
            setAddrLoader(false)
            setInitialValues(finaldata)
          }
        }
      } else {
        var formData3 = new FormData()
        let zipCode = finaldata?.shippingZip
        let mzipCode =
          // finaldata?.sameAsShipping
          //   ? finaldata?.shippingZip:
          finaldata?.manifestZip
        setSavedInfo(finaldata)
        formData3.append("GeneratorName", finaldata?.generatorName || "")
        formData3.append(
          "CustomerName",
          formatCustomerName(changeCustomer?.customer)
        )
        formData3.append(
          "EmergencyResponseProvider",
          finaldata?.emergencyResponseProvider || false
        )

        formData3.append(
          "EmergencyResponseInfo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "Clean Earth"
            : finaldata?.emergencyResponseInfo?.l
        )
        formData3.append(
          "EmergencyResponsePhoneNo",
          finaldata?.emergencyResponseInfo?.v === 0
            ? "(877) 577-2669"
            : erNameValue
        )
        formData3.append("ShippingCountry", finaldata?.shippingCountry?.v)
        formData3.append(
          "ShippingAddressLine1",
          finaldata?.shippingAddressLine1 || ""
        )
        formData3.append("ShippingCity", finaldata?.shippingCity || "")
        formData3.append("ShippingState", finaldata?.shippingState?.v)
        formData3.append("ShippingZip", zipCode || "")
        formData3.append("ShippingCounty", finaldata?.shippingCounty || "")
        formData3.append(
          "ShippingPhoneNo",
          finaldata && finaldata.shippingPhoneNo
            ? formatPhoneNumber(finaldata.shippingPhoneNo)
            : ""
        )
        formData3.append("ShippingFax", finaldata?.shippingFax || "")
        formData3.append("ShippingEmail", finaldata?.shippingEmail || "")
        formData3.append(
          "ShippingAttention",
          finaldata?.shippingAttention || ""
        )
        formData3.append("SameAsShipping", finaldata?.sameAsShipping || false)
        formData3.append("ManifestName", finaldata?.manifestName || "")
        formData3.append(
          "ManifestAddressLine1",
          // finaldata?.sameAsShipping
          //   ? finaldata?.shippingAddressLine1 :
          finaldata?.manifestAddressLine1 || ""
        )
        formData3.append(
          "ManifestCity",
          // finaldata?.sameAsShipping
          //   ? finaldata?.shippingCity :
          finaldata?.manifestCity || ""
        )
        formData3.append(
          "ManifestState",
          // finaldata?.sameAsShipping
          //   ? finaldata?.shippingState?.v :
          finaldata?.manifestState?.v
        )
        formData3.append("ManifestZip", mzipCode || "")
        formData3.append(
          "ManifestCountry",
          // finaldata?.sameAsShipping
          //   ? finaldata?.shippingCountry?.v :
          finaldata?.manifestCountry?.v
        )
        formData3.append(
          "ManifestPhoneNo",
          // finaldata?.sameAsShipping
          //   ? formatPhoneNumber(finaldata?.shippingPhoneNo) :
          finaldata?.manifestPhoneNo
            ? formatPhoneNumber(finaldata?.manifestPhoneNo)
            : ""
        )
        formData3.append(
          "ManifestFax",
          // finaldata?.sameAsShipping
          //   ? finaldata?.shippingFax:
          finaldata?.manifestFax || ""
        )
        formData3.append(
          "ManifestEmail",
          // finaldata?.sameAsShipping
          //   ? finaldata?.shippingEmail :
          finaldata?.manifestEmail || ""
        )
        formData3.append(
          "ManifestAttention",
          // finaldata?.sameAsShipping
          //   ? finaldata?.shippingAttention :
          finaldata?.manifestAttention || ""
        )
        formData3.append("NoEpaId", finaldata?.NoEpaId || false)
        formData3.append("Naics", finaldata?.naics?.v || 0)
        formData3.append("EpaId", finaldata?.epaId || "")
        formData3.append("StateId", finaldata?.stateId || "")
        formData3.append("SubpartP", finaldata?.subpartP?.v || "")
        formData3.append(
          "DisposalRestrictions",
          finaldata?.disposalInstructions || ""
        )
        formData3.append("CsrEmail", knowyourcsr?.email)
        formData3.append("TsdfFileUpload", files)
        formData3.append(
          "GeneratorIdStatus",
          finaldata?.generatorIdStatus?.v || ""
        )
        formData3.append("GeneratorTypeDesc", "Desc")
        formData3.append("ExpireDay", 90)
        formData3.append("Loadit", true)
        formData3.append("Submit", true)
        setLoading(true)
        let response = await api.f_checkDuplicateGenerator(formData3)
        if (response && response.isDuplicate === true) {
          setInitialValues(finaldata)
          setShowDupGenModal(true)
          setLoading(false)
        } else {
          if (hasNoErrors) {
            try {
              setLoading(true)
              setInitialValues(finaldata)
              let res = await api.f_saveNewGenerator(formData)
              if (!res) return
              if (res?.data?.generatorId !== null) {
                if (res?.statusCode === 500) {
                  toast.error(res?.message)
                  setLoading(false)
                } else {
                  setLoading(false)
                  setCreatedGenId(finaldata?.generatorName)
                  setCreatedGenIDProfile(res?.data?.generatorId)
                  setShowSuccessModal(true)
                  if (
                    generatorFrom === "createProfile" ||
                    generatorFrom === "editprofile"
                  ) {
                    history.push(
                      `/profiles/${generatorFrom}/${profileId}?from=${"newGenerator"}`
                    )
                  }
                }
              } else {
                if (res?.message) {
                  toast.error(res?.message)
                } else {
                  toast.error("Generator Creation is not successful")
                }
              }
            } catch (err) {
            } finally {
              setLoading(false)
            }
          }
        }
      }
    }

    let formik = {
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: Yup.object().shape(valSchema),
      onSubmit: onSubmit,
    }

    const getAllGenerators = async (val) => {
      try {
        let res = await api.g_getAllGenerators()
        if (!res) return
        await dispatch({ type: ALL_GENERATORS, payload: res })
        if (val === "fromProf") {
          goToCreateProfile("createprofile")
        }
      } catch (error) {
        setLoading(false)
      }
    }

    const getAllCountryList = async () => {
      try {
        let res = await api.g_getCountryList()
        if (!res) return
        dispatch({ type: SHIP_COUNTRY_LIST, payload: res })
      } catch (error) {}
    }

    const getAllStateList = async () => {
      try {
        let res = await api.g_getStateList()
        if (!res) return
        dispatch({ type: SHIP_STATE_LIST, payload: res })
      } catch (error) {}
    }

    const getAllErServiceNames = async () => {
      try {
        let res = await api.g_getAllErServiceNames()
        if (!res) return
        dispatch({ type: ALL_ER_SERVICE_NAMES, payload: res })
      } catch (error) {}
    }

    const getPhoneNumberByProviderName = async (req) => {
      try {
        let res = await api.g_getPhoneNumberByProviderName(req)
        if (!res) return
        setERNameValue(res?.phoneNumber)
        dispatch({ type: PHONE_NUMBER_BY_PROVIDER_NAME, payload: res })
      } catch (error) {}
    }

    const getGeneratorStatus = async () => {
      try {
        let res = await api.g_getGeneratorIdStatus()
        if (!res) return
        dispatch({ type: GENERATOR_ID_STATUS, payload: res })
      } catch (error) {}
    }

    const getNaicsCode = async () => {
      try {
        let res = await api.g_getNaicsCode()
        if (!res) return
        dispatch({ type: NAICS_CODE, payload: res })
      } catch (error) {}
    }

    useEffect(() => {
      if (firstTimeFetch.current === true) {
        getAllGenerators()
        getAllErServiceNames()
        getAllCountryList()
        getAllStateList()
        getGeneratorStatus()
        getNaicsCode()
        firstTimeFetch.current = false
      }
    }, [])

    const handleShipState = (e) => {
      let states = shipStateOpts.filter(
        (state) => state.countryTerritoryCode === e.v
      )
      setShipStates([...states])
    }

    const handleManifestState = (e) => {
      let states = shipStateOpts.filter(
        (state) => state.countryTerritoryCode === e.v
      )
      setManifestStates([...states])
    }

    const mapGeneratorToProfile = async (id) => {
      await dispatch({
        type: PROFILE_CRETAE_FROM_GENE,
        payload: { isProfileFromGenerator: true, selectedProfileGenerator: id },
      })
    }

    const mapDefaultGenerator = async (val) => {
      if (val) {
        getAllGenerators("fromProf")
        await dispatch({
          type: PROFILE_CRETAE_FROM_GENE,
          payload: {
            isProfileFromGenerator: true,
            selectedProfileGenerator: val,
          },
        })
      } else {
        await dispatch({
          type: PROFILE_CRETAE_FROM_GENE,
          payload: {
            isProfileFromGenerator: false,
            selectedProfileGenerator: null,
          },
        })
      }
      history.push(`/profiles/${generatorFrom}/${profileId}`)
    }

    const goToCreateProfile = async (page) => {
      await dispatch({ type: CURRENT_PROFILE, payload: {} })
      await dispatch({
        type: SET_NEW_PROGRESS_STEP,
      })
      await dispatch({
        type: SET_PROGRESS_STEP,
        step: 1,
        progressStep: 1,
        percentage: 0,
      })
      history.push(`/profiles/${page}?from=newGenerator`)
    }

    const cancelAction = async () => {
      if (
        generatorFrom === "createProfile" ||
        generatorFrom === "editprofile"
      ) {
        setLoading(true)
        await mapDefaultGenerator(createdGenIDProfile)
      } else {
        getAllGenerators()
        history.push(`/generator`)
      }
    }

    return (
      <Component
        {...props}
        {...{
          formik,
          genCountries,
          shipStateOpts,
          handleShipState,
          handleManifestState,
          shipStates,
          manifestStates,
          ERNameOpts,
          genIdStatusOpts,
          naicsOpts,
          subpartPOpts,
          getPhoneNumberByProviderName,
          TSDFFile,
          setTSDFFile,
          shippingError,
          setShippingError,
          manifestError,
          setManifestError,
          setFiles,
          showSuccessModal,
          setShowSuccessModal,
          cancelAction,
          erNameValue,
          createdGenId,
          loading,
          setHasNoErrors,
          showAddressModal,
          setShowAddressModal,
          recShippingAddr,
          setRecShippingAddr,
          recManifestAddr,
          setRecManifestAddr,
          recShipError,
          recManifestError,
          enterAddress,
          setEnterAddress,
          enterManifestAddress,
          setEnterManifestAddress,
          onFinalSubmit,
          savedInfo,
          addrLoader,
          showDupGenModal,
          setShowDupGenModal,
          firstInputRef,
          onDuplicateSubmit,
          setState,
        }}
      />
    )
  }

export default enhancer
