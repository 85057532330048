import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import api from "api"
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux"
import * as dfns from "date-fns"
import { useHarsco, useToday, setCustomerData } from "shared"
import { ScheduleTypes, roles, BusinessSegmentId } from "config"
import { clearIndexedDB, logOut } from "msal"
import useDebounce from "shared/debounce"
import { ALL_GENERATORS } from "store/reducer/actionType"
import { useParams } from "react-router-dom/cjs/react-router-dom"

let enhancer =
  (Component) =>
  ({ ...props }) => {
    let history = useHistory()
    let today = useToday()
    const { type } = useParams()
    const dispatch = useDispatch()
    const alertList = useSelector((state) => state.alerts)
    const alertListD = useSelector((state) => state.alerts.doAlertList)
    let [showModal, setShowModal] = useState(false)
    let [unreadAlrtCount, setAlertsCount] = useState(0)
    const { isStoreManager, isFullCircle, isMandI } = useHarsco()
    const user = useSelector((state) => state.profile)
    const [open, setOpen] = useState(false)
    const [customers, setCustomers] = useState([])

    const [selectedCustomer, setSelectedCustomer] = useState()
    const [customerSearch, setSearch] = useState()

    const { changeCustomer } = useSelector((state) => state)
    const { customerList } = useSelector((state) => state)

    const { generators, config } = useSelector((state) => state)
    const generatorOptions = useSelector(
      (state) => state.generatorDetails.generatorList
    )

    useEffect(() => {
      const { REACT_APP_UI_VERSION, REACT_APP_API_VERSION } = process.env
      if (!localStorage.ui_version || !localStorage.api_version) {
        localStorage.ui_version = REACT_APP_UI_VERSION
        localStorage.api_version = REACT_APP_API_VERSION
      }

      if (
        localStorage.ui_version != REACT_APP_UI_VERSION ||
        localStorage.api_version != REACT_APP_API_VERSION
      ) {
        ;(async () => {
          await logout()
        })()
      }
      if (!changeCustomer?.source) {
        checkForGlobalCustomerChange()
      } else {
        clearLocalCache(changeCustomer.customer)
      }

      if (changeCustomer?.store) {
        clearLocalCache(changeCustomer.customer)
      }
    }, [changeCustomer])

    useEffect(() => {
      getGenerator()
    }, [generators, generatorOptions])

    const checkForGlobalCustomerChange = () => {
      // loading roles
      getRoles()
      // we are not showing global customer drop down for site and corporate role
      if (
        user?.roleId !== roles.SITE_USER &&
        user?.roleId !== roles.CORPORATE_USER
      )
        getInitialCustomer()

      // updating selected customer in global drop-down
      if (changeCustomer?.customer) {
        setSelectedCustomer({
          l: changeCustomer?.customer?.l?.includes(" - ")
            ? changeCustomer?.customer?.l
            : `${changeCustomer?.customer?.l} - ${changeCustomer?.customer?.v}`,
          v: changeCustomer?.customer?.v,
        })
      }
      // else on login default customer
      else {
        setSelectedCustomer({ l: user.customer_name, v: user.customer_id })
        dispatch({
          type: "SET_CHANGE_CUSTOMER",
          status: false,
          customer_name: {
            l: user.customer_name,
            v: user.customer_id,
            sourceSystem: user.sourceSystem,
            businessSegmentId: user?.businessSegmentId,
          },
        })
      }
    }
    // const getAllGenerators = async () => {
    //   // try {
    //   //   let res = await api.g_getAllGenerators()
    //   //   if (!res) return

    //   //   dispatch({ type: ALL_GENERATORS, payload: res })
    //   // } catch (error) {}
    // }

    useEffect(() => {
      if (
        window.clarity &&
        user &&
        user.email &&
        changeCustomer &&
        changeCustomer.customer
      ) {
        //eslint-disable-next-line
        console.log(
          "identify",
          user && user.email,
          changeCustomer?.customer?.v || "",
          "",
          changeCustomer?.customer?.l || ""
        )
        window.clarity(
          "identify",
          user && user.email,
          changeCustomer?.customer?.v || "",
          "",
          changeCustomer?.customer?.l || ""
        )
      }
    }, [changeCustomer?.customer])

    const getGenerator = async () => {
      if (
        generators?.length === 0 &&
        changeCustomer?.customer?.businessSegmentId !== 3
      ) {
        let store
        store = await api.g_getallstore()

        dispatch({ type: "SET_GENERATORS", store })

        store?.forEach((d) => {
          if (!d.division) d.division = ""
          if (!d.region) d.region = ""
        })
        if (!store?.length > 0) return
        store = _.uniqBy(store, "storeNumber")
        store = store.filter((d) => d.storeNumber)
        dispatch({ type: "SET_STORE", store })
      }
      // if (
      //   generatorOptions?.length === 0 &&
      //   changeCustomer?.customer?.businessSegmentId === 3
      // ) {
      //   getAllGenerators()
      // }
    }
    const getRoles = async () => {
      let rolesTypes = await api.g_userTypes({ loading_key: "support_form" })

      if (rolesTypes)
        dispatch({
          type: "SET_ROLES",
          roles: rolesTypes,
        })
    }

    useEffect(() => {
      if (
        user &&
        !(
          user.roleId === roles.CORPORATE_USER &&
          changeCustomer?.customer?.businessSegmentId === 3
        )
      ) {
        ;(async () => {
          if (customerSearch?.length > 2) {
            let res = await api.g_onboardedcustomers({
              searchText: customerSearch,
              loading_key: "customer_search",
            })
            let customer = setCustomerData(res)
            setCustomers(customer)
          } else if (customerSearch?.length == 0) {
            getInitialCustomer()
          }
        })()
      }
    }, [useDebounce(customerSearch, 1000)])

    const getInitialCustomer = async () => {
      if (
        user &&
        !(
          user.roleId === roles.CORPORATE_USER &&
          changeCustomer?.customer?.businessSegmentId === 3
        )
      ) {
        if (customerList.length == 0) {
          let resCustomer = await api.g_onboardedcustomers()
          if (resCustomer) {
            let customer = []
            customer = setCustomerData(resCustomer)
            setCustomers(customer)
            dispatch({ type: "SET_CUSTOMERS", customers: customer })
          }
        } else {
          setCustomers(customerList)
        }
      }
    }

    const onLogoClick = (e) => {
      history.push("/")
    }

    const onServiceReqClick = (e) => {
      history.push("/requestservice")
    }

    useEffect(() => {
      if (!alertList) return
      const alertsF = alertList?.alerts?.filter((d) => !d?.isRead)
      if (!alertListD) return
      //status code 2 indicates downling in progress
      const alertsD = alertListD?.filter((d) => !d?.isRead && d?.status != 2)
      let count
      if (changeCustomer?.customer?.businessSegmentId == 3) {
        count = parseInt(alertsF?.length)
      } else {
        count = parseInt(alertsD?.length + alertsF?.length)
      }
      setAlertsCount(count)
    }, [alertList, alertListD, unreadAlrtCount])

    let timeout = 60 * 60 * 1000

    useEffect(() => {
      if (!props.fontLoaded) return
      getSiteData()
    }, [props.fontLoaded])

    useEffect(() => {
      if (
        !props.fontLoaded ||
        changeCustomer?.customer?.businessSegmentId ===
          BusinessSegmentId?.["M&I"] ||
        type == "viewprofile" ||
        type == "createprofile" ||
        type == "editprofile"
      ) {
        return
      }

      getDownloadNotificationsData()
      const intervalD = setInterval(getDownloadNotificationsData, timeout)
      return () => clearInterval(intervalD)
    }, [
      props.fontLoaded,
      changeCustomer,
      changeCustomer?.customer?.businessSegmentId,
      type,
    ])

    const getDownloadNotificationsData = async () => {
      if (!navigator.onLine) return
      setTimeout(() => {
        getDownloadNotificationsData()
      }, 60 * 1000)

      let dAlerts = await api.g_downloadAlertList({
        userId: user.userId,
      })

      if (!dAlerts.length > 0) return
      dispatch({
        type: "SET_DOWNLOAD_ALERTS",
        doAlertList: dAlerts,
      })
      //eslint-disable-next-line
      console.log("got new alerts!!")
    }

    const checkBusiness = (change_customer, value) => {
      if (change_customer) {
        return (
          value?.businessSegmentId === BusinessSegmentId.FULL_CIRCLE ||
          value?.businessSegmentId === BusinessSegmentId["M&I"]
        )
      } else {
        return isFullCircle || isMandI
      }
    }

    const getSiteData = async (change_customer = false, value = {}) => {
      if (!navigator.onLine)
        return setTimeout(() => {
          getSiteData()
        }, 60 * 1000)

      if (!checkBusiness(change_customer, value)) {
        let docTypes = await api.g_docTypes()
        if (!docTypes) return
        dispatch({ type: "SET_CONFIG", config: { docTypes } })
      }

      getAlerts()

      if (!checkBusiness(change_customer, value)) {
        let scheduleTypes = await api.g_scheduleTypes()
        if (!scheduleTypes) return
        let serviceTypes = await api.g_serviceTypes()
        if (!serviceTypes) return
        scheduleTypes = scheduleTypes.sort(function (a, b) {
          return ScheduleTypes.indexOf(a) - ScheduleTypes.indexOf(b)
        })
        let exceptionType = await api.g_exceptionGrossary()
        if (!exceptionType) return
        exceptionType = exceptionType
          .map((d) => d.exceptionName)
          .sort((a, b) => a.normalize().localeCompare(b.normalize()))

        let serviceReqSupplies = await api.g_ServiceRequestSupplies()
        if (!serviceReqSupplies) return

        let profileList = await api.g_getprofilelist()
        if (!profileList) return
        profileList = profileList.sort()
        if (
          config?.serviceTypes === undefined ||
          config?.serviceReqSupplies === undefined ||
          config?.exceptionType === undefined ||
          config?.scheduleTypes ||
          config?.profileList
        ) {
          dispatch({
            type: "SET_CONFIG",
            config: {
              scheduleTypes,
              serviceTypes,
              exceptionType,
              serviceReqSupplies,
              profileList,
            },
          })
        }
      }

      if (
        generators?.length === 0 &&
        changeCustomer?.customer?.businessSegmentId !== 3
      ) {
        let store
        store = await api.g_getallstore()

        dispatch({ type: "SET_GENERATORS", store })

        store?.forEach((d) => {
          if (!d.division) d.division = ""
          if (!d.region) d.region = ""
        })
        if (!store) return
        store = _.uniqBy(store, "storeNumber")
        store = store.filter((d) => d.storeNumber)
        dispatch({ type: "SET_STORE", store })
      }
      // if (changeCustomer?.customer?.businessSegmentId === 3) {
      //   getAllGenerators()
      // }
    }

    const getAlerts = async () => {
      if (isFullCircle) {
        let fullCirleAlters = await api.g_FullAlerts({
          dates: [dfns.sub(today, { days: 30 }), today],
          loading_key: "get_alerts",
        })
        if (!fullCirleAlters) return
        dispatch({ type: "SET_ALERTS", alertList: fullCirleAlters?.alerts })
      } else {
        let alerts = await api.g_alertsAll({
          dates: [dfns.sub(today, { days: 30 }), today],
          loading_key: "get_alerts",
        })
        if (!alerts) return
        dispatch({ type: "SET_ALERTS", alertList: alerts })
      }
    }

    const onChangeSearch = () => {}

    const onClickhelp = () => {
      history.push("/help")
    }
    const handleModalToggle = () => {
      if (changeCustomer?.customer?.businessSegmentId === 3) {
        history.push("/myalerts")
      } else if (unreadAlrtCount > 0) {
        setShowModal(!showModal)
      } else {
        history.push("/alerts")
      }
      // if (unreadAlrtCount > 0) setShowModal(!showModal)
      // else {
      // if (changeCustomer?.customer?.businessSegmentId === 3 || user.roleId === 2)
      //   history.push("/myalerts")
      // else history.push("/alerts")
      // }
    }

    const updateCount = (type, obj) => {
      if (unreadAlrtCount >= 0)
        if (type == "add") {
          getSiteData()
          alertListD.push(obj)
          dispatch({
            type: "SET_DOWNLOAD_ALERTS",
            doAlertList: alertListD,
          })

          setAlertsCount(unreadAlrtCount + 1)
        } else if (type == "sub") setAlertsCount(unreadAlrtCount - 1)
    }

    const handleoutsideModal = () => {
      setShowModal(false)
    }

    const logout = async () => {
      dispatch({ type: ALL_GENERATORS, payload: [] })
      await api.pa_logOut()
      await logOut()
    }

    const onCleanEarthClick = () => {
      const newWindow = window.open(
        `https://www.cleanearthinc.com/news`,
        "_blank",
        "noopener,noreferrer"
      )
      if (newWindow) newWindow.opener = null
    }

    const handleSearchChange = (e) => {
      if (e.target.name === "customerSearch") setSearch(e.target.value)
    }

    /**
     * method is executed after global customer is updated successful
     */
    const customerUpdateSuccessful = async (value) => {
      //trigerring 'SET_CHANGE_CUSTOMER' change event
      dispatch({
        type: "SET_CHANGE_CUSTOMER",
        status: true,
        customer_name: value,
      })

      clearLocalCache(value)
    }

    const clearLocalCache = async (value) => {
      //clearing 'localforage' storage and clearing redux store expect profile and user
      await clearIndexedDB()
      dispatch({ type: "DELETE_GENERATORS" })
      dispatch({ type: "DELETE_CONFIG" })
      dispatch({ type: "DELETE_STORE" })
      dispatch({ type: "DELETE_ALTERS" })
      dispatch({ type: "DELETE_FILTERS" })

      //calling generator, schedules, service types and document types apis
      await getSiteData(true, value)

      //trigerring 'SET_CHANGE_CUSTOMER' change event
      dispatch({
        type: "SET_CHANGE_CUSTOMER",
        status: false,
        customer_name: value,
        source: false,
        store: false,
      })
    }

    const updateCustomer = async (value) => {
      //trigerring 'SET_CHANGE_CUSTOMER' change event
      // status is boolean flag to enable or disable global spinner
      dispatch({
        type: "SET_CHANGE_CUSTOMER",
        status: true,
        customer_name: changeCustomer?.customer,
      })
      try {
        let res = await api.pa_updateDefaultCustomer({
          customer_id: value?.v,
          shouldUpdateDefaultCustomer: false,
          generatorId: "",
        })
        if (res) {
          customerUpdateSuccessful(value)
          history.push("/")
        } else {
          dispatch({
            type: "SET_CHANGE_CUSTOMER",
            status: false,
            customer_name: { l: user.customer_name, v: user.customer_id },
            source: false,
          })
        }
        if (value?.businessSegmentId === 3) {
          await getAlerts()
        }
      } catch (err) {
        dispatch({
          type: "SET_CHANGE_CUSTOMER",
          status: false,
          customer_name: changeCustomer?.customer
            ? { l: changeCustomer?.customer?.l, v: changeCustomer?.customer?.v }
            : { l: user.customer_name, v: user.customer_id },
          source: false,
        })
      }
    }

    /**
     * this method is invoked after global customer drop down is selected
     */
    const onSelectValue = (name, value) => {
      setSelectedCustomer({ ...value })
      updateCustomer(value)
    }

    const onSelectField = (title, value) => {
      setOpen(value)
      if (!value) {
        getInitialCustomer()
      }
    }

    return (
      <Component
        {...props}
        {...{
          onLogoClick,
          onServiceReqClick,
          onChangeSearch,
          onClickhelp,
          showModal,
          handleModalToggle,
          handleoutsideModal,
          unreadAlrtCount,
          today,
          logout,
          onCleanEarthClick,
          setShowModal,
          isStoreManager,
          alertList,
          alertListD,
          updateCount,
          isFullCircle,
          onSelectValue,
          setOpen,
          onSelectField,
          open,
          customers,
          user,
          selectedCustomer,
          handleSearchChange,
          roles,
          changeCustomer,
        }}
      />
    )
  }
export default enhancer
