// import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import Select from "shared/Select"
import MultiSelectComponent from "shared/Select/CustomMultiSelect"
import "./style.scss"
import Loading from "shared/Loading"
// import Image from "shared/Image"
import enhancer from "./enhancer"
import classnames from "classnames"
import { ProfileListReportsHeaders } from "../List/Lists"
// import { ProfileListReportsHeaderMapping } from "../List/Lists"
// import Image from "shared/Image"
import Modal from "shared/Modal"
import Image from "shared/Image"
import { useEffect } from "react"
import Pagination from "shared/Pagination"

function Report(props) {
  const {
    selectedGenerator,
    generatorCodeOpts,
    facilityProcessCodeOpts,
    sortArrows,
    //profilelistreport,
    // profilelistingreport,
    setSelectedGenerator,
    exportToExcel,
    sortData,
    handleSubmit,
    values,
    setStatus,
    setFieldValue,
    fieldValue,
    status,
    //gridView,
    selectedFacility,
    setSelectedFacility,
    clearFilters,
    reportData,
    showErrors,
    showSucessModal,
    setShowSuccessModal,
    searchSubmitReport,
    loading,
    setMatchGenLength,
    setMatchFaciLength,
    gotoPage,
    pageNo,
    profilelistreport,
    isApply,
    firstLoad,
    loader,
  } = props

  useEffect(() => {
    if (!showSucessModal) {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [showSucessModal])

  const getWidth = (column) => {
    switch (column) {
      case "profileWasteName":
        return 250
      case "customerName":
      case "shippingAddressLine1":
      case "nosDescription":
        return 200
      // case "profile":
      case "state":
      case "zip":
      case "dotId":
        return 75
      case "hazClass":
      case "epaCodes":
      case "stateCodes":
        return 100
      case "generatorName":
      case "shippingName":
      case "epaId":
        return 250
      case "designatedFacility":
        return 275
      case "profileStatus":
      case "wasteCategory":
        return 130
      default:
        return 150
    }
  }

  if (loader) {
    return (
      <div className="loader-parent">
        <div className="loader"></div>
      </div>
    )
  }

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
      <form onSubmit={handleSubmit}>
        <div className="m-0 labelgenerator myprofile">
          <FormattedMessage id="title.profile_listing_reports" />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 d-flex mb-2 mt-4">
          <div className="form-input-block col-sm-4 mni-style mni-reports ">
            <span className="label">
              <FormattedMessage id="profile.mailingstatus" />
            </span>
            <Select
              name="universalWaste"
              label="Select Status"
              labelkey={"l"}
              value={values.status}
              className={"sort-dropdown w-90"}
              options={[
                { l: "Any Status", v: "Any Status" },
                { l: "Pending", v: "Pending" },
                { l: "Approved", v: "Approved" },
                { l: "Inactive", v: "Inactive" },
              ]}
              selected={status}
              onSelect={(e) => {
                setStatus(e)
              }}
            />
          </div>
          {generatorCodeOpts?.length > 0 && (
            <>
              <div
                className="form-input-block col-sm-4"
                // style={{ textAlign: "center" }}
              >
                <span className="label">
                  <FormattedMessage id="profile.generator" />
                  <span style={{ color: "red" }}>{" *"} </span>
                </span>
                <MultiSelectComponent
                  name="generatorIdList"
                  className="custom-generator"
                  options={generatorCodeOpts}
                  selected={selectedGenerator}
                  value={values.generator}
                  handleOnChange={(val) => {
                    setSelectedGenerator(val)
                    let match = val?.length === generatorCodeOpts?.length
                    setMatchGenLength(match)
                  }}
                  disableSearch={false}
                  isAllReqd={true}
                />
                <div style={{ color: "red", fontSize: 10, padding: 8 }}>
                  {selectedGenerator?.length === 0 && showErrors ? (
                    <>Please select Generator</>
                  ) : null}
                </div>
              </div>
            </>
          )}
          {facilityProcessCodeOpts?.length > 0 && (
            <div
              className="form-input-block col-sm-4"
              // style={{ textAlign: "center" }}
            >
              <span className="label">
                <FormattedMessage id="profilelistreport.designatedFacility" />
                <span style={{ color: "red" }}>{" *"} </span>
              </span>
              <MultiSelectComponent
                name="generatorIdList"
                className="custom-generator"
                options={facilityProcessCodeOpts}
                selected={selectedFacility}
                value={values.designatedFacility}
                handleOnChange={(val) => {
                  setSelectedFacility(val)
                  let match = val?.length === facilityProcessCodeOpts?.length
                  setMatchFaciLength(match)
                }}
                disableSearch={false}
                isAllReqd={true}
              />
              <div style={{ color: "red", fontSize: 10, padding: 8 }}>
                {selectedFacility?.length === 0 && showErrors ? (
                  <>Please select Designated Facility</>
                ) : null}
              </div>
            </div>
          )}
        </div>
        <div
          className="col-sm-12 d-flex mt-4 mb-4"
          style={{ justifyContent: "space-between", height: "40px" }}
        >
          <div className="col-sm-6 d-flex" style={{ marginLeft: -5 }}>
            <div className="px-2 py-1 label">
              <span style={{ color: "red" }}>{"*"} </span>
              <FormattedMessage id="report.universal" />
            </div>
            <div className="px-2 dsp-flx py-1">
              <div className="ml-2 radio-container">
                <input
                  id="radioCustomColor"
                  type="radio"
                  name="universal"
                  value={fieldValue}
                  checked={fieldValue === true ? true : false}
                  onChange={() => {
                    setFieldValue(true)
                  }}
                />
                <div
                  className="text-description"
                  style={{ marginTop: "-2px", marginLeft: 3 }}
                >
                  <FormattedMessage id={"profile.opyes"} />
                </div>
              </div>
              <div className="ml-2 radio-container">
                <input
                  id="radioCustomColor"
                  type="radio"
                  name="universal"
                  value={fieldValue}
                  checked={fieldValue === false ? true : false}
                  onChange={() => {
                    setFieldValue(false)
                  }}
                />
                <div
                  className="text-description"
                  style={{ marginTop: "-2px", marginLeft: 3 }}
                >
                  <FormattedMessage id={"profile.opno"} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-sm-6 d-flex downloadsec"
            style={{ justifyContent: "flex-end" }}
          >
            <button
              type="button"
              className={`cancel-button col-sm-4, mr-4`}
              style={{ height: 40 }}
              onClick={() => clearFilters()}
            >
              <FormattedMessage id="profilelistreport.resetSearch" />
            </button>
            {/* <div
              className="dsp-flx mr-4"
              style={{
                color: "#337AB7",
                cursor: "pointer",
                fontWeight: "bold",
                textTransform: "uppercase",
                textDecoration: "underline",
                fontSize: 13,
              }}
              onClick={() => clearFilters()}
            >
              <FormattedMessage id={"profilelistreport.clearFilter"} />
            </div> */}
            <button
              // className={classnames("downloadbtn")}
              className={`green-button ${
                selectedGenerator?.length === 0 ||
                selectedFacility?.length === 0
                  ? "disabledBg"
                  : ""
              }`}
              // onClick={profilelistingreport}
              disabled={
                selectedGenerator?.length === 0 ||
                selectedFacility?.length === 0
              }
              onClick={searchSubmitReport}
              style={{
                padding: "6px 10px",
                minWidth: 140,
                textTransform: "uppercase",
                fontSize: 12,
              }}
            >
              <FormattedMessage id="profile.searchbtn" />
            </button>
          </div>
        </div>
        <div>
          {!firstLoad && (
            <>
              <div className="col-sm-12 col-md-12 col-lg-12 generatorstatus text-left my-4">
                <div className="m-0 labelgenerator myprofile mt-4">
                  <FormattedMessage id="profilelistreport.results" />
                </div>
                {reportData !== undefined && reportData?.length !== 0 && (
                  <button
                    className={classnames("edit-button")}
                    onClick={exportToExcel}
                    style={{
                      padding: "6px 10px",
                      minWidth: 140,
                      marginRight: "0px",
                      color: "#fff",
                      float: "right",
                    }}
                  >
                    <FormattedMessage id="profilelistreport.exporttoexcel" />
                  </button>
                )}
              </div>

              <Loading id="documents">
                <div
                  className="col-sm-12 col-md-12 col-lg-12 mx-3 p-0 table sticky isshipreport"
                  style={{ maxHeight: 400, overflowY: "auto" }}
                >
                  {/* Render Headers */}
                  <div
                    className="row m-0 headers"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className="tr active"
                      // style={{ display: "flex", minHeight: 32 }}
                      // style={{ padding: "0px 9px" }}
                    >
                      {ProfileListReportsHeaders.map((header, index) => (
                        <div
                          key={header}
                          className="th customsort inactive title-container text-center"
                          style={{
                            display: "inline-block",
                            wordBreak: "break-all",
                            boxSizing: "border-box",
                            width: getWidth(header),
                          }}
                        >
                          <span className="title-text">
                            <FormattedMessage
                              id={`profilelistreport.${header}`}
                            />
                          </span>
                          <span
                            className="sorter"
                            onClick={() =>
                              sortData(reportData, {
                                fieldName: header,
                                type:
                                  typeof header === "number"
                                    ? "number"
                                    : "string",
                              })
                            }
                          >
                            {sortArrows(header)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  {!loading && (
                    <div className="row m-0 body">
                      {reportData?.map((item, index) => (
                        <div
                          className="tr active"
                          key={index}
                          style={{ display: "flex", minHeight: 32 }}
                        >
                          {ProfileListReportsHeaders.map((header) => (
                            <div
                              key={header}
                              colSpan={1}
                              style={{
                                display: "inline-block",
                                boxSizing: "border-box",
                                width: getWidth(header),
                              }}
                              className="td custom"
                            >
                              {header === "profileStartDate" ||
                              header === "profileExpiration"
                                ? item[header] === "N/A" ||
                                  item[header] === "" ||
                                  item[header] === null
                                  ? item[header]
                                  : new Date(item[header]).toLocaleDateString(
                                      "en-US",
                                      {
                                        month: "2-digit",
                                        day: "2-digit",
                                        year: "numeric",
                                      }
                                    )
                                : item[header]}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {!loading && reportData?.length == 0 && (
                  <div
                    className="td inactive"
                    style={{
                      background: "#ffffff",
                      padding: 10,
                      textAlign: "center",
                    }}
                  >
                    No Search Results for the filter.
                  </div>
                )}
                <div className="col-sm-12 col-md-12 col-lg-12 mx-3">
                  {!loading && reportData?.length > 0 && (
                    <Pagination
                      {...{
                        pageNo: isApply ? 1 : pageNo,
                        totalRecords: profilelistreport?.totalRecords || 0,
                        gotoPage,
                        pageSize: 20,
                      }}
                    />
                  )}
                </div>
                {loading && (
                  <div>
                    <div className="loader" style={{ marginTop: 250 }}></div>
                  </div>
                )}
                {showSucessModal && (
                  <div className="export-modal">
                    <Modal show={showSucessModal}>
                      <div
                        className="modal-content"
                        style={{
                          borderRadius: "20px",
                          background: "#F0F1F2",
                        }}
                      >
                        <div>
                          <Image
                            src={"Animation_Sucess.gif"}
                            height="110px"
                            width="110px"
                            alt=""
                          />
                        </div>
                        <div className="sub-header mt-4">
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "#000000",
                            }}
                          >
                            SUCCESS
                          </span>
                        </div>
                        <div className="sub-header">
                          <span style={{ fontSize: "12px", color: "#000" }}>
                            The Profile Listing Report has been exported
                            successfully
                          </span>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="green-button"
                            onClick={() => {
                              setShowSuccessModal(false)
                            }}
                          >
                            {"OK"}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </div>
                )}
              </Loading>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default enhancer(Report)
